import { useNavigate } from "react-router-dom";
import closex from "../../assets/images/x.svg";
import DehiddenBranding from "../../assets/images/DehiddenLogoBlack.svg";

import { useGCtx } from "../../Contexts/GlobalContext";
const Navigation = () => {
  const { setShowNav } = useGCtx();
  const navigate = useNavigate();
  const closeAndNavigate = (route) => {
    navigate(route);
    setShowNav(false);
  };
  return (
    <div
      className="navigation fixed h-[100vh] w-[100%] inset-0 bg-[#0000004d] flex justify-end pt-5 md:pt-0 md:items-center z-20"
      style={{ backdropFilter: "blur(4px)" }}
      onClick={() => setShowNav(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative navigation__panel bg-white md:mr-5 p-6 md:p-10 rounded-2xl max-h-[95%] h-[96%] md:h-[90%] md:max-h-[800px] w-[95%] mx-auto md:w-[420px] flex flex-col justify-between"
      >
        <div
          className="absolute top-4 right-4 z-30 cursor-pointer"
          onClick={() => setShowNav(false)}
        >
          <img src={closex} className="h-8" alt="" />
        </div>
        <div className="top">
          <div className="cursor-pointer" onClick={() => closeAndNavigate("/")}>
            <p
              className="optionitem__heading text-2xl font-black md:text-3xl hover:text-4xl  transition-all duration-[0.3s] text-[#1f1f1f] my-3 hover:text-[#3BBEAE]"
              style={{ lineHeight: "44px" }}
            >
              🏠 Home
            </p>
          </div>

          <div
            className="cursor-pointer"
            onClick={() => closeAndNavigate("/profile")}
          >
            <p
              className="optionitem__heading text-2xl font-black md:text-3xl hover:text-4xl  transition-all duration-[0.3s] text-[#1f1f1f] my-3 hover:text-[#3BBEAE]"
              style={{ lineHeight: "44px" }}
            >
              🔮 My Dashboard
            </p>
          </div>
          {/* <p
            className="optionitem__heading text-2xl font-black md:text-3xl hover:text-4xl  transition-all duration-[0.3s] text-[#1f1f1f] my-3 cursor-pointer hover:text-[#3BBEAE]"
            style={{ lineHeight: "44px" }}
            onClick={() => window.open("https://docs.mintnft.today")}
          >
            📚 Documentation
          </p> */}
          <div
            className="cursor-pointer"
            onClick={() => closeAndNavigate("/faqs")}
          >
            <p
              className="optionitem__heading text-2xl font-black md:text-3xl hover:text-4xl  transition-all duration-[0.3s] text-[#1f1f1f] my-3 hover:text-[#3BBEAE]"
              style={{ lineHeight: "44px" }}
            >
              ❓ FAQs
            </p>
          </div>
          {/* <p
            className="optionitem__heading text-2xl font-black md:text-3xl hover:text-4xl  transition-all duration-[0.3s] text-[#1f1f1f] my-3 cursor-pointer hover:text-[#3BBEAE]"
            style={{ lineHeight: "44px" }}
            onClick={() =>
              window.open(
                "https://8ctjqil5acl.typeform.com/to/Wt3GukTH?typeform-source=mintnft.today"
              )
            }
          >
            🐞 Report a bug
          </p>
          <p
            className="optionitem__heading text-2xl font-black md:text-3xl hover:text-4xl  transition-all duration-[0.3s] text-[#1f1f1f] my-3 cursor-pointer hover:text-[#3BBEAE]"
            style={{ lineHeight: "44px" }}
            onClick={() =>
              window.open(
                "https://8ctjqil5acl.typeform.com/to/Wt3GukTH?typeform-source=mintnft.today"
              )
            }
          >
            💡 Need help?
          </p> */}
        </div>
        <div className="bottom">
        <div className="log_box">
          <p className=" font-semibold">Powered by</p>
          <a href="https://dehidden.com/" target="_blank" rel="noopener noreferrer">
          <img src={DehiddenBranding} alt="" className="h-full w-[200px] ml-[-20px]" />
          </a>

        </div>
          {/* <p>Powered by Polygon</p> */}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
