import propertiesIcon from "../../assets/images/propertiesIcon.svg";
import plusIcon from "../../assets/images/plusIcon.svg";
import chartBar from "../../assets/images/chartBar.svg";
import rocket from "../../assets/images/rocket.svg";
import closeIcon from "../../assets/images/closeIcon.png";
import BuildBlock from "../../Components/BuildBlock/BuildBlock";
import BuildNftImage from "../../Components/BuildNftImage/BuildNftImage";
import BuildNftDetails from "../../Components/BuildNftDetails/BuildNftDetails";
import AdditionalDetailModal from "../../Components/AdditionalDetailModal/AdditionalDetailModal";
import { useState } from "react";
import { useGCtx } from "../../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
const AdditionalProperties = () => {
  const [activePopUp, setActivePopUp] = useState(null);
  const { attributes, setAttributes } = useGCtx();
  const navigate = useNavigate();
  const propertySample = {
    trait_type: "",
    value: "",
  };
  const levelSample = {
    trait_type: "",
    value: "",
    of: "",
    display_type: "number",
  };
  const statsSample = {
    trait_type: "",
    value: "",
    of: "",
    display_type: "percent",
  };

  const handleAddProperty = (type) => {
    var id = attributes.length;
    if (activePopUp === "default") {
      setAttributes([...attributes, { ...propertySample, id }]);
    } else if (activePopUp === "number") {
      setAttributes([...attributes, { ...levelSample, id }]);
    } else if (activePopUp === "percent") {
      setAttributes([...attributes, { ...statsSample, id }]);
    }
  };

  const handleRemoveItem = (item) => {
    var tempAttributes = attributes;

    var idx = tempAttributes.indexOf(item);

    if (idx != -1) tempAttributes.splice(idx, 1);
    setAttributes([...tempAttributes]);
  };

  const handleOnChange = (e, item) => {
    var tempAttributes = attributes;

    var idx = tempAttributes.indexOf(item);

    var itemNow = tempAttributes[idx];
    var field = e.target.id;
    itemNow[field] = e.target.value;

    setAttributes([...tempAttributes]);
  };

  return (
    <section className=" flex h-[100%] md:h-screen w-full">
      <BuildBlock>
        <div className="w-full md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 mx-auto rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
          <BuildNftImage />
          <BuildNftDetails />
        </div>
        <div className="w-full md:h-[100%] md:w-[60%] p-4 relative">
          <h2 className="text-4xl font-black md:text-6xl uppercase pb-5">
            Additional Properties
          </h2>

          <div className="properties__items">
            <PropertyItem
              propertyIcon={propertiesIcon}
              propertyName="Properties"
              propertyDesc={"Textual traits that show up as rectangles"}
              clickHandler={() => setActivePopUp("default")}
            />
            <PropertyItem
              propertyIcon={rocket}
              propertyName="Levels"
              propertyDesc={"Numerical traits that show as a progress bar"}
              clickHandler={() => setActivePopUp("number")}
            />
            <PropertyItem
              propertyIcon={chartBar}
              propertyName="Stats"
              propertyDesc={"Numerical traits that just show as numbers"}
              clickHandler={() => setActivePopUp("percent")}
            />
          </div>
          <div className="steps__wrapper mt-3">
            <button
              className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4"
              onClick={() => navigate("/build-nft")}
            >
              Confirm Properties
            </button>
            <p
              className="text-center pt-3 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              Back
            </p>
          </div>

          {activePopUp ? (
            <AdditionalDetailModal>
              {activePopUp === "default" ? (
                <PropertiesModalInner
                  attributes={attributes}
                  handleAddProperty={handleAddProperty}
                  handleOnChange={handleOnChange}
                  handleRemoveItem={handleRemoveItem}
                  setActivePopUp={setActivePopUp}
                />
              ) : (
                <LevelStatsModalInner
                  attributes={attributes}
                  handleAddProperty={handleAddProperty}
                  handleOnChange={handleOnChange}
                  handleRemoveItem={handleRemoveItem}
                  type={activePopUp}
                  setActivePopUp={setActivePopUp}
                />
              )}
            </AdditionalDetailModal>
          ) : null}
        </div>
      </BuildBlock>
    </section>
  );
};

export default AdditionalProperties;

export const PropertyItem = ({
  propertyIcon,
  propertyName,
  propertyDesc,
  clickHandler,
}) => {
  return (
    <div className="flex-col md:flex-row flex justify-between py-3 border-b border-solid border-[#33333350] items-start">
      <div className="flex">
        <img src={propertyIcon} alt="" className="icon h-10 mr-2" />
        <div>
          <p className="font-semibold pb-1 text-[20px]">{propertyName}</p>
          <p className="text-[#333333] font-[14px]">{propertyDesc}</p>
        </div>
      </div>
      <button
        className="border-2 border-solid border-[#E2E4E8] rounded-lg py-3 px-20 text-center mx-auto mt-3"
        onClick={clickHandler}
      >
        <img src={plusIcon} alt="" className="h-5" />
      </button>
    </div>
  );
};

const PropertiesModalInner = ({
  attributes,
  handleAddProperty,
  handleRemoveItem,
  handleOnChange,
  setActivePopUp,
}) => {
  return (
    <div className="font-medium">
      <div className="flex justify-between">
        {" "}
        <p className="font-semibold text-2xl">Add Properties</p>{" "}
        <img
          src={closeIcon}
          className="h-6 cursor-pointer"
          onClick={() => setActivePopUp(null)}
          alt=""
        />
      </div>
      <div className="flex my-3">
        <p className="w-[40%] font-medium">Type</p>
        <p className="w-[40%] font-medium">Name</p>
      </div>
      {attributes
        .filter((item) => !item.display_type)
        .map((item, index) => {
          return (
            <div className="flex my-1" key={index}>
              <input
                type="text"
                className="w-[38%] border-[#cccccc] border border-solid rounded-md p-2 mr-2 focus:bg-[#ECE8FF] focus:border-none"
                value={item.trait_type}
                id="trait_type"
                onChange={(event) => handleOnChange(event, item)}
              />
              <input
                type="text"
                className="w-[38%] border-[#cccccc] border border-solid rounded-md p-2 mr-2 focus:bg-[#ECE8FF] focus:border-none"
                value={item.value}
                id="value"
                onChange={(event) => handleOnChange(event, item)}
              />
              {index !== 0 ? (
                <button
                  className="close "
                  onClick={() => handleRemoveItem(item)}
                >
                  <img src={closeIcon} alt="" className="h-6" />
                </button>
              ) : null}
            </div>
          );
        })}

      <div className="flex my-5 items-center">
        <button
          className="border-2 border-[#E2E4E8] border-solid p-2 text-center rounded-md mr-4"
          onClick={() => handleAddProperty("default")}
        >
          <img src={plusIcon} alt="" className="h-8" />
        </button>
        <p>Add Property</p>
      </div>
    </div>
  );
};

const LevelStatsModalInner = ({
  attributes,
  handleAddProperty,
  handleRemoveItem,
  handleOnChange,
  type,
  setActivePopUp,
}) => {
  return (
    <div className="font-medium">
      <div className="flex justify-between">
        {" "}
        <p className="font-semibold text-2xl">Add Properties</p>{" "}
        <img
          src={closeIcon}
          className="h-6 cursor-pointer"
          onClick={() => setActivePopUp(null)}
          alt=""
        />
      </div>
      <div className="flex my-3">
        <p className="w-[46%] md:w-[40%] font-medium">Name</p>
        <p className="w-[46%] md:w-[40%] font-medium">Value</p>
      </div>
      {attributes
        .filter((item) => item.display_type === type)
        .map((item, index) => {
          return (
            <div className="flex my-1" key={index}>
              <input
                type="text"
                className="w-[41%] border-[#cccccc] border border-solid rounded-md p-2 mr-2 focus:bg-[#ECE8FF] focus:border-none"
                value={item.trait_type}
                id="trait_type"
                onChange={(event) => handleOnChange(event, item)}
              />
              <div className="flex items-stretch w-[45%] mr-2">
                <input
                  type="number"
                  className="w-[41%] border-[#cccccc] border border-solid rounded-md rounded-tr-none rounded-br-none p-2 focus:bg-[#ECE8FF] focus:border-none"
                  value={item.value}
                  id="value"
                  min={0}
                  max={item.of}
                  onChange={(event) => handleOnChange(event, item)}
                />
                <div className="p-2 px-3 bg-[#fefefe]">of</div>
                <input
                  type="number"
                  className="w-[45%] border-[#cccccc] border border-solid rounded-md rounded-tl-none rounded-bl-none p-2 mr-2 focus:bg-[#ECE8FF] focus:border-none"
                  value={item.of}
                  min={item.value}
                  id="of"
                  onChange={(event) => handleOnChange(event, item)}
                />
              </div>

              {index !== 0 ? (
                <button
                  className="close w-6 md:w-auto"
                  onClick={() => handleRemoveItem(item)}
                >
                  <img src={closeIcon} alt="" className="h-4 md:h-6" />
                </button>
              ) : null}
            </div>
          );
        })}

      <div className="flex my-5 items-center">
        <button
          className="border-2 border-[#E2E4E8] border-solid p-2 text-center rounded-md mr-4"
          onClick={() => handleAddProperty(type)}
        >
          <img src={plusIcon} alt="" className="h-8" />
        </button>
        <p>Add Property</p>
      </div>
    </div>
  );
};
