import { useEffect, useState } from "react";
import { isAddress } from "../../../utils";
import BuildBlock from "../../../Components/BuildBlock/BuildBlock";
import BuildNftDetails from "../../../Components/BuildNftDetails/BuildNftDetails";
import BuildNftImage from "../../../Components/BuildNftImage/BuildNftImage";
import { useGCtx } from "../../../Contexts/GlobalContext";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

import { ERC721MUMBAI } from "../../../assets/ABI/ERC721MUMBAI";
import { Biconomy } from "@biconomy/mexa";
import checkCirclePurple from "../../../assets/images/checkCirclePurple.svg";
import Web3 from "web3";
import { truncateAddress } from "../../../utils";
import alertCircle from "../../../assets/images/alertCircle.svg";
import { globalNFT } from "../../../connectors";
import { useSigner } from "wagmi";

const AirdropModule = () => {
  const [toAddress, setToAddress] = useState("");
  const { data: signer } = useSigner();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { wallet, setDehiddenModules, dehiddenModules, mintedTokenId, txhash } =
    useGCtx();
  var biconomy, web3Pro;

  useEffect(() => {
    if (error?.length) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);

  const updateDb = async () => {
    const data = {
      newOwner: toAddress,
      txHash: txhash,
    };
    try {
      const updateDbCall = await fetch(
        `${process.env.REACT_APP_BASE_URL}/dehidden/gift/transfer`,
        {
          method: "POST",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const updateDbRes = await updateDbCall.json();
      if (
        updateDbRes.message &&
        updateDbRes.message.toLowerCase() === "update success"
      ) {
        setIsLoading(false);
        setDehiddenModules({ ...dehiddenModules, airdrop: true });
      } else {
        setError("NFT airdropped, unable to update profile details");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getContractToUse = () => {
    // if (networkType === "80001" || networkType === "mumabi") {
    //   return dehiddenModules.isAutographedItem
    //     ? "0x55e5c962661BE5edfA6a9A290b186677396470Cd"
    //     : "0x8021701ced6e9b8376a165efe00c26d86de15a41";
    // } else {
    //add mainnet addresses
    return dehiddenModules.isAutographedItem
      ? globalNFT.Autograph.address
      : globalNFT.ERC721.address;
    // }
  };

  const airDropItem = async () => {
    const contractToUse = getContractToUse();
    try {
      setIsLoading(true);
      biconomy = new Biconomy(signer?.provider, {
        apiKey: process.env.REACT_APP_BICONOMY_KEY_MAINNET,
        debug: false,
        strictMode: true,
      });
      web3Pro = new Web3(biconomy);
      biconomy.onEvent(biconomy.READY, async () => {
        try {
          const contract = new web3Pro.eth.Contract(
            ERC721MUMBAI,
            contractToUse
          );

          const tx = await contract.methods
            .safeTransferFrom(wallet, toAddress, mintedTokenId)
            .send({
              from: wallet,
            });
          if (tx) {
            // setIsLoading(false);
            // setDehiddenModules({ ...dehiddenModules, airdrop: true });
            updateDb();
          } else {
            setIsLoading(false);
            setError(
              "Oops, the airdrop has failed! Please try again after a while."
            );
          }
        } catch (err) {
          // console.log("err: ", err);
          console.log(err.message);
          setIsLoading(false);
          setError(
            "Oops, the airdrop has failed! Please try again after a while."
          );
        }
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <section className=" flex h-[100%] md:h-screen w-full">
      <BuildBlock>
        <div className="w-full mx-auto h-auto  md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
          {/* <div className="mockup__container"> */}
          <BuildNftImage />
          <BuildNftDetails />
          {/* </div> */}
        </div>
        <div className="w-full md:h-[100%] md:w-[60%] p-4 flex flex-col justify-between md:overflow-y-auto">
          <div>
            <h2 className="text-4xl font-black md:text-6xl uppercase pb-5">
              🎁 Send as a gift
            </h2>
            <p className="pb-4 font-semibold text-3xl">
              Enter the wallet address to airdrop the NFT
            </p>
            {isLoading ? (
              <div className="options grid items-center font-semibold">
                <div>
                  {/* <img src={} alt="" /> */}
                  <div className="text-center my-4">
                    <ClipLoader size={60} color="#3BBEAE" />
                  </div>

                  <p className="text-center font-semibold">
                    Airdropping... <br /> Please don't close this window.
                  </p>
                </div>
              </div>
            ) : !dehiddenModules.airdrop ? (
              <div className="options font-semibold text-[#333333]">
                <div className={"w-full form-group mt-4"}>
                  <label htmlFor="name" className="font-semibold pb-1">
                    Wallet Address*
                  </label>
                  <input
                    type="text"
                    name="to_address"
                    value={toAddress}
                    onChange={(e) => setToAddress(e.target.value)}
                    id="to_address"
                    placeholder="Enter wallet address"
                    className="w-full border-2 border-solid border-[#cccccc] p-2 rounded-md my-2"
                    required
                  />
                </div>
                {error ? (
                  <div className="options font-semibold text-[#333333] flex items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2">
                    <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                    <p>{error}</p>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="options font-semibold text-[#333333] w-full h-[300px] text-center border rounded-xl grid items-center">
                <div className="text-center">
                  <img
                    src={checkCirclePurple}
                    className="h-24 mb-3 mx-auto"
                    alt=""
                  />
                  <p>NFT airdropped to {truncateAddress(toAddress)}</p>
                </div>
              </div>
            )}
          </div>

          {!dehiddenModules.airdrop ? (
            <div className="steps__wrapper mt-3">
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
                onClick={airDropItem}
                disabled={!isAddress(toAddress) || isLoading}
              >
                Airdrop NFT
              </button>
              <p className="text-center pt-3" onClick={() => navigate(-1)}>
                Back
              </p>
            </div>
          ) : (
            <div className="steps__wrapper mt-3">
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
                onClick={() => navigate("/dehidden/add-utilities")}
              >
                Go Back
              </button>
            </div>
          )}
        </div>
      </BuildBlock>
    </section>
  );
};

export default AirdropModule;
