import { useEffect, useState, memo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart, Bar } from "react-chartjs-2";

const initAuth = () => {
  return window.gapi.auth2.init({
    client_id:
      "370856798392-qh0hr58ehirnf5rednmuon1qsh7ovji1.apps.googleusercontent.com", //paste your client ID here
    scope: "https://www.googleapis.com/auth/analytics.readonly",
  });
};
const onSuccess = (googleUser) => {
  console.log("Logged in as: " + googleUser.getBasicProfile().getName());
};

const onFailure = (error) => {
  console.error(error);
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AnalyticsPage = () => {
  const [analyticsData, setData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [password, setPassword] = useState(null);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [activeTab, setActiveTab] = useState("mint-data");
  const [login, setLogin] = useState(false);

  useEffect(() => {
    console.log(analyticsData, login);
    if (!analyticsData && login) {
      console.log("Data is null");
      getAnalyticsData(startDate, endDate);
    }
  }, [login]);

  const getAnalyticsData = async (from, to) => {
    const data = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/analytics?from=${from}&to=${to}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-pass": password,
        },

        method: "GET",
      }
    );

    const analyticsData = await data.json();
    console.log(analyticsData);
    if (analyticsData.singleMintData) {
      setData(analyticsData);
    } else {
      alert("Unauthorized!");
      setLogin(false);
      setPassword(null);
    }
  };

  useEffect(() => {
    if (analyticsData !== null) {
      prepareChartData();
    }
  }, [analyticsData]);

  const prepareChartData = async () => {
    const chartData = analyticsData.singleMintData.mintDataByDay;
    let labels = [];
    let data = [];
    chartData.forEach((item, i) => {
      labels.push(item.date);
      data.push(item.count);
    });
    console.log(labels, data);
    setChartData({ labels, data });
  };

  // experiment
  const [isSignedIn, setIsSignedIn] = useState(false);

  const updateSignin = (signedIn) => {
    //(3)
    setIsSignedIn(signedIn);
    if (!signedIn) {
      renderButton();
    }
  };

  const init = () => {
    //(2)
    checkSignedIn()
      .then((signedIn) => {
        updateSignin(signedIn);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getNewData = async () => {
    console.log(new Date(endDate).getTime());
    const from = parseInt(new Date(startDate).getTime() / 1000);
    const to = parseInt(new Date(endDate).getTime() / 1000) + 86400;
    console.log(from);
    getAnalyticsData(from, to);
  };

  const resetData = async () => {
    setStartDate(0);
    setEndDate(0);
    getAnalyticsData(0, 0);
  };

  return login ? (
    analyticsData ? (
      <section
        className="w-full px-8 py-[40px] bg-[#e5e5e5] "
        style={{ backdropFilter: "blur(10px)" }}
      >
        {!isSignedIn ? (
          <div id="signin-button"></div>
        ) : (
          <div>Coming soon...</div>
        )}
        <div className="grid-rows-12">
          <div className="grid grid-cols-1 w-full">
            <h2 className="text-xl py-4">Mint NFT Dashboard</h2>
          </div>
        </div>
        <div className="grid-rows-12 mt-8 mb-8">
          <div className="grid grid-cols-4 w-full">
            <button
              className={
                activeTab === "mint-data"
                  ? "bg-[#3BBEAE] text-white border rounded-lg py-4 text-sm px-4 text-center mr-4"
                  : "hover:bg-[#8238e393] border rounded-lg py-4 text-sm px-4 text-center mr-4 bg-[#fefefe] text-[#000000]"
              }
              onClick={() => setActiveTab("mint-data")}
            >
              Mint Data
            </button>
            <button
              className={
                activeTab === "analytics-data"
                  ? "bg-[#3BBEAE] text-white  border rounded-lg py-4 text-sm px-4 text-center mx-4"
                  : "hover:bg-[#8238e393] border rounded-lg py-4 text-sm px-4 text-center mx-4 bg-[#fefefe] text-[#000000]"
              }
              onClick={() => setActiveTab("analytics-data")}
            >
              GA Data
            </button>
          </div>
        </div>
        <div className={activeTab === "mint-data" ? "" : "hidden"}>
          <div className="grid-rows-12 pb-4">
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-1 w-full">
              <input
                datepicker=""
                value={startDate}
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
                className="text-[14px] rounded-lg  p-2.5 py-2 datepicker-input border focus:border-[#8247E5]  focus:text-[#8247E5]"
                placeholder="Select date"
              />

              <input
                datepicker=""
                value={endDate}
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                className="text-[14px] rounded-lg ml-4 p-2.5 py-2 datepicker-input border focus:border-[#8247E5]  focus:text-[#8247E5]"
                placeholder="Select date"
              />

              <div className="flex">
                <button
                  onClick={getNewData}
                  className="bg-[#3BBEAE] text-sm px-5 py-2 text-center w-[25%] font-semibold text-white rounded-lg ml-4 flex cursor-pointer items-center"
                >
                  Get Data
                </button>
                <button
                  onClick={resetData}
                  className="bg-[#F55431] text-sm px-5 py-2 text-center w-[25%] font-semibold text-white rounded-lg ml-4 flex cursor-pointer items-center"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div className="grid-rows-12 mt-8">
            <h2 className="mb-4 text-3xl">Single Mint</h2>
            <div className="grid grid-cols-4 gap-4 w-full">
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">Total Mints</h3>
                <h2 className="text-2xl">
                  {analyticsData.singleMintData.totalMints}
                </h2>
              </div>
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">ERC721</h3>
                <h2 className="text-2xl">
                  {analyticsData.singleMintData.totalERC721}
                </h2>
              </div>
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">ERC1155</h3>
                <h2 className="text-2xl">
                  {analyticsData.singleMintData.totalSoundBound}
                </h2>
              </div>
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">Soulbound</h3>
                <h2 className="text-2xl">
                  {analyticsData.singleMintData.totalERC1155}
                </h2>
              </div>
            </div>
          </div>
          <div className="grid-rows-12 mt-8 mb-[40px]">
            <div className="gird grid-cols-12  rounded-md">
              <div className="chart-section">
                {chartData ? (
                  <Bar
                    height="400px"
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: "top",
                        },
                        title: {
                          display: true,
                          text: "Daily mint count",
                        },
                      },
                    }}
                    data={{
                      labels: chartData.labels,
                      datasets: [
                        {
                          label: "Count",
                          data: chartData.data,
                          backgroundColor: "rgba(53, 162, 235, 0.5)",
                        },
                      ],
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className="grid-rows-12 mt-8 mb-[40px]">
            <div className="grid grid-cols-3 gap-4 w-full">
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">
                  Addresses with most ERC721 transaction count
                </h3>
                {analyticsData.singleMintData.addressWithMostERC721Txns.map(
                  (item, i) => {
                    return (
                      <div>
                        <p className="text-sm">
                          {item.address} - {item.count}
                        </p>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">
                  Addresses with most ERC1155 transaction count
                </h3>
                {analyticsData.singleMintData.addressWithMostERC1155Txns.map(
                  (item, i) => {
                    return (
                      <div>
                        <p className="text-sm">
                          {item.address} - {item.count}
                        </p>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">
                  Addresses with most Soulbound transaction count
                </h3>
                {analyticsData.singleMintData.addressWithMostSouldboundTxns.map(
                  (item, i) => {
                    return (
                      <div>
                        <p className="text-sm">
                          {item.address} - {item.count}
                        </p>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <hr />

          {/* ----------------------Users------------------------ */}
          <div className="grid-rows-12 mt-8 mb-[40px]">
            <h2 className="mb-4 text-3xl">Users</h2>
            <div className="grid grid-cols-4 gap-4 w-full">
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">Total Number of Unique wallets</h3>
                <h2 className="text-2xl">
                  {analyticsData.usersData.noOfUsers}
                </h2>
              </div>
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">Total Number of users created APIs</h3>
                <h2 className="text-2xl">
                  {analyticsData.usersData.usersWithApiKey}
                </h2>
              </div>
            </div>
          </div>
          <hr />

          {/* ----------------------Batch Mint------------------------ */}
          <div className="grid-rows-12 mt-8 mb-[40px]">
            <h2 className="mb-4 text-3xl">Batch Mint</h2>
            <div className="grid grid-cols-4 gap-4 w-full">
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">Total Number of Sessions</h3>
                <h2 className="text-2xl">
                  {analyticsData.batchMintData.totalSession}
                </h2>
              </div>
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">Total NFTs minted</h3>
                <h2 className="text-2xl">
                  {analyticsData.batchMintData.totalNFTsMinted}
                </h2>
              </div>
            </div>
          </div>
          <hr />

          {/* ----------------------Dehidden moudles------------------------ */}
          <div className="grid-rows-12 mt-8 mb-[40px]">
            <h2 className="mb-4 text-3xl">Dehidden Modules</h2>
            <div className="grid grid-cols-4 gap-4 w-full">
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">No.of Autographed NFTs</h3>
                <h2 className="text-2xl">
                  {analyticsData.dehiddenData.noOfAutographedNFTs}
                </h2>
              </div>
              <div className="score-card rounded-md  shadow-xl p-4">
                <h3 className="mb-4">No.of Phygital orders</h3>
                <h2 className="text-2xl">
                  {analyticsData.dehiddenData.noOfPhygitalOrders}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className={activeTab === "analytics-data" ? "" : "hidden"}>
          <div className="grid-rows-12 mt-8 mb-[40px]">
            <div className="grid grid-cols-1 w-full">
              <iframe
                height="800"
                width="100%"
                src="https://datastudio.google.com/embed/reporting/b3969292-bc3a-403a-ac16-213778b19b49/page/tWDGB"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    ) : null
  ) : (
    <section>
      <div className="flex h-screen items-center justify-center text-center">
        <div className="w-400px bg-[#00000052] p-10 rounded-xl">
          <h1 className="mb-8 text-4xl font-black text-white">
            MintNFT Dashboard
          </h1>
          <form>
            <label className="block">
              <span className="block text-sm font-medium text-white text-left">
                Password
              </span>
              <input
                type="password"
                placeholder="Enter password"
                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>

            <button
              type="submit"
              onClick={() => setLogin(true)}
              className="mt-8 bg-[#3BBEAE] text-white  rounded-full p-2 px-6 text-sm text-center font-medium"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AnalyticsPage;

export const checkSignedIn = () => {
  return new Promise((resolve, reject) => {
    initAuth() //calls the previous function
      .then(() => {
        const auth = window.gapi.auth2.getAuthInstance(); //returns the GoogleAuth object
        resolve(auth.isSignedIn.get()); //returns whether the current user is currently signed in
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const renderButton = () => {
  window.gapi.signin2.render("signin-button", {
    scope: "profile email",
    width: 240,
    height: 50,
    longtitle: true,
    theme: "dark",
    onsuccess: onSuccess,
    onfailure: onFailure,
  });
};
