import { useEffect } from "react";

const RedirectPage = () => {
  useEffect(() => {
    var url = new URL(window.location.href);

    if (url.searchParams.get("username")) {
      console.log(url.searchParams.get("username"));
    }
  }, []);

  return (
    <section>
      <h1>Please go back to previous tab and verify your Authentication</h1>
    </section>
  );
};

export default RedirectPage;
