import { useEffect, useState } from "react";
import checkCirclePurple from "../../assets/images/checkCirclePurple.svg";
import failCirclePurple from "../../assets/images/failCirclePurple.svg";
import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import { useGCtx } from "../../Contexts/GlobalContext";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";

const MintProgress = () => {
  const {
    wallet,
    nftData,
    assetPreview,
    uploadedFile,
    isUploading,
    setIsUploading,
    isMinting,
    isMinted,
    resetState,
    isPending,
    networkType,
    txhash,
    nftType,
  } = useGCtx();
  const navigate = useNavigate();

  const handleViewOnPolygon = () => {
    var url =
      networkType === "80001"
        ? "https://mumbai.polygonscan.com/tx/"
        : "https://polygonscan.com/tx/";

    window.open(`${url + txhash}`);
    resetState();
    navigate("/");
  };
  useEffect(() => {
    if (
      nftData.name &&
      nftData.description &&
      wallet &&
      uploadedFile.file &&
      assetPreview.file
    ) {
      setIsUploading(true);
      // uploadAssets();
    } else {
      console.log("MISSING DATA");
    }
    var url =
      networkType === "80001"
        ? "https://mumbai.polygonscan.com/tx/"
        : "https://polygonscan.com/tx/";
  }, []);
  useEffect(() => {
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "The transaction might fail if tab is closed";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Webkit, Safari, Chrome
    });
  });
  return (
    <section className="connect  flex h-screen w-full items-end">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-6xl text-4xl font-black uppercase">
            Mint <br className="hidden md:block" />
            In <br className="hidden md:block" />
            Progress
          </h1>
          <p className="font-semibold py-4 text-xl md:text-2xl">
            We are now getting your NFT ready for the metaverse
          </p>
          <div className="h-40 grid items-center">
            {isUploading ? (
              <div>
                {/* <img src={} alt="" /> */}
                <div className="text-center my-4">
                  <ClipLoader size={60} color="#3BBEAE" />
                </div>
                <p className="text-center font-semibold">
                  Uploading... <br /> Please don't close this window.{" "}
                </p>
              </div>
            ) : isMinting ? (
              <div>
                {/* <img src={} alt="" /> */}
                <div className="text-center my-4">
                  <ClipLoader size={60} color="#3BBEAE" />
                </div>

                <p className="text-center font-semibold">
                  Minting... <br /> Please don't close this window.{" "}
                </p>
              </div>
            ) : isMinted ? (
              <div className="text-center">
                <img
                  src={checkCirclePurple}
                  alt=""
                  className="mx-auto h-16 mb-3"
                />
                <p className="text-center">Mint Successful</p>
              </div>
            ) : !isPending ? (
              <div>
                <img
                  src={failCirclePurple}
                  alt=""
                  className="mx-auto h-16 my-4"
                />
                <p className="text-center font-semibold">
                  Whoops! Something went wrong!
                </p>
              </div>
            ) : (
              <div>
                <img
                  src={failCirclePurple}
                  alt=""
                  className="mx-auto h-16 my-4"
                />
                <p className="text-center font-semibold">
                  Transaction is sent to the blockchain, <br /> please check
                  your profile after sometime
                </p>
              </div>
            )}
          </div>
        </div>
        {isPending ? (
          <div className="buttons">
            <button
              className="flex items-center justify-center gap-3 bg-[#3BBEAE] w-full px-5 py-4 my-2 rounded-full text-white font-semibold"
              disabled={isUploading || isMinting || !txhash.length}
              onClick={handleViewOnPolygon}
            >
              <span>View on PolygonScan</span>
            </button>
          </div>
        ) : !isMinting && !isUploading && !isMinted ? (
          <div className="buttons">
            <button
              className="flex items-center justify-center gap-3 bg-[#3BBEAE] w-full px-5 py-4 my-2 rounded-full text-white font-semibold "
              onClick={() => (window.location.href = "/")}
              disabled={isUploading || isMinting}
            >
              <span>Retry Minting</span>
            </button>
          </div>
        ) : null}
      </ContentBlock>
    </section>
  );
};

export default MintProgress;
