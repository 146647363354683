import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BuildBlock from "../../Components/BuildBlock/BuildBlock";
import BuildNftDetails from "../../Components/BuildNftDetails/BuildNftDetails";
import BuildNftImage from "../../Components/BuildNftImage/BuildNftImage";
import { useGCtx } from "../../Contexts/GlobalContext";

const AddDetails = () => {
  const { setNftData, nftData, nftType } = useGCtx();
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !nftData?.name?.length ||
      !nftData?.description?.length ||
      (nftType === "erc1155" && !nftData.amount)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [nftData]);

  const handleConfirmEdits = (e) => {
    e.preventDefault();
    setNftData({ ...nftData, isAdded: true });
    navigate(-1);
  };
  return (
    <section className=" flex h-[100%] md:h-screen w-full">
      <BuildBlock>
        <div className="w-full mx-auto h-auto  md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
          <BuildNftImage />
          <BuildNftDetails />
        </div>
        <div className="w-full md:h-[100%] md:w-[60%] p-4 md:overflow-y-auto">
          <h2 className="text-4xl font-black md:text-6xl uppercase pb-4">
            Add NFT details*
          </h2>
          <form onSubmit={handleConfirmEdits}>
            <div className="form__container">
              <div className="flex justify-between">
                <div
                  className={
                    nftType === "erc1155"
                      ? "form-group my-3 w-[70%] mr-2"
                      : "w-full form-group my-3"
                  }
                >
                  <label htmlFor="name" className="font-semibold pb-1">
                    Name*
                  </label>
                  <input
                    type="name"
                    name="name"
                    value={nftData.name}
                    onChange={(e) =>
                      setNftData({ ...nftData, name: e.target.value })
                    }
                    id="name"
                    placeholder="Item Name"
                    className="w-full border-2 border-solid border-[#cccccc] p-2 rounded-md my-2"
                    required
                  />
                </div>
                {nftType === "erc1155" ? (
                  <div className="form-group my-3 w-[25%]">
                    <label htmlFor="name" className="font-semibold pb-1">
                      Supply*
                    </label>
                    <input
                      type="number"
                      name="amount"
                      value={nftData.amount}
                      onChange={(e) =>
                        setNftData({ ...nftData, amount: e.target.value })
                      }
                      min="1"
                      id="name"
                      placeholder="Item Name"
                      className="w-full border-2 border-solid border-[#cccccc] p-2 rounded-md my-2"
                      required
                    />
                  </div>
                ) : null}
              </div>
              <div className="form-group my-3">
                <label htmlFor="description" className="font-semibold pb-1">
                  Description*
                </label>
                <textarea
                  name="description"
                  id="description"
                  value={nftData.description}
                  onChange={(e) =>
                    setNftData({ ...nftData, description: e.target.value })
                  }
                  placeholder="Fill in the detailed description of your NFT"
                  className="w-full border-2 border-solid border-[#cccccc] p-2 rounded-md my-2 min-h-[100px]"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="name" className="font-semibold pb-1">
                  External Link
                </label>
                <input
                  type="url"
                  name="externalUrl"
                  value={nftData.external_link}
                  onChange={(e) =>
                    setNftData({ ...nftData, external_link: e.target.value })
                  }
                  id="externalUrl"
                  placeholder="Ex: https://www.opensea.com"
                  className="w-full border-2 border-solid border-[#cccccc] p-2 rounded-md my-2"
                />
              </div>

              <button
                type="submit"
                className="bg-[#3BBEAE] w-full px-5 py-4 rounded-full text-white font-semibold text-center mt-4"
                disabled={isDisabled}
              >
                Confirm Edits
              </button>
              <p
                className="text-center pt-2 cursor-pointer"
                onClick={() => navigate(-1)}
              >
                Back
              </p>
            </div>
          </form>
        </div>
      </BuildBlock>
    </section>
  );
};

export default AddDetails;
