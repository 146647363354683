const OptionItem = ({
  heading,
  buttonText,
  description,
  isExtended,
  type,
  handleClickExtention,
  handleContinue,
  pill,
}) => {
  return (
    <div
      className={
        isExtended
          ? " bg-[#fefefea9] optionitem my-3 p-4 rounded-lg cursor-pointer"
          : " bg-[#fefefe6f] optionitem my-3 px-4 py-1 rounded-lg cursor-pointer"
      }
      onClick={(event) => handleClickExtention(event, type)}
    >
      <p
        className={
          !isExtended
            ? "optionitem__heading text-xl font-black md:text-2xl text-[#1f1f1f]"
            : "optionitem__heading text-xl font-black md:text-2xl text-[#008376]"
        }
      >
        {heading}
        {pill && (
          <span
            className={
              isExtended
                ? "ml-3 text-[10px] font-semibold text-white bg-[#1f1f1f] px-3 py-1 rounded-xl"
                : "ml-3 text-[10px] font-semibold text-white bg-[#1f1f1fa7] px-3 py-1 rounded-xl"
            }
          >
            {pill}
          </span>
        )}
      </p>

      <div className={isExtended ? "extended" : "not-extended"}>
        <p className="optionitem__heading font-medium pt-2 md:text-[18px]">
          {description}
        </p>
        <button
          className="bg-[#3BBEAE] w-full px-5 py-4 mt-3 rounded-full text-white font-semibold text-center"
          onClick={() => handleContinue(type)}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default OptionItem;
