import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Assistant from "../../Components/Assitant/Assistant";
import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import OptionItem from "../../Components/OptionItem/OptionItem";

import { useGCtx } from "../../Contexts/GlobalContext";

const MintYourNFT = () => {
  const [extended, setExtended] = useState("quick");


  const navigate = useNavigate();
  const { callAssistant ,setMintType, setAssistantText} = useGCtx();
  const handleClickExtention = (e, type) => {
    setExtended(type);
    if (e.target.tagName === "BUTTON") {
      return;
    }
    if (type === "pro") {
      setTimeout(() => {
        callAssistant(
          "Choose between ERC 721 & ERC 1155, batch mint a family of NFTs, in-game minting using Unity SDK, and API keys for your dApps."
        );
      }, 100);
    } else if (type === "quick") {
      setTimeout(() => {
        callAssistant(
          "Mint your very own NFT on the blockchain, completely gas-free"
        );
      }, 100);
    }
    else if (type === "batch") {
      setTimeout(() => {
        callAssistant(
          "Mint up to 10,000 unique NFTs in one go. Gas fees may apply."
        );
      }, 100);
    } else if (type === "single") {
      setTimeout(() => {
        callAssistant(
          "Mint your very own NFT on the blockchain, completely gas-free."
        );
      }, 100);
    }
  };
  const handleContinue = (type) => {
    setMintType(type);

    setAssistantText("");
    if (type === "batch") {
      navigate("/create");
    } else if (type === "single") {
      navigate("/type");
    }
    else if (type === "pro") {
      navigate("/select");
    } else {
      navigate("/quick");
    }
  };
  return (
    <section className="select  flex h-screen w-full items-end">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-6xl text-4xl font-black uppercase">
            Mint <br className="hidden md:block" />
            Your NFT
          </h1>
          {/* <p className="font-semibold py-4 text-xl md:text-2xl">
            in just 4 clicks 🖱
          </p> */}
          <div className="options__container pt-2">
            <OptionItem
              heading="🍀 Quick Mint"
              description="A quick gasless NFT minting solution for those who just want to mint a single NFT."
              buttonText="Continue"
              isExtended={extended === "quick"}
              type={"quick"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            />
            
             <OptionItem
              heading="⭐️ Single Mint"
              description="Mint a unique piece of art - as a Soulbound to a Million copies -  completely gas-free."
              buttonText="Continue"
              isExtended={extended === "single"}
              type={"single"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            />
            <OptionItem
              heading="✨ Batch Mint"
              description="Mint a family of unique NFTs in one go."
              buttonText="Continue"
              isExtended={extended === "batch"}
              type={"batch"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            />
           
          </div>
        </div>
      </ContentBlock>
      <Assistant />
    </section>
  );
};

export default MintYourNFT;
