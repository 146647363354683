import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import fileIcon from "../../assets/images/fileIcon.png";
import closeIcon from "../../assets/images/closeIcon.png";

import { useNavigate } from "react-router-dom";
import { useGCtx } from "../../Contexts/GlobalContext";
import Assistant from "../../Components/Assitant/Assistant";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import alertCircle from "../../assets/images/alertCircle.svg";
import JSZip from "jszip";
const acceptedTypes = [
  "JPG",
  "PNG",
  "JPEG",
  "GIF",
  "SVG",
  "MP4",
  "WEBM",
  "WEBP",
  "MP3",
  "WAV",
  "OGG",
  "GLB",
  "GLTF",
  "GLB",
];
const acceptedPreviewTypes = ["JPG", "JPEG", "PNG", "WEBP"];
const BatchUpload = () => {
  const { callAssistant, batchFiles, setBatchFiles, wallet } = useGCtx();
  const [sessionName, setSessionName] = useState("");
  const [uploadPercent, setUploadPercent] = useState(0.0);
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const navigate = useNavigate();
  const getSupportedFileTypes = (id) => {
    if (id === "preview") {
      return (
        <p className="copytext files preview">
          <span className="ml-2">ⓘ</span>
        </p>
      );
    } else if (id === "assets") {
      return (
        <p className="copytext files assets">
          <span className="ml-2">ⓘ</span>
        </p>
      );
    } else {
      return (
        <p className="copytext files metadata">
          <span className="ml-2">ⓘ</span>
        </p>
      );
    }
  };
  const getFileExtention = (filename) => {
    return filename.split(".").pop();
  };

  const handleResetFile = (id) => {
    setBatchFiles({ ...batchFiles, [id]: {} });
  };

  useEffect(() => {
    if (error.length) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);

  const handleUploadFiles = async () => {
    try {
      window.gtag("event", "batch-upload", {
        event_category: "batch",
        event_label: "batch",
      });
      setIsUploading(true);

      var assetFile = batchFiles.assets;

      var previewFile = batchFiles.preview;
      var metadataFile = batchFiles.metadata;

      previewFile.forEach((file) => {
        if (file.size > 100000000) {
          setIsUploading(false);
          setError(
            "Whoops! Max file size exceeded. Please upload a file less than 100MB.."
          );
          return;
        }
        if (
          !acceptedPreviewTypes.includes(
            getFileExtention(file.name.toUpperCase())
          )
        ) {
          setIsUploading(false);
          setError("You uploaded an unsupported format. Please try again.");
          return;
        }
      });
      assetFile.forEach((file) => {
        if (file.size > 100000000) {
          setIsUploading(false);
          setError(
            "Whoops! Max file size exceeded. Please upload a file less than 100MB.."
          );
          return;
        }
        if (
          !acceptedTypes.includes(getFileExtention(file.name.toUpperCase()))
        ) {
          setIsUploading(false);
          setError("You uploaded an unsupported format. Please try again.");
          return;
        }
      });

      if (assetFile.length !== previewFile.length) {
        callAssistant("No. of preview files should be same as no. of assets");
        return;
      }
      const formData = new FormData();
      formData.append("wallet", wallet);
      formData.append("sessionName", sessionName);
      formData.append("sessionID", uuidv4());
      for (const im of assetFile) {
        formData.append("assets", im);
      }
      for (const im of previewFile) {
        formData.append("preview", im);
      }
      // formData.append("assets", assetFile, assetFile.name + "_asset.zip");
      // formData.append(
      //   "preview",
      //   previewFile,
      //   previewFile.name + "_preview.zip"
      // );
      formData.append("csv", metadataFile, metadataFile.name);

      const uploadingCall = await axios.request({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/batch/upload`,
        data: formData,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        onUploadProgress: (p) => {
          setUploadPercent(p.loaded / p.total);
        },
      });

      const uploadResponse = await uploadingCall.data;

      if (uploadResponse.error) {
        setError(
          "Uh oh. There's something wrong with the metadata. Please check the files and try again"
        );
        setIsUploading(false);
      } else {
        navigate(`/batch/create-collection/${uploadResponse.sessionID}`);
        setIsUploading(false);
        setBatchFiles({ assets: [], metadata: {}, preview: [] });
      }
    } catch (e) {
      setError("Please check the files and retry upload.");
      setBatchFiles({ assets: [], metadata: {}, preview: [] });

      setIsUploading(false);
    }
  };

  const extractAndSave = (zip, id) => {
    const unzipper = new JSZip();
    const tempFiles = {
      id,
      name: zip.name,

      lastModified: zip.lastModified,
      lastModifiedDate: zip.lastModifiedDate,
      size: zip.size,
      files: [],
    };
    unzipper.loadAsync(zip).then(({ files }) => {
      Object.keys(files).forEach((item, index) => {
        if (!item.includes("__MACOSX/")) {
          tempFiles.files.push(files[item]);
        }
      });
    });
    console.log(tempFiles);
    setBatchFiles({ ...batchFiles, [id]: tempFiles });
  };

  return (
    <section className="select  flex h-screen w-full items-end">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-6xl text-4xl font-black uppercase">
            Upload <br className="hidden md:block" />
            Assets
          </h1>
          {!isUploading ? (
            <div>
              <div>
                {error ? (
                  <div className="flex font-semibold text-[#333333] items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                    <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                    <p>{error}</p>
                  </div>
                ) : (
                  <p className="text-xl py-2 font-semibold text-[#333333] md:text-2xl">
                    Session Name*
                  </p>
                )}
                <input
                  type="name"
                  name="sessionName"
                  id="sessionName"
                  value={sessionName}
                  onChange={(e) => setSessionName(e.target.value)}
                  placeholder="Set a name for your batch mint session"
                  className="w-full border-2 border-solid border-[#cccccc] p-2 px-2 rounded-md my-2 text-sm"
                />
              </div>

              <UploadItem
                fileItem={batchFiles.assets}
                handleResetFile={handleResetFile}
                batchFiles={batchFiles}
                setBatchFiles={setBatchFiles}
                type="Assets"
                id="assets"
                callAssistant={callAssistant}
                getFileExtention={getFileExtention}
                extractAndSave={extractAndSave}
                getSupportedFileTypes={getSupportedFileTypes}
              />
              <UploadItem
                fileItem={batchFiles.preview}
                handleResetFile={handleResetFile}
                batchFiles={batchFiles}
                setBatchFiles={setBatchFiles}
                type="Preview Images"
                id="preview"
                callAssistant={callAssistant}
                getFileExtention={getFileExtention}
                extractAndSave={extractAndSave}
                getSupportedFileTypes={getSupportedFileTypes}
              />
              <UploadItem
                fileItem={batchFiles.metadata}
                handleResetFile={handleResetFile}
                batchFiles={batchFiles}
                setBatchFiles={setBatchFiles}
                type="Metadata"
                id="metadata"
                callAssistant={callAssistant}
                getSupportedFileTypes={getSupportedFileTypes}
                getFileExtention={getFileExtention}
                extractAndSave={extractAndSave}
              />
            </div>
          ) : (
            <div>
              {/* <img src={} alt="" /> */}
              <div className="text-center my-4">
                <ClipLoader size={60} color="#3BBEAE" />
              </div>
              <p className="text-center font-semibold">
                {(uploadPercent * 100).toFixed(2) + "%/100%"} Uploading...{" "}
                <br /> Please don't close this window.{" "}
              </p>

              <div className="pt-4">
                <div className="bar w-full rounded-full h-3 overflow-hidden relative bg-[#fefefe]">
                  <div
                    className={`barinner bg-[#3BBEAE] h-full rounded-full trasition-width duration-300`}
                    style={{ width: uploadPercent * 100 + "%" }}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!isUploading ? (
          <div className="button__container text-center">
            <button
              onClick={handleUploadFiles}
              className="bg-[#3BBEAE] w-full px-5 py-4 my-3 rounded-full text-white font-semibold text-center"
              disabled={
                !batchFiles.metadata.name ||
                !batchFiles.assets.length ||
                !sessionName
              }
            >
              Continue
            </button>
            <p
              onClick={() => (window.location.href = "/")}
              className="cursor-pointer"
            >
              Cancel
            </p>
          </div>
        ) : null}
      </ContentBlock>
      <Assistant />
    </section>
  );
};

export default BatchUpload;

const UploadItem = ({
  fileItem,
  batchFiles,
  setBatchFiles,
  handleResetFile,
  type,
  id,
  callAssistant,
  getFileExtention,
  getSupportedFileTypes,
}) => {
  return !fileItem.length && !fileItem.name ? (
    <Dropzone
      multiple={true}
      onDrop={(acceptedFiles) => {
        // Do something with the files
        if (!acceptedFiles[0]) {
          console.log("Error Uploading File / No files present");
          return;
        }

        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents

          if (
            id === "metadata" &&
            getFileExtention(acceptedFiles[0].name).toLowerCase() !== "json" &&
            getFileExtention(acceptedFiles[0].name).toLowerCase() !== "csv"
          ) {
            callAssistant("Metadata should be a JSON/CSV");
          } else if (
            id === "assets" &&
            !acceptedTypes.includes(
              getFileExtention(acceptedFiles[0].name).toUpperCase()
            )
          ) {
            callAssistant("Please upload a valid file type.");
          } else if (
            id === "preview" &&
            !acceptedPreviewTypes.includes(
              getFileExtention(acceptedFiles[0].name).toUpperCase()
            )
          ) {
            callAssistant("Please upload a valid file type.");
          } else {
            // setBatchFiles({ ...batchFiles, [id]: acceptedFiles[0] });
            if (id === "metadata") {
              setBatchFiles({ ...batchFiles, metadata: acceptedFiles[0] });
            } else {
              // extractAndSave(acceptedFiles[0], id);

              setBatchFiles({ ...batchFiles, [id]: acceptedFiles });
            }
          }

          //todo write logic to go mint
        };
        reader.readAsArrayBuffer(acceptedFiles[0]);
      }}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          className="dndarea border-2 rounded-md h-22 p-4 my-2"
          {...getRootProps()}
        >
          <input {...getInputProps()} multiple={true} className="w-0 h-0" />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <div className="flex items-center justify-start w-full">
              <img src={fileIcon} alt="File Icon" className="h-6 mr-3" />
              <p className="flex items-center justify-between w-full">
                Select {type}
                {getSupportedFileTypes(id)}
              </p>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  ) : (
    <div className="dndarea mt-3">
      <div className="flex border-[#fefefe] border-solid border-2 items-center justify-between p-2 rounded-lg">
        {!batchFiles[id].name ? (
          <p className="font-semibold">{batchFiles[id].length} Files</p>
        ) : batchFiles[id].name.length > 30 ? (
          <p className="font-semibold">
            {batchFiles[id].name.slice(0, 8)}...
            {batchFiles[id].name.slice(batchFiles[id].name.length - 10)}
          </p>
        ) : (
          <p className="font-semibold">{batchFiles[id].name}</p>
        )}

        <div className="closeicon">
          <img
            src={closeIcon}
            alt=""
            className="h-5 cursor-pointer"
            onClick={() => handleResetFile(id)}
          />
        </div>
      </div>
    </div>
  );
};
