import { useEffect, useState } from "react";

import Dropzone from "react-dropzone";
import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import closeIcon from "../../assets/images/closeIcon.png";

import { useNavigate } from "react-router-dom";
import { useGCtx } from "../../Contexts/GlobalContext";
import Assistant from "../../Components/Assitant/Assistant";
import alertCircle from "../../assets/images/alertCircle.svg";

const UploadSingle = ({ history }) => {
  const {
    uploadedFile,
    setUploadedFile,
    assetPreview,
    setAssetPreview,
    callAssistant,
  } = useGCtx();

  const acceptedTypes = [
    "JPG",
    "PNG",
    "JPEG",
    "GIF",
    "SVG",
    "MP4",
    "WEBM",
    "WEBP",
    "MP3",
    "WAV",
    "OGG",
    "GLB",
    "GLTF",
    "GLB",
  ];

  //eslint-disable-next-line
  const [fileType, setFileType] = useState("");
  const [error, setError] = useState("");

  //eslint-disable-next-line
  const [uploadedFileExtension, setUploadedFileExtension] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const navigate = useNavigate();

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const getFileType = (file) => {
    return file["type"].split("/")[0];
  };
  const getFileExtention = (filename) => {
    return filename.split(".").pop();
  };

  const getFilePreview = (file) => {
    if (fileType === "image") {
      return <img src={file.file} alt="" className="h-40 mx-auto" />;
    } else if (fileType === "video") {
      return <video src={file.file} controls className="h-40 mx-auto" />;
    } else if (fileType === "audio") {
      return (
        <audio src={file.file} controls className="mx-auto w-[90%] h-auto" />
      );
    }
  };

  useEffect(() => {
    if (error?.length) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);

  const handleResetFile = () => {
    setUploadedFile({ file: {}, fileInfo: {} });
    setAssetPreview({ file: {}, fileInfo: {} });
    setIsUploaded(false);
    setFileType("");
    setUploadedFileExtension("");
  };
  const handleCancel = () => {
    handleResetFile();
    navigate(-1);
  };
  const uploadPreview = () => {
    return (
      <Dropzone
        onDrop={(acceptedFiles) => {
          // Do something with the files
          if (!acceptedFiles[0]) {
            console.log("Error Uploading File / No files present");
            return;
          }
          const reader = new FileReader();
          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = () => {
            // Do whatever you want with the file contents
            if (getFileType(acceptedFiles[0]) === "image") {
              const binaryStr = reader.result;
              const blob = new Blob([binaryStr]);
              const url = URL.createObjectURL(blob);
              let singleFile = acceptedFiles[0];
              setAssetPreview({ file: url, fileInfo: singleFile });
            } else {
              callAssistant(
                "Only images are allowed as thumbnails. Please check your thumbnail file type."
              );
              setError(
                "Only images are allowed as thumbnails. Please check your thumbnail file type."
              );
            }
          };
          reader.readAsArrayBuffer(acceptedFiles[0]);
        }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className="dndarea border-solid my-2 border-2 rounded-md h-44 p-4 grid items-center text-center"
            {...getRootProps()}
          >
            <input {...getInputProps()} multiple={false} accept="image/*" />
            {isDragActive ? (
              <p>Drop the files here...</p>
            ) : (
              <div>
                <img
                  src={uploadIcon}
                  alt="File Icon"
                  className="h-14 mx-auto "
                />
                <p>Upload a thumbnail</p>
              </div>
            )}
          </div>
        )}
      </Dropzone>
    );
  };
  return (
    <section className="select flex h-screen w-full items-end">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-6xl text-4xl font-black uppercase">
            Upload <br className="hidden md:block" />
            Your NFT <br className="hidden md:block" />
            File
          </h1>
          <p className="text-xl py-2 font-semibold text-[#333333] md:text-2xl">
            Drag and drop your NFT file
          </p>
          {error ? (
            <div className="font-semibold text-[#333333] flex items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
              <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
              <p>{error}</p>
            </div>
          ) : null}
          {!isUploaded ? (
            <Dropzone
              onDrop={(acceptedFiles) => {
                // Do something with the files
                if (!acceptedFiles[0]) {
                  console.log("Error Uploading File / No files present");
                  return;
                }
                const reader = new FileReader();
                reader.onabort = () => console.log("file reading was aborted");
                reader.onerror = () => console.log("file reading has failed");
                reader.onload = () => {
                  // Do whatever you want with the file contents

                  if (acceptedFiles[0].size > 104857600) {
                    setError(
                      "Whoops! Whoops! Max file size exceeded. Please upload a file less than 100MB.. Please upload a file less than 100MB."
                    );
                    callAssistant(
                      "Whoops! Whoops! Max file size exceeded. Please upload a file less than 100MB.. Please upload a file less than 100MB."
                    );
                  } else if (
                    !acceptedTypes.includes(
                      getFileExtention(acceptedFiles[0].name.toUpperCase())
                    )
                  ) {
                    setError("Please upload a valid file");
                    callAssistant("Please upload a valid file");
                  } else {
                    setError("");
                    const binaryStr = reader.result;
                    const blob = new Blob([binaryStr]);
                    const url = URL.createObjectURL(blob);
                    let singleFile = acceptedFiles[0];
                    var tempFileItem = { file: url, fileInfo: singleFile };
                    setUploadedFile(tempFileItem);
                    let tempFileType = getFileExtention(singleFile.name);
                    setUploadedFileExtension(tempFileType);
                    setFileType(getFileType(acceptedFiles[0]));
                    if (getFileType(acceptedFiles[0]) === "image") {
                      setIsUploaded(true);
                      setAssetPreview(tempFileItem);
                      //todo write logic to go mint
                    } else {
                      setIsUploaded(true);
                      callAssistant(
                        "Please Upload a thumbnail for your file as a PNG or JPG"
                      );
                      console.log("NOT IMAGE");
                      //write logic to add preview file
                    }
                  }
                };
                reader.readAsArrayBuffer(acceptedFiles[0]);
              }}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  className="dndarea border-dashed border-2 rounded-md h-44 p-4 grid items-center text-center"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} multiple={false} />
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : (
                    <div>
                      <img
                        src={uploadIcon}
                        alt="File Icon"
                        className="h-14 mx-auto"
                      />
                      <p>
                        <span className="text-sm">Max file size 100MB</span>{" "}
                        <br />
                        (JPG, PNG, JPEG, GIF, SVG, MP4, WEBM, WEBP, MP3, WAV,
                        OGG, GLTF, GLB)
                      </p>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          ) : (
            <div className="dndarea mt-3">
              <div className="flex border-[#fefefe] border-solid border-2 items-center justify-between p-2 rounded-lg">
                {uploadedFile.fileInfo.name.length > 30 ? (
                  <p className="font-semibold">
                    {uploadedFile.fileInfo.name.slice(0, 8)}...
                    {uploadedFile.fileInfo.name.slice(
                      uploadedFile.fileInfo.name.length - 10
                    )}
                  </p>
                ) : (
                  <p className="font-semibold">{uploadedFile.fileInfo.name}</p>
                )}

                <div className="closeicon">
                  <img
                    src={closeIcon}
                    alt=""
                    className="h-5 cursor-pointer"
                    onClick={handleResetFile}
                  />
                </div>
              </div>

              {fileType && fileType === "image" ? (
                <div className="my-2 border-[#fefefe] border-solid border-2 h-44 text-center p-2 rounded-lg">
                  {getFilePreview(uploadedFile)}
                </div>
              ) : !assetPreview.fileInfo.name ? (
                uploadPreview()
              ) : (
                <div className="my-2 border-[#fefefe] border-solid border-2 h-44 text-center p-2 rounded-lg">
                  <img
                    src={assetPreview.file}
                    alt=""
                    className="h-40 mx-auto"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="button__container text-center">
          <button
            onClick={() => navigate("/build-nft")}
            className="bg-[#3BBEAE] w-full px-5 py-4 my-3 rounded-full text-white font-semibold text-center"
            disabled={!isUploaded}
          >
            Edit My NFT
          </button>
          <p onClick={() => handleCancel()} className="cursor-pointer">
            Cancel
          </p>
        </div>
      </ContentBlock>
      <Assistant />
    </section>
  );
};

export default UploadSingle;
