import { useLocation, useNavigate } from "react-router-dom";

const ProgressBar = ({ phase, percentage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="py-2">
      <div className="flex justify-between text-[14px] py-2 font-semibold">
        {location.pathname !== "/select" && phase !== 2 ? (
          <p onClick={() => navigate(-1)} className="cursor-pointer">
            &#8592; Back
          </p>
        ) : null}
        <p className="text-[#33333399] self-end ml-auto">Phase {phase}/2</p>
      </div>
      <div className="bar w-full rounded-full h-3 overflow-hidden relative bg-[#fefefe]">
        <div
          className={`barinner bg-[#3BBEAE] h-full rounded-full trasition-width duration-300`}
          style={{ width: percentage + "%" }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
