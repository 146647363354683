import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Assistant from "../../Components/Assitant/Assistant";
import alertCircle from "../../assets/images/alertCircle.svg";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import { useGCtx } from "../../Contexts/GlobalContext";

const Connect = ({ prev }) => {
  const navigate = useNavigate();
  const [werror, setwError] = useState("");
  const { hasSigned } = useGCtx();
  const { address, isConnected } = useAccount();
  useEffect(() => {
    console.log({ hasSigned, isConnected });
    if (isConnected && hasSigned) {
      handleNextPage();
    }
  }, [hasSigned, isConnected]);

  const handleNextPage = () => {
    if (prev && prev.pathname !== "/") {
      navigate([prev.pathname]);
    } else {
      navigate("/start");
    }
  };

  return (
    <section className="connect  flex h-screen w-full items-end">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-6xl text-4xl font-black uppercase">
            Connect <br className="hidden md:block" />
            Your <br className="hidden md:block" />
            Wallet
          </h1>
          <p className="font-semibold py-4 text-xl md:text-2xl">
            Connect a web3 wallet to mint your NFT. Choose between Metamask or
            other popular wallets.
          </p>
          {werror ? (
            <div className="flex md:hidden font-semibold text-[#333333] items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
              <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
              <p>{werror}</p>
            </div>
          ) : null}
        </div>
        <div className="buttons btn-rainbow">
          <ConnectButton
            showBalance={{
              smallScreen: false,
              largeScreen: false,
            }}
          />
        </div>
      </ContentBlock>
      <Assistant />
    </section>
  );
};

export default Connect;
