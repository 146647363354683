import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGCtx } from "../../Contexts/GlobalContext";
import claimed from "../../assets/images/claimed.svg";
import fingerprint from "../../assets/images/fingerprint.svg";
import supplyIcon from "../../assets/images/supply.svg";
import timer from "../../assets/images/timer.svg";
import ClaimPopup from "../../Components/ClaimPopup/ClaimPopup";
import Web3 from "web3";
import { v4 as uuidv4 } from "uuid";
// import GlobalABI from "../../assets/ABI/ClaimNFTGlobal.json";
import alertCircle from "../../assets/images/alertCircle.svg";
import { isAddress } from "../../utils";
import { ClipLoader } from "react-spinners";
import { globalNFT, globalClaim } from "../../connectors";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import closeIcon from "../../assets/images/closeIcon.png";
import ClaimBlock from "../../Components/ClaimBlock/ClaimBlock";
import linkIcon from "../../assets/images/linkIcon.svg";
import { useAccount, useSigner } from "wagmi";

const pageUrl = window.location.origin;

const BASE_URL = process.env.REACT_APP_BASE_URL;
const CreateClaim = () => {
  const navigate = useNavigate();
  const {
    nftType,
    nftData,
    assetPreview,
    wallet,
    mintedTokenId,
    dehiddenModules,
    erc721Type,
  } = useGCtx();
  const { data: signer } = useSigner();
  const [currentClaimStep, setCurrentClaimStep] = useState("1");
  const [customDate, setCustomDate] = useState(false);
  const [claimType, setClaimType] = useState("");
  const [isListed, setIsListed] = useState(false);
  const [isListing, setIsListing] = useState(false);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [claimUrl, setClaimUrl] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [whiteListed721, setWhiteListed721] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [limit1155, setLimit1155] = useState(1);
  const [error, setError] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [maxSupply, setMaxSupply] = useState(1);
  const [selectedFile, setSelectedFile] = useState("");
  const [published, setPublished] = useState(false);
  const supply = nftData.amount;
  var claimNFTContract = globalClaim.ClaimNFT.address;
  const { connector } = useAccount();

  useEffect(() => {
    var tempsessionId = uuidv4();
    if (claimUrl === "") {
      setSessionId(tempsessionId);
    }
    console.log(sessionId);
  }, []);

  useEffect(() => {
    console.log(nftData);
    if (error.length) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);
  var contractAddresses = [
    globalNFT.ERC721.address,
    globalNFT.SoulBound.address,
    globalNFT.ERC1155.address,
    globalNFT.Autograph.address,
  ];

  const getContractToUse = () => {
    if (dehiddenModules?.isAutographedItem) {
      return contractAddresses[3];
    } else if (nftType.toLowerCase() === "erc721") {
      if (erc721Type.toLowerCase() === "721only") {
        return contractAddresses[0];
      } else {
        return contractAddresses[1];
      }
    } else if (nftType.toLowerCase() === "erc1155") {
      return contractAddresses[2];
    }
  };

  const getTodayDate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;

    return today;
  };

  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key).then(
      function () {
        console.log("Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const handleNext = async () => {
    const provider = await connector?.getProvider();
    const web3 = new Web3(provider);
    const validate = async () => {
      setIsLoading(true);
      return new Promise(async (response, reject) => {
        if (!claimType) {
          response(false);
          setIsLoading(false);
          setError(
            "Do you want your NFTs to be exclusive or public? Please specify."
          );
          return;
        } else if (customDate && (!startDate || !endDate)) {
          response(false);
          setIsLoading(false);
          setError("Please choose both start and end dates.");
          return;
        } else if (claimUrl.replace(/\s/g, "").length < 1) {
          response(false);
          setIsLoading(false);
          setError("Please enter a valid URL.");
          return;
        } else if (
          claimType === "custom" &&
          nftType.toUpperCase() === "ERC721" &&
          !isAddress(whiteListed721)
        ) {
          response(false);
          setIsLoading(false);
          setError("Please enter a valid Address");
          return;
        }
        //check if name already exist
        if (claimType === "anyone" && nftType.toUpperCase() === "ERC1155") {
          const contract = new web3.eth.Contract(
            globalNFT.ERC1155.abi,
            globalNFT.ERC1155.address
          );
          const balance = await contract.methods
            .balanceOf(wallet, mintedTokenId)
            .call();
          console.log(balance);
          console.log(maxSupply);
          if (parseInt(balance) < parseInt(maxSupply)) {
            response(false);
            setError(
              "The max NFT supply should be greater than or equal to your balance!"
            );
          }
        }

        const checkAvailability = await fetch(
          `${BASE_URL}/v1/claim/custom/checkAvailability/${claimUrl}`,
          {
            method: "GET",
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        const availability = await checkAvailability.json();

        if (!availability.available) {
          response(false);
          setError(
            "The custom URL you've entered is already in use. Please enter a new one."
          );
        }

        if (
          claimType === "custom" &&
          nftType.toUpperCase() === "ERC1155" &&
          selectedFile === ""
        ) {
          response(false);
          setError("Ahem, you're yet to add the CSV file!");
        }

        setIsLoading(false);
        response(true);
      });
    };

    setIsListed(await validate());
  };

  const createClaimPage = async () => {
    try {
      setIsLoading(true);
      console.log(sessionId);
      console.log(nftData);
      const data = {
        wallet: wallet, //Required - The wallet address
        sessionID: sessionId, //Required - Session id to be generated from the front-end
        supply: nftData.amount || maxSupply, //nftData.amount, //Required - Total no.of supply they wish to put on the claim page. For ERC721 it should be 1 only.
        tokenId: mintedTokenId, //Required - Token id of the NFT
        collectionName: nftData.name, //Required
        collectionDescription: nftData.description, //Required
        contractAddress: getContractToUse(), //Required - The contract address of the NFT
        contractStandard: nftType, //Required - The contract standard 1155 or 721
        exclusive: claimType === "custom", //Exclusive true is for the claim page with whitelisted wallets.
        // csv: "", //Only if exclusive is true.
        startDate: parseInt((new Date(startDate).getTime() / 1000).toFixed(0)), //Unix timestamp of date if not 0
        endDate: parseInt((new Date(endDate).getTime() / 1000).toFixed(0)),
        customName: claimUrl, //the custom URL for the page,
        whitelistedAddress: whiteListed721,
      };
      const formData = new FormData();

      for (const property in data) {
        // console.log(`${property}: ${data[property]}`);
        formData.append(property, data[property]);
      }

      if (nftType.toUpperCase() === "ERC1155" && claimType === "custom") {
        if (selectedFile) {
          formData.append("csv", selectedFile);
        }
      }

      const createClaimCall = await fetch(`${BASE_URL}/v1/claim/create`, {
        method: "POST",
        body: formData,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      });

      const createClaimRes = await createClaimCall.json();

      if (createClaimRes?.status === true) {
        setIsListing(true);
        setIsLoading(false);
      } else {
        console.log(createClaimRes);
        setError("Unable to create claim page");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setError("Unable to create claim page");
      setIsLoading(false);
    }
  };

  // const editClaimPage = async () => {};

  const approve = async () => {
    try {
      setIsLoading(true);
      const provider = await connector?.getProvider();
      const web3 = new Web3(provider);

      var currentAbi,
        currentContract = getContractToUse();
      currentAbi =
        nftType.toUpperCase() === "ERC721"
          ? globalClaim.Sample721.abi
          : globalClaim.Sample1155.abi;
      const contract = new web3.eth.Contract(currentAbi, currentContract);

      if (nftType.toUpperCase() === "ERC721") {
        const totalGas = await contract.methods
          .approve(claimNFTContract, mintedTokenId)
          .estimateGas({ from: wallet });
        const gasPrice = await web3.eth.getGasPrice();

        const tx = await contract.methods
          .approve(claimNFTContract, mintedTokenId)
          .send({ from: wallet, gas: totalGas.toString(), gasPrice: gasPrice });

        if (tx) {
          setCurrentStep("2");
          console.log(tx);
          setIsLoading(false);
        } else {
          console.log("Contract Interaction Failed", tx);
          setError(
            "There's been an error approving your transaction. Please check your wallet & try again."
          );
          setIsLoading(false);
        }
      } else {
        const totalGas = await contract.methods
          .setApprovalForAll(claimNFTContract, true)
          .estimateGas({ from: wallet });
        const gasPrice = await web3.eth.getGasPrice();

        const tx = await contract.methods
          .setApprovalForAll(claimNFTContract, true)
          .send({ from: wallet, gas: totalGas.toString(), gasPrice: gasPrice });

        if (tx) {
          console.log(tx);
          setIsLoading(false);
          setCurrentStep("2");
        } else {
          console.log("Contract Interaction Failed", tx);
          setError(
            "There's been an error approving your transaction. Please check your wallet & try again."
          );
          setIsLoading(false);
        }
      }
    } catch (error) {
      setError(
        "There's been an error approving your transaction. Please check your wallet & try again."
      );
      setIsLoading(false);

      console.log(error);
    }
  };

  const register = async () => {
    try {
      setIsLoading(true);
      const provider = await connector?.getProvider();
      const web3 = new Web3(provider);
      var currentContract = getContractToUse();
      var currentAbi = globalClaim.ClaimNFT.abi;

      const contract = new web3.eth.Contract(currentAbi, claimNFTContract);

      const toAddress =
        claimType !== "anyone"
          ? whiteListed721
          : "0x0000000000000000000000000000000000000000";

      const currentStart = parseInt(
        (new Date(startDate).getTime() / 1000).toFixed(0)
      );
      const currentEnd = parseInt(
        (new Date(endDate).getTime() / 1000).toFixed(0)
      );

      if (nftType.toUpperCase() === "ERC721") {
        console.log(toAddress);
        const totalGas = await contract.methods
          .registerERC721(
            sessionId,
            currentContract,
            mintedTokenId,
            wallet,
            toAddress,
            currentStart,
            currentEnd
          )
          .estimateGas({ from: wallet });
        const gasPrice = await web3.eth.getGasPrice();

        const tx = await contract.methods
          .registerERC721(
            sessionId,
            currentContract,
            mintedTokenId,
            wallet,
            toAddress,
            currentStart,
            currentEnd
          )
          .send({ from: wallet, gas: totalGas.toString(), gasPrice: gasPrice });

        if (tx) {
          console.log(tx);
          setIsLoading(false);
          setCurrentStep("3");
        } else {
          console.log("Contract Interaction Failed", tx);
          setError(
            "There's been an error registering your claim. Please try again."
          );
          setIsLoading(false);
        }
      } else if (nftType.toUpperCase() === "ERC1155") {
        if (claimType === "anyone") {
          const totalGas = await contract.methods
            .registerERC1155(
              sessionId,
              currentContract,
              mintedTokenId,
              wallet,
              supply,
              limit1155,
              currentStart,
              currentEnd
            )
            .estimateGas({ from: wallet });
          const gasPrice = await web3.eth.getGasPrice();

          const tx = await contract.methods
            .registerERC1155(
              sessionId,
              currentContract,
              mintedTokenId,
              wallet,
              supply,
              limit1155,
              currentStart,
              currentEnd
            )
            .send({
              from: wallet,
              gas: totalGas.toString(),
              gasPrice: gasPrice,
            });

          if (tx) {
            console.log(tx);

            setIsLoading(false);
            setCurrentStep("3");
          } else {
            console.log("Contract Interaction Failed", tx);
            setError(
              "There's been an error registering your claim. Please try again."
            );
            setIsLoading(false);
          }
        } else if (claimType === "custom") {
          const totalGas = await contract.methods
            .registerListERC1155(
              sessionId,
              currentContract,
              mintedTokenId,
              wallet,
              currentStart,
              currentEnd
            )
            .estimateGas({ from: wallet });
          const gasPrice = await web3.eth.getGasPrice();

          const tx = await contract.methods
            .registerListERC1155(
              sessionId,
              currentContract,
              mintedTokenId,
              wallet,
              currentStart,
              currentEnd
            )
            .send({
              from: wallet,
              gas: totalGas.toString(),
              gasPrice: gasPrice,
            });

          if (tx) {
            console.log(tx);
            setCurrentStep("3");
            setIsLoading(false);
          } else {
            console.log("Contract Interaction Failed", tx);
            setError(
              "There's been an error registering your claim. Please try again."
            );
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  const confirmListing = async () => {
    try {
      const postData = {
        wallet: wallet, //Required - The wallet address
        sessionID: sessionId,
        contractStandard: nftType.toUpperCase(),
        tokenId: mintedTokenId,
        url: claimUrl,
      };
      const confirmListCall = await fetch(`${BASE_URL}/v1/claim/activate`, {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const confirmListingRes = await confirmListCall.json();

      if (confirmListingRes?.status === true) {
        setIsListing(false);
        // navigate(`/claim-page/${claimUrl}`);
        setPublished(true);
      } else {
        setError(
          "Looks like there's an error confirming your transaction. Please try again."
        );
        // setIsListing(false);
      }
    } catch (error) {
      console.log(error.message);
      setError(
        "Looks like there's an error confirming your transaction. Please try again."
      );
      // setIsListing(false);
    }
  };
  return (
    <section className=" flex h-screen w-full items-end">
      <div className="video__wrapper">
        <video
          src={"/videos/Screen1.mp4"}
          autoPlay
          loop
          muted
          playsInline
        ></video>
      </div>

      {!isListed ? (
        <ContentBlock>
          <div className="top__content">
            <h1 className="md:text-6xl text-4xl font-black uppercase">
              Create Claim Page
            </h1>
            <div className="steps flex items-center gap-1 my-2">
              <div className="step w-[50%] h-[10px] rounded-full bg-[#3BBEAE]"></div>
              <div
                className={
                  currentClaimStep === "2"
                    ? "step w-[50%] h-[10px] rounded-full bg-[#3BBEAE]"
                    : "step w-[50%] h-[10px] rounded-full bg-[#fefefe]"
                }
              ></div>
            </div>
            {error ? (
              <div className="flex font-semibold text-[#333333] items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                <p>{error}</p>
              </div>
            ) : null}
            {currentClaimStep === "1" ? (
              <div className="options">
                <div className="ccp__option__outer border-2 border-[#cacdd5] rounded-lg p-3 my-3">
                  <div className="ccp__option__item flex py-2">
                    <div className="ccp__option__item__left mr-4">
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        value={customDate}
                        checked={customDate}
                        onChange={(e) => setCustomDate(e.target.checked)}
                      />
                    </div>
                    <div className="ccp__option__item__right">
                      <p className="font-semibold text-lg md:text-xl">
                        ⏰ Set claim period
                      </p>
                      <p className="text-xs text-[#8c93a3]">
                        Set the duration between which your NFT can be claimed
                      </p>
                      {customDate ? (
                        <div className="datepickers flex items-center gap-3 flex-wrap pt-3 ">
                          <div className="relative">
                            <div className="text-[14px] px-1 text-[#49536E]">
                              Start Date
                            </div>
                            <input
                              datepicker=""
                              value={startDate}
                              type="date"
                              min={getTodayDate()}
                              onChange={(e) => setStartDate(e.target.value)}
                              className="text-[14px] rounded-lg  p-2.5 py-2 datepicker-input border focus:border-[#8247E5]  focus:text-[#8247E5]"
                              placeholder="Select date"
                            />
                          </div>
                          <div className="relative">
                            <div className="text-[14px] px-1 text-[#49536E]">
                              End Date
                            </div>

                            <input
                              datepicker=""
                              value={endDate}
                              type="date"
                              min={getTodayDate()}
                              onChange={(e) => setEndDate(e.target.value)}
                              className="text-[14px] rounded-lg  p-2.5  datepicker-input border focus:border-[#8247E5] focus:text-[#8247E5]"
                              placeholder="Select date"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="ccp__option__outer border-2 border-[#cacdd5] rounded-lg p-3 my-3">
                  <div className="ccp__option__item flex py-2">
                    <div className="ccp__option__item__right">
                      <p className="font-semibold text-lg md:text-xl pb-2">
                        🚀 Set claim page URL
                      </p>
                      <div className="flex items-center border border-[#cacdd5] p-2 rounded-md">
                        <p className="text-xs text-[#8c93a3] ">
                          {pageUrl}/claim-page/
                        </p>
                        <input
                          type="text"
                          value={claimUrl}
                          onChange={(e) => setClaimUrl(e.target.value)}
                          className="inline   focus:outline-none text-xs"
                          placeholder="myfirstnft"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="options">
                <div className="ccp__option__outer border-2 border-[#cacdd5] rounded-lg p-3 my-3">
                  <div className="ccp__option__item flex py-2 ">
                    <div className="ccp__option__item__left mr-4">
                      <input
                        type="radio"
                        name="claimType"
                        checked={claimType === "anyone"}
                        onChange={(e) => setClaimType("anyone")}
                      />
                    </div>
                    <div className="ccp__option__item__right w-full">
                      <p className="font-semibold text-lg md:text-xl">
                        🥏 Anyone can claim
                      </p>
                      <p className="text-xs text-[#8c93a3] ">
                        Anyone can claim your NFT
                      </p>

                      {claimType === "anyone" && nftType !== "ERC721" ? (
                        <div className="py-2 w-full flex items-center flex-wrap gap-4">
                          <div className="w-full  md:w-[46%]">
                            <p className="text-xs font-semibold">
                              Max NFTs supply to claim
                            </p>
                            <input
                              type="number"
                              value={maxSupply}
                              onChange={(e) => setMaxSupply(e.target.value)}
                              min="1"
                              className="w-full border-2 border-solid border-[#cccccc] p-2 px-2 rounded-md my-2 text-sm"
                            />
                          </div>
                          <div className="w-full  md:w-[46%]">
                            <p className="text-xs font-semibold">
                              Max NFTs per wallet
                            </p>
                            <input
                              type="number"
                              value={limit1155}
                              onChange={(e) => setLimit1155(e.target.value)}
                              min="1"
                              className="w-full border-2 border-solid border-[#cccccc] p-2 px-2 rounded-md my-2 text-sm"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="ccp__option__item  flex ">
                    <div className="ccp__option__item__left mr-4">
                      <input
                        type="radio"
                        name="claimType"
                        checked={claimType === "custom"}
                        onChange={(e) => setClaimType("custom")}
                      />
                    </div>
                    <div className="ccp__option__item__right w-full">
                      <p className="font-semibold text-lg md:text-xl">
                        ⭐ Exclusive Access
                      </p>
                      <p className="text-xs text-[#8c93a3]">
                        Only specified wallet address/addresses can claim your
                        NFT
                      </p>

                      {claimType === "custom" &&
                      nftType.toUpperCase() === "ERC721" ? (
                        <div className="py-2 w-full">
                          <p className="text-xs font-semibold">
                            Enter Wallet Address
                          </p>
                          <input
                            type="text"
                            value={whiteListed721}
                            onChange={(e) => setWhiteListed721(e.target.value)}
                            className="w-full border-2 border-solid border-[#cccccc] p-2 px-2 rounded-md my-2 text-sm"
                          />
                        </div>
                      ) : null}

                      {claimType === "custom" &&
                      nftType.toUpperCase() === "ERC1155" ? (
                        <div className="py-2 w-full">
                          {!selectedFile?.name && (
                            <p className="text-xs font-semibold">
                              Upload CSV file
                            </p>
                          )}
                          <div className="flex">
                            {!selectedFile?.name ? (
                              <label
                                htmlFor="csv1155"
                                className="bg-[#3BBEAE] text-sm px-5 py-2 text-center font-semibold text-white rounded-lg mt-3 flex cursor-pointer items-center"
                              >
                                <img
                                  src={uploadIcon}
                                  className="h-4 mr-2"
                                  alt=""
                                />{" "}
                                Browse Files
                              </label>
                            ) : (
                              <div className="flex items-center">
                                <p className="font-semibold py-2 mr-2">
                                  {selectedFile.name}
                                </p>

                                <div
                                  className="close cursor-pointer"
                                  onClick={() => setSelectedFile({})}
                                >
                                  <img src={closeIcon} alt="" />
                                </div>
                              </div>
                            )}
                            <input
                              type="file"
                              onChange={(e) => {
                                console.log("on change!");
                                setSelectedFile(e.target.files[0]);
                                setMaxSupply(nftData.amount);
                              }}
                              hidden
                              name="csv1155"
                              id="csv1155"
                            />
                          </div>
                          {/* <button
                            onChange={(e) => setLimit1155(e.target.value)}
                          /> */}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {currentClaimStep === "1" ? (
            <div className="button__container text-center">
              <button
                className="bg-[#3BBEAE] w-full px-5 py-4 my-3 rounded-full text-white font-semibold text-center"
                onClick={() => setCurrentClaimStep("2")}
              >
                Next
              </button>
              <p onClick={() => navigate(-1)} className="cursor-pointer">
                Back
              </p>
            </div>
          ) : (
            <div className="button__container text-center">
              <button
                className="bg-[#3BBEAE] w-full px-5 py-4 my-3 rounded-full text-white font-semibold text-center"
                onClick={handleNext}
              >
                Confirm
              </button>
              <p
                onClick={() => setCurrentClaimStep("1")}
                className="cursor-pointer"
              >
                Back
              </p>
            </div>
          )}
        </ContentBlock>
      ) : (
        <ClaimBlock>
          <div className="w-full mx-auto h-full  lg:h-[100%] md:w-[40%] lg:w-[50%] max-w-[360px] md:max-w-[400px] lg:max-w-[50%]  gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden relative">
            <div className="image__container mx-auto text-center w-full h-full">
              <img
                src={assetPreview?.file}
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/notloaded.png";
                }}
                className="h-full w-full object-contain mx-auto rounded-xl "
              />
            </div>
            {endDate !== 0 && startDate !== 0 && (
              <div className="absolute bottom-6 left-6 right-6  claimprop rounded-xl overflow-hidden  mx-auto my-3 mt-4 font-semibold flex  items-center gt-w bg-[#3A4662] max-w-[400px]">
                <div className="prop flex items-center gap-1 text-white justify-center bg-[#3BBEAE] p-2 w-[50%]">
                  <img src={timer} className="h-4" alt="" /> Time left to Claim
                </div>
                <div className="prop-value p-2 px-4 text-white font-medium text-center bg-[#3A4662] w-[50%] ">
                  {Math.ceil(
                    (new Date(endDate).getTime() / 1000 -
                      new Date(startDate).getTime() / 1000) /
                      (24 * 60 * 60)
                  )}{" "}
                  Days
                </div>
              </div>
            )}
          </div>
          <div className="w-full md:h-[100%] lg:w-[60%] md:w-[50%] p-4 flex items-center md:overflow-y-auto">
            <div className="w-full">
              {error ? (
                <div className="flex font-semibold text-[#333333] items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                  <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                  <p>{error}</p>
                </div>
              ) : null}
              <h2 className="text-3xl font-black md:text-5xl  py-5 ">
                {nftData.name}
              </h2>
              <div className="claimprops flex items-center gap-2 flex-wrap font-regular gt-w ">
                <div className="claimprop rounded-lg overflow-hidden w-auto  ">
                  <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                    <img src={claimed} className="h-4 mr-1" alt="" />
                    {0} Claimed
                  </div>
                </div>
                <div className="claimprop w-auto  rounded-lg overflow-hidden">
                  <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                    <img src={fingerprint} className="h-4 mr-1" alt="" />
                    {claimType === "anyone" ? "Public" : "Exclusive"} Access
                  </div>
                </div>
                <div className="claimprop rounded-lg overflow-hidden w-auto  ">
                  <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                    <img src={supplyIcon} className="h-4 mr-1" alt="" />
                    {maxSupply} Supply
                  </div>
                </div>
              </div>
              <p className="font-medium text-[#1e1e1e] text-[18px] md:text-[21px] py-3 gt-w">
                {nftData.description}
              </p>

              <p className="py-1 pb-3 flex items-center gt-w">
                <img src={linkIcon} alt="" className="mr-2 h-4" />{" "}
                <span className="text-[#3BBEAE] font-semibold text-[14px] md:text-[16px]">
                  {window.location.host}/claim-page/{claimUrl}
                </span>
              </p>

              <div className="button__container text-center">
                {!published ? (
                  <button
                    className="bg-[#3BBEAE] w-full px-5 py-3 my-3 rounded-full text-white font-semibold text-center"
                    onClick={() => createClaimPage()}
                  >
                    Publish
                  </button>
                ) : (
                  <button className="bg-[#3BBEAE] w-full px-5 py-3 my-3 rounded-full text-white font-semibold text-center">
                    Published!
                  </button>
                )}

                {!published ? (
                  <p
                    onClick={() => setIsListed(false)}
                    className="cursor-pointer font-semibold"
                  >
                    Continue Editing
                  </p>
                ) : (
                  <p
                    onClick={() => navigate("/profile")}
                    className="cursor-pointer font-semibold"
                  >
                    Go to profile
                  </p>
                )}
              </div>
            </div>
          </div>
        </ClaimBlock>
      )}

      {isListing ? (
        <ClaimPopup
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          approve={approve}
          register={register}
          confirmListing={confirmListing}
          supply={supply}
          accessType={claimType === "anyone" ? "Public" : "Exclusive"}
        />
      ) : null}

      {isLoading ? (
        <div className="absolute z-20 inset-0 bg-[#000a] flex items-center justify-center ">
          <div className="text-center">
            <ClipLoader size={60} color="#3BBEAE" />
            <p className="text-white font-semibold py-3">Please Wait...</p>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default CreateClaim;
