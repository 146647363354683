import BuildBlock from "../../../Components/BuildBlock/BuildBlock";
// import hoodieIcon from "../../../assets/images/hoodieIcon.png";
// import cardIcon from "../../../assets/images/cardIcon.png";
import frameIcon from "../../../assets/images/frameIcon.png";
import BuildNftDetails from "../../../Components/BuildNftDetails/BuildNftDetails";
import { useState } from "react";
import { useGCtx } from "../../../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import checkCirclePurple from "../../../assets/images/checkCirclePurple.svg";
import PhygitalImage from "../../../Components/PhygitalImage/PhygitalImage";
import { globalNFT } from "../../../connectors";

const PhygitalModule = () => {
  const [selectedOption, setselectedOption] = useState("frame-fx");
  var contractAddresses = [
    globalNFT.ERC721.address,
    globalNFT.SoulBound.address,
    globalNFT.ERC1155.address,
    globalNFT.Autograph.address,
  ];
  const navigate = useNavigate();
  const [phygital, setPhygital] = useState({
    user_email: "",
    item: null,
  });
  const {
    wallet,
    ipfsHash,
    dehiddenModules,
    setDehiddenModules,
    txhash,
    mintedTokenId,
    erc721Type,
    nftType,
  } = useGCtx();
  const [isLoading, setIsLoading] = useState(false);
  const handleOptionSelection = (name) => {
    setselectedOption(name);
    setPhygital({ ...phygital, item: name });
  };

  const getContractToUse = () => {
    if (dehiddenModules?.isAutographedItem) {
      return contractAddresses[3];
    } else if (nftType.toLowerCase() === "erc721") {
      if (erc721Type.toLowerCase() === "721only") {
        return contractAddresses[0];
      } else {
        return contractAddresses[1];
      }
    } else if (nftType.toLowerCase() === "erc1155") {
      return contractAddresses[2];
    }
  };

  const orderItem = async () => {
    setIsLoading(true);
    const data = {
      wallet: wallet,
      metadata: ipfsHash,
      email: phygital.user_email,
      txhash,
      type: "frame",
      color: "acralic",
      product: "frame",
      size: selectedOption === "frame-fx" ? "S" : "XL",
      contractaddress: getContractToUse(),
      tokenID: mintedTokenId,
    };

    try {
      const orderCall = await fetch(
        `${process.env.REACT_APP_BASE_URL}/dehidden/phygital/order`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const orderResponse = await orderCall.json();

      if (!orderResponse.error) {
        setIsLoading(false);

        setDehiddenModules({ ...dehiddenModules, phygital: true });
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <section className=" flex h-[100%] md:h-screen w-full">
      <BuildBlock>
        <div className="w-full mx-auto h-auto  md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
          {/* <div className="mockup__container"> */}
          {/* <BuildNftImage /> */}
          <PhygitalImage selectedOption={selectedOption} />
          <BuildNftDetails />
          {/* </div> */}
        </div>
        <div className="w-full md:h-[100%] md:w-[60%] p-4 flex flex-col justify-between md:overflow-y-auto">
          <div>
            <h2 className="text-4xl font-black md:text-6xl uppercase pb-3 md:pb-5">
              🍀 Order a Phygital
            </h2>
            <p className="pb-4 font-semibold text-2xl md:text-3xl">
              Enter your email ID to get order details
            </p>
            {isLoading ? (
              <div className="options grid items-center font-semibold">
                <div>
                  {/* <img src={} alt="" /> */}
                  <div className="text-center my-4">
                    <ClipLoader size={60} color="#3BBEAE" />
                  </div>

                  <p className="text-center font-semibold">
                    Ordering... <br /> Please don't close this window.
                  </p>
                </div>
              </div>
            ) : !dehiddenModules?.phygital ? (
              <div className="options font-semibold text-[#333333]">
                <p>Select your Frame size*</p>
                <div className="flex my-3 gap-[16px] md:gap-[30px]">
                  <PhygitalOption
                    name={"Frame-FX"}
                    handleOptionSelection={handleOptionSelection}
                    icon={frameIcon}
                    selectedOption={selectedOption}
                  />
                  <PhygitalOption
                    name={"Frame-FXL"}
                    handleOptionSelection={handleOptionSelection}
                    icon={frameIcon}
                    selectedOption={selectedOption}
                  />
                </div>
                <div className={"w-full form-group mt-4"}>
                  <label htmlFor="name" className="font-semibold pb-1">
                    Email ID*
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={phygital.user_email}
                    onChange={(e) =>
                      setPhygital({ ...phygital, user_email: e.target.value })
                    }
                    id="user_email"
                    placeholder="Email"
                    className="w-full border-2 border-solid border-[#cccccc] p-2 rounded-md my-2"
                    required
                  />
                </div>
              </div>
            ) : (
              <div className="options font-semibold text-[#333333] w-full h-[300px] text-center border rounded-xl grid items-center">
                <div className="text-center">
                  <img
                    src={checkCirclePurple}
                    className="h-24 mb-3 mx-auto"
                    alt=""
                  />
                  <p>Please check your Email to complete the order</p>
                </div>
              </div>
            )}
          </div>

          {!dehiddenModules.phygital ? (
            <div className="steps__wrapper mt-3">
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
                disabled={!phygital.user_email || isLoading}
                onClick={orderItem}
              >
                Order
              </button>
              <p className="text-center pt-3" onClick={() => navigate(-1)}>
                Back
              </p>
            </div>
          ) : (
            <div className="steps__wrapper mt-3">
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
                onClick={() => navigate("/dehidden/add-utilities")}
              >
                Go Back
              </button>
            </div>
          )}
        </div>
      </BuildBlock>
    </section>
  );
};

export default PhygitalModule;

const PhygitalOption = ({
  icon,
  handleOptionSelection,
  name,
  selectedOption,
}) => {
  return (
    <div
      className={
        selectedOption === name.toLowerCase()
          ? "border h-[100px] md:h-[150px] w-[100px] md:w-[150px] rounded-md text-[#ffffff] border-[#3BBEAE] cursor-pointer"
          : "border h-[100px] md:h-[150px] w-[100px] md:w-[150px] rounded-md cursor-pointer"
      }
      onClick={() => handleOptionSelection(name.toLowerCase())}
    >
      <div className="item__image h-[70px] md:h-[110px] grid items-center">
        <img src={icon} alt="" className="mx-auto h-[60px] md:h-[90px] " />
      </div>
      <div
        className={
          selectedOption === name.toLowerCase()
            ? "item__foot h-[28px] md:h-[40px] grid items-center text-center bg-[#3BBEAE] font-semibold rounded-b-md "
            : "item__foot h-[28px] md:h-[40px] grid items-center text-center bg-[#EDEDED] font-semibold rounded-b-md"
        }
      >
        <p>{name}</p>
      </div>
    </div>
  );
};
