import { useEffect, useState } from "react";
import { useNavigate, useParams  } from "react-router-dom";
import Assistant from "../../Components/Assitant/Assistant";

import ContentBlock from "../../Components/ContentBlock/ContentBlock";

import { useGCtx } from "../../Contexts/GlobalContext";
import { v4 as uuidv4 } from 'uuid';
import customContract from "../../assets/ABI/nftFactoryMainnet.json";
import { ERC721ABI,ERC1155ABI } from "../../assets/ABI/createCollection";
import Web3 from "web3";
import { ClipLoader } from "react-spinners";
import alertCircle from "../../assets/images/alertCircle.svg";
import { useAccount } from "wagmi";
const CreateContract = () => {
  const [collectionName, setcollectionName] = useState("");
  const [symbolName, setsymbolName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const sessionID  =  uuidv4();
  
  const { connector } = useAccount();
  const navigate = useNavigate();
  const { wallet, nftType,setNftType,setCollection} = useGCtx();
  const [auth,setAuth] = useState(false);

  useEffect(() => {
    // if (error.length) {
    //   setTimeout(() => {
    //     setError("");
    //   }, 5000);
    // }
    canCreateCollection();
    return () => {
      canCreateCollection();
    }
  }, []);

  const handleContinue = async () => {
    try {
      setIsLoading(true);
    //   var checkCollection = await createCollection();
    //   if (checkCollection?.isCreated === true) {
    //     navigate(`/batch/session/${sessionID}`);
    //     return;
    //   }
      const provider = await connector?.getProvider();
      const web3 = new Web3(provider);

      const contract = new web3.eth.Contract(
        nftType?.toUpperCase() === "ERC721" ? ERC721ABI : ERC1155ABI,
        nftType?.toUpperCase() === "ERC721" ? customContract?.ERC721Factory?.address : customContract?.ERC1155Factory?.address,
      );

      const totalGas = await contract.methods
        .createCollection(wallet,
            sessionID, collectionName, symbolName)
        .estimateGas({ from: wallet });
      const gasPrice = await web3.eth.getGasPrice();

      const tx = await contract.methods
        .createCollection(wallet, sessionID, collectionName, symbolName)
        .send({ from: wallet, gas: totalGas.toString(), gasPrice: gasPrice });

      if (tx && tx.events) {
        var uploadRes = await createCollectionPayload();
        if (uploadRes.error) {
          console.log(uploadRes.error);
          setError("Unable to create collection. Please retry!");
          setcollectionName("");
          setsymbolName("");
          setIsLoading(false);
        } else {
          setCollection(true)
          navigate("/upload");
          setIsLoading(false);
        }
        // setIsLoading(false);
        // alert("Collection created successfully");
      }
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
      // console.log(e.message);
    }
  };

  const canCreateCollection = async() => {
    try{
      const data = await fetch(`${process.env.REACT_APP_BASE_URL}/v2/collection/canCreate/${wallet}`,{
        method:"GET",
        headers:{
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-auth-token": process.env.REACT_APP_API_KEY,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const res = await data.json();
      if(res?.status === false){
        setError("Reached max contract limit")
      }
    }
    catch(error){
      console.log(error);
      setError("Unable to create collection. Please retry!");
    }
  }

  const createCollectionPayload = async () => {
    const data = {
      wallet,
      contractId:sessionID,
      nftType:nftType?.toUpperCase(),
      name:collectionName,
      symbol:symbolName,
    };
    const uploadCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v2/collection/create`,
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-auth-token": process.env.REACT_APP_API_KEY,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const uploadRes = await uploadCall.json();
    return uploadRes;
  };

  useEffect(() => {
   
    const urlParams = new URLSearchParams(window.location.search);
    const auth = urlParams.get('username');
    console.log(nftType);
    if(auth){
      setAuth(true);
    }
  }, []);

  return (
    <section className="select  flex h-screen w-full items-end">
     {
      error?.length ? 
      <ContentBlock>
      <div className="top__content">
      <div className="">
      <h1 className="md:text-5xl text-4xl font-black uppercase">
            Create
            <br className="hidden md:block" />
             Collection
            
          </h1>
          <div className="options__container pt-2">
                <div className="flex font-semibold text-[#333333] items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                  <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                  <p>{error}</p>
                </div>
      </div>
      </div>
      </div>
      <div className="bottom__content">
          <div className="flex items-center gap-2">
            <p
              onClick={() => navigate("/collection-type")}
            className="text-[#333333] font-semibold">
              Go Back
            </p>
          </div>
        </div>
      </ContentBlock>
      :
      !auth ? 
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-5xl text-4xl font-black uppercase">
            Connect
            <br />
            Your {""}
            <br className="hidden md:block" />
            Socials
          </h1>
          <p className="py-2 text-xl">Choose social account to authenticate</p>
          <div className="options__container pt-2">
            <button
            onClick={() => window.location.href = `${process.env.REACT_APP_BASE_URL}/api/key/auth/twitter?wallet=${wallet}&redirect_uri=${window.location.href}`}
            className="flex items-center justify-center gap-3 bg-[#3BBEAE] w-full px-5 py-4 my-3 rounded-full text-white font-semibold">
               Connect Twitter
            </button>

            <button
            onClick={() => window.location.href = `${process.env.REACT_APP_BASE_URL}/api/key/auth/discord?wallet=${wallet}&redirect_uri=${window.location.href}`}
            className="flex items-center justify-center gap-3 bg-[#000000] w-full px-5 py-4 my-3 rounded-full text-white font-semibold">
               
               Connect Discord
            </button>
          </div>
          </div>
          <div className="bottom__content">
          <div className="flex items-center gap-2">
            <p
              onClick={() => navigate("/collection-type")}
            className="text-[#333333] font-semibold">
             Go Back
            </p>
          </div>
        </div>
      </ContentBlock>

    : <>
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-5xl text-4xl font-black uppercase">
            Create
            <br/>
            Collection
          </h1>

          {!isLoading ? (
            <div className="options__container pt-2">
              {error ? (
                <div className="flex font-semibold text-[#333333] items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                  <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                  <p>{error}</p>
                </div>
              ) : null}
              <div>
                <p className=" pt-2 font-semibold text-[#333333] ">Name*</p>
                <input
                  type="text"
                  name="collectionName"
                  id="collectionName"
                  value={collectionName}
                  onChange={(e) => setcollectionName(e.target.value)}
                  placeholder="Enter a personal collection name"
                  className="w-full border-2 border-solid border-[#cccccc] p-2 px-2 rounded-md my-2 text-sm"
                />
              </div>
              <div>
                <p className=" pt-2 font-semibold text-[#333333] ">Symbol*</p>
                <input
                  type="text"
                  name="symbolName"
                  id="symbolName"
                  value={symbolName}
                  onChange={(e) => setsymbolName(e.target.value)}
                  placeholder="Enter your sybmbol name"
                  className="w-full border-2 border-solid border-[#cccccc] p-2 px-2 rounded-md my-2 text-sm"
                />
              </div>
              {/* <div className="flex gap-4 max-w-full py-2 rounded-lg md:my-1 my-2 gt-w">
                <div className="flex items-center gap-2">
                  <input
                  onChange={(e) => {setType(e.target.value)}}
                  type="radio" name="activeType" id="erc721Type" className="accent-[#3bbeae] h-4 w-4 default:ring-1 checked:ring-[#3bbeae]" value="ERC721" checked={type === "ERC721" ? true : false} /> 
                  <label className="gt-w" for="erc721Type">ERC721</label>
                  </div>
                
                <div className="flex items-center gap-2">
                  <input
                  onChange={(e) => {setType(e.target.value)}}
                  type="radio" name="activeType" id="erc721Type" className="accent-[#3bbeae] h-4 w-4 default:ring-1 checked:ring-[#3bbeae]" value="ERC1155" checked={type === "ERC1155" ? true : false}/> 
                  <label className="gt-w" for="erc1155type">ERC1155</label>
                  </div>
                </div> */}
                  
            </div>
          ) : (
            <div>
              {/* <img src={} alt="" /> */}
              <div className="text-center my-4">
                <ClipLoader size={60} color="#3BBEAE" />
              </div>
              <p className="text-center font-semibold">
                Creating Collection... <br /> Please don't close this window.{" "}
              </p>
            </div>
          )}
        </div>
        {!isLoading && (
          <div className="button__container text-center">
             
            <button
              onClick={handleContinue}
              className="bg-[#3BBEAE] w-full px-5 py-4 my-3 rounded-full text-white font-semibold text-center"
              disabled={!collectionName || !symbolName || !sessionID}
            >
              Create Collection
            </button>
            <p onClick={() => navigate("/collection-type")} className="cursor-pointer">
              Back
            </p>
          </div>
        )}
      

      </ContentBlock>
      </>
     }
     
      <Assistant />
    </section>
  );
};

export default CreateContract;
