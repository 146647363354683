import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesContainer from "./RoutesContainer";
import GlobalContextProvider from "./Contexts/GlobalContext";
import "@rainbow-me/rainbowkit/styles.css";

import {
  connectorsForWallets,
  lightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  coinbaseWallet,
  // injectedWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { sequenceWallet } from "@0xsequence/rainbowkit-plugin";

function App() {
  const { chains, provider } = configureChains(
    [chain.polygon],
    [publicProvider()]
  );

  const connectors = connectorsForWallets([
    {
      groupName: "Recommended",
      wallets: [
        // sequenceWallet({
        //   chains,
        //   shimDisconnect: true,
        //   connect: {
        //     app: "Mint",
        //     networkId: 137,
        //   },
        // }),
        metaMaskWallet({ chains }),
        rainbowWallet({ chains }),
        walletConnectWallet({ chains }),
        coinbaseWallet({ chains }),
      ],
    },
  ]);

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        modalSize="wide"
        initialChain={chains.polygon}
        theme={lightTheme({
          accentColor: "#3BBEAE",
          accentColorForeground: "white",
          borderRadius: "large",
        })}
      >
        <div className="App">
          <div className="video__wrapper">
            <video
              src="/videos/Screen.mp4"
              autoPlay
              id="bgvideo"
              loop
              muted
              playsInline
            ></video>
          </div>
          <Router>
            <GlobalContextProvider>
              <RoutesContainer />
            </GlobalContextProvider>
          </Router>
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
