import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import backRound from "../../assets/images/roundBack.svg";
import "./FAQ.css";

const FAQs = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const items = document.querySelectorAll(".accordion button");

    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded");

      for (var i = 0; i < items.length; i++) {
        items[i].setAttribute("aria-expanded", "false");
      }

      if (itemToggle === "false") {
        this.setAttribute("aria-expanded", "true");
      }
    }

    items.forEach((item) => item.addEventListener("click", toggleAccordion));
  }, []);

  return (
    <section className=" flex h-[100%] min-h-[100vh] md:h-screen w-full flex-col ">
      <div className="pt-4 flex justify-between w-[95%] md:w-[90%] mx-auto items-center pb-10">
        <div
          className="back flex items-center py-3 pt-5 pb-0 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          {/* <img src={backRound} className="h-6 mr-2" alt="" />{" "} */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" className="mr-2" viewBox="0 0 24 25" fill="none">
            <path d="M20.1984 14.2712C20.1984 9.83601 16.5888 6.2264 12.1536 6.2264H8.94717V3.5L3.80157 7.66641L8.94717 11.8328L8.94731 8.70317H12.1537C15.2258 8.70317 17.7217 11.1992 17.7217 14.2712C17.7217 17.3433 15.2257 19.8392 12.1537 19.8392L5.60651 19.8393C4.91531 19.8393 4.37771 20.3961 4.37771 21.0681C4.37771 21.7593 4.93444 22.2969 5.60651 22.2969H12.1537C16.5889 22.3161 20.1985 18.7065 20.1985 14.2713" fill="#fff" />
          </svg>
          <p className="font-semibold text-[#fff] hidden md:block">Go Back</p>
        </div>
      </div>

      <div className="flex-col md:flex-row w-[95%] md:w-[90%] mx-auto rounded-2xl p-3 md:p-6  mb-10 mt-10 bg-white h-auto m-auto md:mb-auto md:mt-auto md:max-w-[1440px]  md:h-[80vh] md:max-h-[800px] box-shadow-block">
        <div className="flex gap-3 flex-col sm:items-center sm:justify-between sm:flex-row">
          <h1 className="md:text-6xl text-4xl font-black uppercase py-3">
            FAQs
          </h1>
        </div>
        <div className="usernft__outer rounded-lg">
          <div className="usernft__container accordion">
            <FAQItem
              question="What is an NFT?"
              answer="A Non Fungible Token or NFT is a digital asset that exists on a blockchain. It is unique and indivisible. By turning a digital file - a photograph, certificate, art, audio, or video file into an NFT, you can keep the record of ownership of that asset, with you. Forever."
            />
            <FAQItem
              question="What does it mean to mint an NFT?"
              answer="When you go to the bank to get a new credit card, you have to wait for it to get printed before you can use it. The same thing happens with an NFT, except that it is minted on the blockchain. Minting is a way of turning any digital file or item into an NFT stored on the blockchain."
            />
            <FAQItem
              question="Why do I need a wallet to mint an NFT?"
              answer="An NFT wallet is a cryptocurrency wallet that supports the blockchain protocol that the NFT is minted on. Think of your NFT wallet as a locker where you store your NFTs after minting."
            />
            <FAQItem
              question="I don't have a wallet. How do I get one?"
              answer="To set up a Metamask wallet, go to MetaMask.io and click the “Download” button, which will take you to the relevant store to download the extension or app based on the device and browser you’re using."
            />
            <FAQItem
              question="Okay, so I have a wallet now. How do I proceed?"
              answer="Awesome!
              Just connect your wallet by clicking on Wallet Connect; follow the instructions, and you are good to go!"
            />
            <FAQItem
              question="What is an ERC 721 NFT?"
              answer="ERC 721 is an NFT standard. NFTs created with this standard are unique and can only be stored in one wallet at a time. If you want to mint a unique file like an audio, image, or video as an NFT, then you should use the ERC 721 standard."
            />
            <FAQItem
              question="What is an ERC 1155 NFT?"
              answer="ERC 1155 NFT is another NFT standard. It is useful when you want to mint more than one NFTs at a time."
            />
            <FAQItem
              question="Do I have to pay gas fees to mint NFTs?"
              answer="If you use the Single Mint, Quick Mint and Send as Gift features it is completely free (the gas is on us!)
              But, for batch minting, you have to pay a nominal gas fee."
            />
            <FAQItem
              question="Okay, so I have minted an NFT. What next?"
              answer="Once you have minted an NFT, you can sell it in a marketplace like OpenSea, gift it to someone by transferring it to their wallet, or keep it in your wallet like a memory stored forever."
            />
            <FAQItem
              question="Can I display the NFT on a public platform?"
              answer="After minting, you can put your NFT for sale on an NFT marketplace like OpenSea and Rarible."
            />
            <FAQItem
              question="Does minting an NFT mean I hold copyrights over the content?"
              answer="Minting an NFT is not the same as holding its copyright. So, always mint original artwork created by you, or digital assets that you own."
            />
            <FAQItem
              question="What is a soulbound NFT?"
              answer="Soulbound is an NFT that is non-transferable. This means that you can neither transfer nor sell a soulbound. The value a soulbond holds cannot be bought in any marketplace for it is unique to the holder."
            />
            <FAQItem
              question="What are NFT utilities?"
              answer="Utilities are added perks. With NFTs, you can personalize them to your taste. Whatever floats your boat — add a social autograph, hold a physical copy, gift it to a special someone, and even make some content hidden while on the blockchain!"
            />
            <FAQItem
              question="What is a phygital?"
              answer="A phygital is an NFT with a physical counterpart, and you can own both! For instance, you can choose to have your phygital printed on canvas or made into a wall decor for your home. Simply select size, provide your email address, and order the physical frame."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;

const FAQItem = ({ question, answer }) => {
  return (
    <div class="accordion-item max-w-[94%] w-full mx-auto">
      <button id="accordion-button-1" aria-expanded="false">
        <span class="accordion-title">{question}</span>
        <span class="icon" aria-hidden="true"></span>
      </button>
      <div class="accordion-content">
        <p>{answer}</p>
      </div>
    </div>
  );
};
