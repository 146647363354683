const ContentBlock = ({ children }) => {
  return (
    <div className=" w-[90%] rounded-3xl p-6   mb-20 bg-white/10 h-auto m-auto md:mb-auto md:w-[440px] md:ml-10 md:h-[90%] md:max-h-[800px] box-shadow-block overflow-y-auto content__block">
      <div className="flex-none flex flex-col justify-between h-full w-full fade-in">
        {children}
      </div>
    </div>
  );
};

export default ContentBlock;
