import { useGCtx } from "../../Contexts/GlobalContext";
import bluetickIcon from "../../assets/images/bluetickIcon.svg";

const AutographedImage = () => {
  const { assetPreview, autographedText } = useGCtx();

  return (
    <div className="image__container__signed mx-auto text-center w-full bg-white rounded-xl border-4 border-solid border-black p-3">
      <img
        src={assetPreview?.file}
        alt=""
        className="h-[auto] w-full object-contain object-center mx-auto rounded-xl border-4 border-solid border-white"
      />
      <div className="signature mt-3 bg-[#ffffffe1] border-2 border-solid border-white py-3 px-3 rounded-xl flex items-center">
        <img src={bluetickIcon} className="h-6 mr-2" alt="verified" />{" "}
        <p className="font-semibold text-[18px]">{autographedText}</p>
      </div>
    </div>
  );
};

export default AutographedImage;
