import { useNavigate } from "react-router-dom";
const BatchItem = ({ item }) => {
  const navigate = useNavigate();
  const getAction = (status) => {
    if (status.s3 !== "CONFIRMED") {
      if (status.s3 === "PENDING") {
        return (
          <p className="bg-yellow-400 p-2 py-1 font-semibold rounded-md text-xs">
            Uploading to Server
          </p>
        );
      } else {
        return (
          <p className="bg-gray-400 p-2 py-1 font-semibold rounded-md text-xs">
            Retry Upload
          </p>
        );
      }
    } else if (status.ipfs !== "CONFIRMED") {
      if (status.ipfs === "PENDING") {
        return (
          <p className="bg-yellow-400 p-2 py-1 font-semibold rounded-md text-xs">
            Uploading to IPFS
          </p>
        );
      } else {
        return (
          <p className="bg-gray-400 p-2 py-1 font-semibold rounded-md text-xs">
            Upload to IPFS
          </p>
        );
      }
    } else if (status.gas !== "DEPOSITED") {
      if (status.gas === "PENDING") {
        return (
          <p className="bg-yellow-400 p-2 py-1 font-semibold rounded-md text-xs">
            Deposit in progress
          </p>
        );
      } else if (status.gas === "INSUFFICIENT") {
        return (
          <p className="bg-gray-400 p-2 py-1 font-semibold rounded-md text-xs">
            Insufficient Matic
          </p>
        );
      } else {
        return (
          <p className="bg-gray-400 p-2 py-1 font-semibold rounded-md text-xs">
            Deposit Matic
          </p>
        );
      }
    } else if (status.mint !== "CONFIRMED") {
      if (status.mint === "PENDING") {
        return (
          <p className="bg-yellow-400 p-2 py-1 font-semibold rounded-md text-xs">
            Minting in progress
          </p>
        );
      } else if (status.mint === "FAILED") {
        return (
          <p className="bg-red-400 p-2 py-1 font-semibold rounded-md text-xs">
            Mint Failed!
          </p>
        );
      } else {
        return (
          <p className="bg-gray-400 p-2 py-1 font-semibold rounded-md text-xs">
            Mint
          </p>
        );
      }
    } else if (status.refund !== "CONFIRMED") {
      if (status.refund === "PENDING") {
        return (
          <p className="bg-yellow-400 p-2 py-1 font-semibold rounded-md text-xs">
            Refund in progress
          </p>
        );
      } else if (status.refund === "FAILED") {
        return (
          <p className="bg-yellow-400 p-2 py-1 font-semibold rounded-md text-xs">
            Refund in progress
          </p>
        );
      } else {
        return (
          <p className="bg-green-400 p-2 py-1 font-semibold rounded-md text-xs">
            Refund Success
          </p>
        );
      }
    } else {
      return (
        <p className="bg-green-400 p-2 py-1 font-semibold rounded-md text-xs">
          Mint Success
        </p>
      );
    }
  };

  return (
    <div className="usernft__item border border-[#00000082] p-3 rounded-xl ">
      {/* <div className="flex items-center w-full p-2 border rounded-xl border-[#0000007d] border-t-0 border-x-0"> */}
      <div className=" h-full w-auto aspect-square relative border bg-[#e0e0e0] rounded-lg mr-3 ">
        <img
          src={`https://avatars.dicebear.com/api/bottts/${item.name}.svg`}
          alt={"Batch Test 1"}
          className="rounded-xl h-full w-full object-contain"
        />
      </div>
      <div className="">
        {getAction(item.status)}

        <p className="font-black text-2xl mt-2">
          {item.name.length > 7 ? item.name.substring(0, 7) + "..." : item.name}
        </p>
        <div
          className="bg-[#fefefe] transition-colors transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer w-full"
          onClick={() => navigate(`/batch/session/${item.sessionID}`)}
        >
          View Details
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default BatchItem;
