import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import BuildBlock from "../../Components/BuildBlock/BuildBlock";
import alertCircle from "../../assets/images/alertCircle.svg";
var contractAddresses = [
  {
    contract: "MINT NFT-721",
    contractAddress: "0x03e055692e77e56abf7f5570d9c64c194ba15616",
  },
  {
    contract: "MINT NFT-Soulbound",
    contractAddress: "0x42c091743f7b73b2f0043b1fb822b63aaa05041b",
  }, //soulbound
  {
    contract: "MINT NFT-1155",
    contractAddress: "0xe2f50189f8c1e3804aeb854c9ebffb92ba9d3270",
  }, //1155
  {
    contract: "MINT NFT-Autograph",
    contractAddress: "0xe2adba225105451dd07605c78aa869e797c87467",
  }, //Autograph
];
const NFTDetails = () => {
  const { contractAddress, tokenId } = useParams();
  const [currentNft, setCurrentNft] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!contractAddress || !tokenId) return;
    getCurrentNft();
    if (!currentNft.transactionHash) {
      console.log("No Data");
    }
  }, [contractAddress, tokenId]);

  const getCurrentNft = async () => {
    setIsLoading(true);

    const getCurrentCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/nft/${contractAddress}/${tokenId}`,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );

    const getCurrentRes = await getCurrentCall.json();
    if (getCurrentRes) {
      setCurrentNft(getCurrentRes);
      setIsLoading(false);
    }
  };

  const getCollectionName = () => {
    const item = contractAddresses.filter(
      (item) =>
        item.contractAddress.toLowerCase() === contractAddress.toLowerCase()
    )[0];

    return item.contract;
  };
  const getFileExtention = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };
  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key).then(
      function () {
        console.log("Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };
  const getNFTPreview = () => {
    switch (getFileExtention(currentNft.metadata.animation)) {
      case "png":
      case "jpg":
      case "gif":
      case "webp":
      case "jpeg":
      case "svg":
        return (
          <img
            src={`https://ipfs.io/ipfs/${currentNft.metadata.animation.substring(
              7,
              currentNft.metadata.animation.length
            )}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/notloaded.png";
            }}
            alt={currentNft.title}
            className="h-[300px] w-[300px] md:h-[200px] md:w-[200px] lg:h-[360px] lg:w-[360px]  object-contain object-center mx-auto rounded-xl border-4 border-solid border-white"
          />
        );
      case "glb":
      case "gltf":
        return (
          <model-viewer
            alt={currentNft.title}
            src={`https://ipfs.io/ipfs/${currentNft.metadata.animation.substring(
              7,
              currentNft.metadata.animation.length
            )}`}
            ar
            ar-modes="webxr scene-viewer quick-look"
            seamless-poster
            shadow-intensity="1"
            camera-controls
            enable-pan
          ></model-viewer>
        );
      case "mp4":
      case "webm":
      case "mov":
        return (
          <video
            controls
            src={`https://ipfs.io/ipfs/${currentNft.metadata.animation.substring(
              7,
              currentNft.metadata.animation.length
            )}`}
            alt={currentNft.title}
            className="h-[300px] w-[300px] md:h-[200px] md:w-[200px] lg:h-[360px] lg:w-[360px]  object-contain object-center mx-auto rounded-xl border-4 border-solid border-white"
          ></video>
        );
      case "mp3":
      case "wav":
      case "ogg":
        return (
          <audio
            src={`https://ipfs.io/ipfs/${currentNft.metadata.animation.substring(
              7,
              currentNft.metadata.animation.length
            )}`}
            className="w-full mx-auto"
            controls
          ></audio>
        );
      default:
        return (
          <div className="h-[300px] w-[300px] md:h-[200px] md:w-[200px] lg:h-[360px] lg:w-[360px]  object-contain object-center mx-auto rounded-xl border-4 border-solid border-white">
            Unable to load Asset
          </div>
        );
    }
  };

  return (
    <section className=" flex h-[100%] md:h-screen w-full">
      {currentNft.transactionHash ? (
        <BuildBlock>
          <div className="w-full mx-auto h-auto  md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
            {currentNft.title && getNFTPreview()}
          </div>
          <div className="w-full md:h-[100%] md:w-[60%] p-4 flex flex-col justify-between md:overflow-y-auto ">
            <div>
              <p className="font-semibold text-sm">{getCollectionName()}</p>
              <h2 className="text-4xl font-black lg:text-6xl uppercase pb-2 ">
                {currentNft.title}
              </h2>
              <p>{currentNft.description}</p>

              <div className="bg-[#fefefe] p-2 px-3 rounded-lg my-3">
                <div className="flex justify-between items-center my-1">
                  <p className="font-semibold">Contract Address</p>{" "}
                  <button
                    onClick={() => handleCopyKey(contractAddress)}
                    className="copytext text-[16px]"
                  >
                    {contractAddress.substring(0, 8) +
                      "..." +
                      contractAddress.substring(
                        contractAddress.length - 5,
                        contractAddress.length
                      )}
                  </button>
                </div>
                <div className="flex justify-between items-center my-1">
                  <p className="font-semibold">Transaction Hash</p>{" "}
                  <button
                    onClick={() => handleCopyKey(currentNft.transactionHash)}
                    className="copytext text-[16px]"
                  >
                    {currentNft.transactionHash.substring(0, 8) +
                      "..." +
                      currentNft.transactionHash.substring(
                        currentNft.transactionHash.length - 5,
                        currentNft.transactionHash.length
                      )}
                  </button>
                </div>
                <div className="flex justify-between items-center my-1">
                  <p className="font-semibold">Token Standard</p>{" "}
                  <p>{currentNft.id.tokenMetadata.tokenType}</p>
                </div>
                <div className="flex justify-between items-center my-1">
                  <p className="font-semibold">Token ID</p> <p>{tokenId}</p>
                </div>
              </div>
              <div className="py-3 buttons__wrapper flex gap-2 w-full flex-wrap">
                <button
                  className="w-full sm:w-auto border-black border p-2 rounded-lg  text-sm cursor-pointer my-2 px-10"
                  onClick={() =>
                    window.open(
                      `https://opensea.io/assets/matic/${contractAddress}/${tokenId}`
                    )
                  }
                >
                  ⛵️ View on OpenSea
                </button>
                <button
                  className="w-full sm:w-auto border-black border p-2 rounded-lg  text-sm cursor-pointer my-2 px-10"
                  onClick={() =>
                    window.open(
                      `https://rarible.com/token/polygon/${contractAddress}:${tokenId}?tab=details`
                    )
                  }
                >
                  ® View on Rarible
                </button>
                <button
                  className="w-full sm:w-auto border-black border p-2 rounded-lg text-sm cursor-pointer my-2 px-10"
                  onClick={() =>
                    window.open(
                      `https://polygonscan.com/tx/${currentNft.transactionHash}`
                    )
                  }
                >
                  ⛓ View on PolygonScan
                </button>
                <button
                  className="w-full sm:w-auto border-black border p-2 rounded-lg text-sm cursor-pointer my-2 px-10"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  Back Home
                </button>
              </div>
            </div>

            {/* <div className="p-2 py-3 font-semibold rounded-lg bg-yellow-200 flex ">
              <img src={alertCircle} alt="" className="h-6 mr-3" />
              We are currently facing issues with OpenSea and Rarible. We're
              actively trying to resolve it.
            </div> */}
          </div>
        </BuildBlock>
      ) : isLoading ? (
        <BuildBlock>
          <div className="w-full h-full grid items-center">
            <div>
              <div className="text-center my-4">
                <ClipLoader size={60} color="#3BBEAE" />
              </div>
              <p className="text-center font-semibold">Loading...</p>
            </div>
          </div>
        </BuildBlock>
      ) : (
        <BuildBlock>
          <div className="border-2 rounded-2xl  p-4 flex h-full justify-center items-center w-full">
            <div className="text-center">
              <p className="font-black text-3xl md:text-5xl pb-3">WHOOPS!</p>
              <p className="font-medium text-[20px] md-text-[24px]">
                You've requested to view an NFT that does not exist. Please try
                again.
              </p>
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 mt-4 px-16"
                onClick={() => navigate("/")}
              >
                Home
              </button>
            </div>
          </div>
        </BuildBlock>
      )}
    </section>
  );
};

export default NFTDetails;