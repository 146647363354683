import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import claimed from "../../assets/images/claimed.svg";
import fingerprint from "../../assets/images/fingerprint.svg";
import supply from "../../assets/images/supply.svg";
import timer from "../../assets/images/timer.svg";
import Web3 from "web3";
import alertCircle from "../../assets/images/alertCircle.svg";
import { globalClaim } from "../../connectors";
import globalABI from "../../assets/ABI/nftGlobalMainnet.json";
import { useGCtx } from "../../Contexts/GlobalContext";
import ClaimBlock from "../../Components/ClaimBlock/ClaimBlock";
import BuildBlock from "../../Components/BuildBlock/BuildBlock";
import { Biconomy } from "@biconomy/mexa";
import { useAccount, useSigner } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import spindl from "@spindl-xyz/attribution";
import { ERC1155MUMBAI } from "../../assets/ABI/ERC1155MUMBAI";
const BASE_URL = process.env.REACT_APP_BASE_URL;

//Spindl Integration
spindl.configure({
  API_KEY: process.env.REACT_APP_SPINDL_KEY,
});

const gasslessWhiteList = [
  "0x5850cA4F7456989BBd88cf6dF9Ca6437BBF7018b",
  "0x53194EECD65413d2EB1Ead46e7021BD02daC2cB3",
  // "0x64eE84780d868cB0649770b798482508682a0117",
  "0x4a935d54133705ac265eba21a2cA20D520E8c438",
];

const Claimpage = () => {
  const navigate = useNavigate();
  const { wallet, setWallet } = useGCtx();
  const [currentPage, setCurrentPage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentNft, setCurrentNft] = useState({});
  const [isClaimed, setIsClaimed] = useState(false);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const { claimId } = useParams();
  const { address, isConnected, connector } = useAccount();
  const { data: signer } = useSigner();
  const [error, setError] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [toAddress,setToAddress] = useState("");
  var biconomy, web3Pro;
  let linksHeadlines = [
    "zkEVM-is-the-Mainnet-For-You",
    "Polygon-zkEVM-Ethereum",
    "miningforlove",
    "GM-GM-GM",
    "nonfungiblelove",
  ]

  // check window location includes any of the linksHeadlines (uppercase or lowercase)
 let valentineDrop = linksHeadlines.some((headline) => {
    return window.location.href?.toLowerCase().includes(headline?.toLowerCase());
  }) ? true : false;
    
  console.log(valentineDrop);

  let limit = 1000;

  useEffect(() => {
    spindl.enableAutoPageViews();
    window.gtag("event", "valentines-drop", {
      event_category: "valentines-drop",
      event_label: window.location.pathname,
    });
  }, []);

  useEffect(() => {
    if (!claimId) {
      return;
    }
    getClaimByURL();
  }, []);
  useEffect(() => {
    if (isConnected) {
      setWallet(address);
      window.gtag("event", "wallet-connect", {
        event_category: "claim-page-wallet-connect",
        event_label: window.location.pathname,
      });
    }
  }, [isConnected]);

  useEffect(() => {
    //spindl integration for 1155 NFT
    if (!address) {
      return;
    }
    if (
      currentPage &&
      currentPage?.contractStandard?.toLowerCase() === "erc1155"
    ) {
      spindl.attribute(address);
    }
  }, [address]);

  const airDropItemGas = async () => {
    const contractToUse =  "0xe2f50189F8c1e3804AEb854C9eBFFB92Ba9d3270";
    try{
      setPopUp(false);
      setIsLoading(true);
      let provider = await connector?.getProvider();
      let web3Pro = new Web3(provider);
        const contract = new web3Pro.eth.Contract(
          ERC1155MUMBAI,
          contractToUse
        );

        const tx = await contract.methods
          .safeTransferFrom(wallet, toAddress, currentPage.tokenId,1,"0x")
          .send({
            from: wallet,
          });
        if (tx) {
          // setIsLoading(false);
          // setDehiddenModules({ ...dehiddenModules, airdrop: true });
          setIsLoading(false);
          setPopUp(false);
        } else {
          setIsLoading(false);
          setError(
            "Oops, the airdrop has failed! Please try again after a while."
          );
        }
    }
    catch(error){
      console.log(error);
        setIsLoading(false);
        setError(
          "Oops, the airdrop has failed! Please try again after a while."
        );
    }
  }

  const airDropItem = async () => {
    const contractToUse =  "0xe2f50189F8c1e3804AEb854C9eBFFB92Ba9d3270";
    try {
      setPopUp(false);
      setIsLoading(true);
      biconomy = new Biconomy(signer?.provider, {
        apiKey: process.env.REACT_APP_BICONOMY_KEY_MAINNET,
        debug: true,
        strictMode: true,
      });
      web3Pro = new Web3(biconomy);
      biconomy.onEvent(biconomy.READY, async () => {
        try {
          const contract = new web3Pro.eth.Contract(
            ERC1155MUMBAI,
            contractToUse
          );

          const tx = await contract.methods
            .safeTransferFrom(wallet, toAddress, currentPage.tokenId,1,"0x")
            .send({
              from: wallet,
            });
          if (tx) {
            // setIsLoading(false);
            // setDehiddenModules({ ...dehiddenModules, airdrop: true });
            setIsLoading(false);
            setPopUp(false);
          } else {
            setIsLoading(false);
            setError(
              "Oops, the airdrop has failed! Please try again after a while."
            );
          }
        } catch (err) {
          // console.log("err: ", err);
          console.log(err.message);
          setIsLoading(false);
          setError(
            "Oops, the airdrop has failed! Please try again after a while."
          );
        }
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const getCurrentNft = async (contractAddress, tokenId) => {
    setIsLoading(true);

    const getCurrentCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/nft/${contractAddress}/${tokenId}`,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );

    const getCurrentRes = await getCurrentCall.json();
    if (getCurrentRes.title) {
      setCurrentNft(getCurrentRes);
      setIsLoading(false);
    } else {
      console.log(getCurrentRes);
      setIsLoading(false);
    }
  };

  const getClaimByURL = async () => {
    try {
      setIsLoading(true);

      var web3, contract;

      var claimNFTABI = globalClaim.ClaimNFT.abi;
      var claimNFTContract = globalClaim.ClaimNFT.address;

      web3 = new Web3(
        new Web3.providers.HttpProvider("https://polygon-rpc.com/")
      );
      contract = new web3.eth.Contract(claimNFTABI, claimNFTContract);

      const getClaimPage = await fetch(
        `${process.env.REACT_APP_BASE_URL}/v1/claim/custom/${claimId}`,
        {
          method: "GET",
        }
      );

      const getClaimPageRes = await getClaimPage.json();
      if (getClaimPageRes._id) {
        setCurrentPage(getClaimPageRes);
        getCurrentNft(getClaimPageRes.contractAddress, getClaimPageRes.tokenId);
        if (getClaimPageRes.contractStandard.toUpperCase() === "ERC721") {
          const res = await contract.methods
            .isERC721Claimed(getClaimPageRes.sessionID)
            .call();
          if (res) {
            setTotalClaimed(1);
          }
        } else if (
          getClaimPageRes.contractStandard.toUpperCase() === "ERC1155"
        ) {
          const res = await contract.methods
            .totalERC1155Claimed(getClaimPageRes.sessionID)
            .call();
          setTotalClaimed(res);
        }
      } else {
        console.log(getClaimPageRes);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (parseInt(totalClaimed) >= parseInt(currentPage.supply)) {
      setError("All NFTs have been claimed!");
      setIsLoading(false);
      return;
    }
  }, [totalClaimed, currentPage.supply]);

  // const hasUserClaimed = async () => {
  //   try {
  //     console.log("checking if user has claimed");
  //     var web3 = new Web3(
  //       new Web3.providers.HttpProvider("https://polygon-rpc.com")
  //     );
  //     var currentAbi = ERC1155MUMBAI;
  //     var currentContract = currentPage.contractAddress;
  //     const contractCheck = new web3.eth.Contract(currentAbi,currentContract);

  //     const tx = await contractCheck.methods.balanceOf(
  //       address,
  //       currentPage.tokenId
  //     ).call();

  //     if (tx) {
  //       console.log(tx);
  //       setIsClaimed(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const claim = () => {
    if(gasslessWhiteList.includes(currentPage.ownerAddress) && valentineDrop && totalClaimed <= limit){
      claimGasless();
    }
    else if (gasslessWhiteList.includes(currentPage.ownerAddress) && !valentineDrop) {
      claimGasless();
    } else {
      claimWithGas();
    }
  };
  const claimGasless = async () => {
    try {
      setIsLoading(true);
      console.log("claiming gasless");

      biconomy = new Biconomy(signer?.provider, {
        apiKey: process.env.REACT_APP_BICONOMY_KEY_MAINNET,
        debug: true,
        strictMode: true,
      });
      web3Pro = new Web3(biconomy);
      var currentAbi =
        currentPage.contractStandard.toUpperCase() === "ERC721"
          ? globalABI.ERC721.abi
          : globalABI.ERC1155.abi;
      var currentC = currentPage.contractAddress;

      var c = new web3Pro.eth.Contract(currentAbi, currentC);
      var tempTokenId = currentPage.tokenId;
      var checktx;
      if (currentPage.contractStandard.toUpperCase() === "ERC721") {
        checktx = await c.methods.balanceOf(address).call();
      } else {
        checktx = await c.methods.balanceOf(address, tempTokenId).call();
      }
      if (checktx && parseInt(checktx) >= 1) {
        valentineDrop ? setPopUp(true) : setPopUp(false);
        setError("You have already claimed this NFT");
        setIsClaimed(true);
        setIsLoading(false);
        return false;
      }

      biconomy.onEvent(biconomy.READY, async () => {
        try {
          var claimNFTABI = globalClaim.ClaimNFT.abi;
          var claimNFTContract = globalClaim.ClaimNFT.address;
          let tx;
          const contract = new web3Pro.eth.Contract(
            claimNFTABI,
            claimNFTContract
          );
          var sess = currentPage.sessionID;
          if (currentPage.contractStandard.toUpperCase() === "ERC721") {
            tx = await contract.methods.claimSingleERC721(sess).send({
              from: wallet,
            });
          } else if (currentPage.contractStandard.toUpperCase() === "ERC1155") {
            if (currentPage.exclusive) {
              tx = await contract.methods.claimListERC1155(sess).send({
                from: wallet,
              });
            } else {
              tx = await contract.methods.claimERC1155(sess).send({
                from: wallet,
              });
            }
          }

          if (tx) {
            setIsLoading(false);
            setIsClaimed(true);
            if (currentPage.contractStandard.toUpperCase() === "ERC721") {
              const res = await contract.methods
                .isERC721Claimed(currentPage.sessionID)
                .call();
              if (res) {
                setTotalClaimed(1);
              }
            } else if (
              currentPage.contractStandard.toUpperCase() === "ERC1155"
            ) {
              const res = await contract.methods
                .totalERC1155Claimed(currentPage.sessionID)
                .call();
              setTotalClaimed(res);
              valentineDrop ? setPopUp(true) : setPopUp(false);
            }
          } else {
            setError("Unable to  claim");
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      if(error?.message?.includes("Already claimed!")){
        setError("You have already claimed this NFT");
        setIsClaimed(true);
        setIsLoading(false);
      } else {
      setError("Please refresh the page and try again!");
      setIsLoading(false);
      }
    }
  };
  const claimWithGas = async () => {
    try {
      setIsLoading(true);

      const provider = await connector?.getProvider();
      const web3 = new Web3(provider);

      var currentAbi =
        currentPage.contractStandard.toUpperCase() === "ERC721"
          ? globalABI.ERC721.abi
          : globalABI.ERC1155.abi;
      var currentC = currentPage.contractAddress;

      var c = new web3.eth.Contract(currentAbi, currentC);
      var tempTokenId = currentPage.tokenId;
      var checktx;
      if (currentPage.contractStandard.toUpperCase() === "ERC721") {
        checktx = await c.methods.balanceOf(address).call();
      } else {
        checktx = await c.methods.balanceOf(address, tempTokenId).call();
      }
      if (checktx && parseInt(checktx) >= 1) {
        valentineDrop ? setPopUp(true) : setPopUp(false);
        setError("You have already claimed this NFT");
        setIsClaimed(true);
        setIsLoading(false);
        return;
      }

      var claimNFTABI = globalClaim.ClaimNFT.abi;
      var claimNFTContract = globalClaim.ClaimNFT.address;
      let tx;
      const contract = new web3.eth.Contract(claimNFTABI, claimNFTContract);
      var sess = currentPage.sessionID;
      if (currentPage.contractStandard.toUpperCase() === "ERC721") {
        const totalGas = await contract.methods
          .claimSingleERC721(sess)
          .estimateGas({ from: wallet });
        const gasPrice = await web3.eth.getGasPrice();
        tx = await contract.methods.claimSingleERC721(sess).send({
          from: wallet,
          gas: totalGas.toString(),
          gasPrice: gasPrice,
        });
      } else if (currentPage.contractStandard.toUpperCase() === "ERC1155") {
        if (currentPage.exclusive) {
          const totalGas = await contract.methods
            .claimListERC1155(sess)
            .estimateGas({ from: wallet });
          const gasPrice = await web3.eth.getGasPrice();
          tx = await contract.methods.claimListERC1155(sess).send({
            from: wallet,
            gas: totalGas.toString(),
            gasPrice: gasPrice,
          });
        } else {
          const totalGas = await contract.methods
            .claimERC1155(sess)
            .estimateGas({ from: wallet });
          const gasPrice = await web3.eth.getGasPrice();
          tx = await contract.methods.claimERC1155(sess).send({
            from: wallet,
            gas: totalGas.toString(),
            gasPrice: gasPrice,
          });
        }
      }

      if (tx) {
        setIsLoading(false);
        setIsClaimed(true);
        if (currentPage.contractStandard.toUpperCase() === "ERC721") {
          const res = await contract.methods
            .isERC721Claimed(currentPage.sessionID)
            .call();
          if (res) {
            setTotalClaimed(1);
          }
        } else if (currentPage.contractStandard.toUpperCase() === "ERC1155") {
          const res = await contract.methods
            .totalERC1155Claimed(currentPage.sessionID)
            .call();
          setTotalClaimed(res);
          valentineDrop ? setPopUp(true) : setPopUp(false);
        }
      } else {
        setError("Unable to  claim");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      if(error?.message?.includes("Already claimed!")){
        setError("You have already claimed this NFT");
        setIsClaimed(true);
        setIsLoading(false);
      } else{
      setError("Please refresh the page and try again!");
      setIsLoading(false);
      }
    }
  };

  const deleteClaim = async () => {
    try {
      let sessionId = currentPage.sessionID;
      setIsLoading(true);
      const web3 = new Web3(signer?.provider);
      var currentAbi = globalClaim.ClaimNFT.abi;
      var currentContract = globalClaim.ClaimNFT.address;

      const contract = new web3.eth.Contract(currentAbi, currentContract);

      const totalGas = await contract.methods
        .cancel(sessionId)
        .estimateGas({ from: wallet });
      const gasPrice = await web3.eth.getGasPrice();

      const tx = await contract.methods
        .cancel(sessionId)
        .send({ from: wallet, gas: totalGas.toString(), gasPrice: gasPrice });

      if (tx) {
        console.log(tx);
        const deleteCall = await fetch(`${BASE_URL}/v1/claim/deactivate`, {
          method: "POST",
          body: JSON.stringify({ wallet, url: claimId }),
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        const deleteRes = await deleteCall.json();

        if (deleteRes?.status === true) {
          setIsLoading(false);
          navigate("/profile");
        } else {
          setError("Unable to delete the claim page. Please try again later.");
          setIsLoading(false);
        }
      } else {
        setError("Unable to delete the claim page. Please try again later.");

        setIsLoading(false);
      }
    } catch (error) {
      setError("Unable to delete the claim page. Please try again later.");

      console.log("Error", error);
    }
  };

  return (
    <section className=" flex h-[100%] md:h-screen w-full relative">
      <div className="video__wrapper bg-white md:hidden">
        <video
          src={"/videos/Screen1.mp4"}
          className="bg-white object-cover w-full h-full"
          autoPlay
          loop
          muted
          playsInline
        ></video>
      </div>
     
      {currentNft.contract ? (
        <ClaimBlock>
          {popUp &&<>
      <div className="claimpopup absolute z-10 bg-white md:bg-[#000000ad] inset-0 grid items-center justify-center max-w-full ">
     
      <div className="claimpopup__inner w-[95%] md:w-2/3 bg-white p-8 rounded-xl mx-auto ">
      <div
          className="back flex items-center pb-0 cursor-pointer gap-1 mb-4"
          onClick={() => setPopUp(false)}
        >
          <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
    >
      <path
        fill="#000"
        d="M17.198 11.271c0-4.435-3.61-8.045-8.045-8.045H5.946V.5L.801 4.666l5.145 4.167v-3.13h3.207a5.572 5.572 0 015.568 5.568 5.572 5.572 0 01-5.568 5.568H2.606c-.691 0-1.23.557-1.23 1.23 0 .69.558 1.228 1.23 1.228h6.547c4.435.02 8.045-3.59 8.045-8.026"
      ></path>
    </svg>
          <p className="font-semibold text-black">Go Back</p>
        </div>
       
          <div className="flex gap-4 justify-center items-center mt-4">
            <label className="text-4xl font-semibold gt-w ">🎁 Gift Your Valentine
            </label>
            
          </div>
          <div className="image__container mx-auto text-center w-full h-full md:p-8">
              <img
                src={`https://ipfs.io/ipfs/${currentNft.metadata.image.substring(
                  7,
                  currentNft.metadata.image.length
                )}`}
                alt=""
                className="h-full md:w-[25%] w-full object-contain mx-auto rounded-xl "
              />
            </div>
            <div className="mx-0 md:mx-12 justify-center items-center mt-4">
            <label className="text-lg  font-semibold py-5 gt-w">Add valentine's address*
            </label>
            <input
                    type="text"
                    name="to_address"
                    value={toAddress}
                    onChange={(e) => setToAddress(e.target.value)}
                    id="to_address"
                    placeholder="Enter wallet address"
                    className="w-full border-2 border-solid border-[#cccccc] p-2 rounded-xl my-2"
                    required
                  />
              <button
                      className="bg-[#3BBEAE] w-full px-5 py-3 my-3 rounded-full text-white font-semibold text-center gt-w"
                      onClick={() =>
                         valentineDrop ? airDropItem() : airDropItemGas()
                      }
                    >
                     Send as Gift
                    </button>
        </div>

         
      </div>
      </div>
    </>}
          <div className="w-full mx-auto h-full  lg:h-[100%] md:w-[40%] lg:w-[50%] max-w-[360px] md:max-w-[400px] lg:max-w-[50%]  gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden relative">
            <div className="image__container mx-auto text-center w-full h-full md:p-12">
              <img
                src={`https://ipfs.io/ipfs/${currentNft.metadata.image.substring(
                  7,
                  currentNft.metadata.image.length
                )}`}
                alt=""
                className="h-full w-full object-contain mx-auto rounded-xl "
              />
            </div>

            {Date.now() / 1000 < currentPage.startDate ? (
              <div className="absolute bottom-6 left-6 right-6  claimprop rounded-xl overflow-hidden  mx-auto my-3 mt-4 font-semibold flex  items-center gt-w bg-[#3A4662] max-w-[400px]">
                {" "}
                <div className="prop flex items-center gap-1 text-white justify-center bg-[#3BBEAE] p-2 w-[100%]">
                  Claim not started yet!
                </div>
              </div>
            ) : (
              currentPage.endDate !== 0 &&
              currentPage.startDate !== 0 &&
              Date.now() / 1000 < currentPage.endDate && (
                <div className="absolute bottom-6 left-6 right-6  claimprop rounded-xl overflow-hidden  mx-auto my-3 mt-4 font-semibold flex  items-center gt-w bg-[#3A4662] max-w-[400px]">
                  <div className="prop flex items-center gap-1 text-white justify-center bg-[#3BBEAE] p-2 w-[50%]">
                    <img src={timer} className="h-4" alt="" /> Time left to
                    Claim
                  </div>
                  <div className="prop-value p-2 px-4 text-white font-medium text-center bg-[#3A4662] w-[50%] ">
                    {Math.ceil(
                      (new Date(currentPage.endDate).getTime() -
                        new Date(Date.now() / 1000).getTime()) /
                        (24 * 60 * 60)
                    )}{" "}
                    Days
                  </div>
                </div>
              )
            )}
          </div>
          <div className="w-full md:h-[100%] lg:w-[60%] md:w-[50%] p-4 flex items-center md:overflow-y-auto">
            <div className="w-full">
              {error ? (
                <div className="flex font-semibold text-[#333333] items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                  <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                  <p>{error}</p>
                </div>
              ) : null}
              <h2 className="text-3xl font-black md:text-5xl  py-5 ">
                {currentNft.title}
              </h2>
              <div className="claimprops flex items-center gap-2 flex-wrap font-regular gt-w ">
                <div className="claimprop rounded-lg overflow-hidden w-auto  ">
                  <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                    <img src={claimed} className="h-4 mr-1" alt="" />
                    {totalClaimed} Claimed
                  </div>
                </div>
                <div className="claimprop w-auto  rounded-lg overflow-hidden">
                  <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                    <img src={fingerprint} className="h-4 mr-1" alt="" />
                    {currentPage.exclusive === true
                      ? "Exclusive"
                      : "Public"}{" "}
                    Access
                  </div>
                </div>
                <div className="claimprop rounded-lg overflow-hidden w-auto  ">
                  <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                    <img src={supply} className="h-4 mr-1" alt="" />
                    {currentPage.supply} Supply
                  </div>
                </div>
              </div>
              <p className="font-medium text-[#1e1e1e] text-[18px] md:text-[21px] py-3 gt-w">
                {currentNft.description.length > 200
                  ? currentNft.description.substring(0, 200) + "..."
                  : currentNft.description}
              </p>

              {/* <p className="py-1 pb-3 flex items-center gt-w">
                <img src={linkIcon} alt="" className="mr-2 h-4" />{" "}
                <span className="text-[#3BBEAE] font-semibold text-[14px] md:text-[16px]">
                  {window.location.href}
                </span>
              </p> */}

              <div className="button__container text-center">
                {parseInt(totalClaimed) >= parseInt(currentPage.supply) ? (
                  <>
                    <button
                      className="bg-[#3BBEAE] w-full px-5 py-3 my-3 rounded-full text-white font-semibold text-center"
                      onClick={() =>
                        window.open(
                          `https://opensea.io/assets/matic/${
                            currentPage.contractAddress
                          }/${Number(currentPage.tokenId)}`
                        )
                      }
                    >
                      View on Opensea
                    </button>
                  </>
                ) : Date.now() / 1000 < currentPage.startDate ? (
                  <></>
                ) : currentPage.endDate !== 0 &&
                  currentPage.startDate !== 0 &&
                  Date.now() / 1000 > currentPage.endDate ? (
                  <div>
                    <button
                      className="bg-red-400 w-full px-5 py-3 my-3 rounded-full text-white font-semibold text-center"
                      disabled
                    >
                      Claim Ended
                    </button>
                  </div>
                ) : (
                  <div>
                    {!wallet ? (
                      <div className="button-div buttons btn-rainbow mb-3">
                        <ConnectButton />
                      </div>
                    ) : wallet === currentPage.ownerAddress ? (
                      <button
                        className="bg-red-400 w-full px-5 py-3 my-3 rounded-full text-white font-semibold text-center"
                        onClick={deleteClaim}
                      >
                        Delete
                      </button>
                    ) : !isClaimed ? (
                      <button
                        className="bg-[#3BBEAE] w-full px-5 py-3 my-3 rounded-full text-white font-semibold text-center"
                        onClick={claim}
                      >
                        Claim
                      </button>
                    ) : (
                      <button
                      onClick={() =>
                        window.open(
                          `https://opensea.io/assets/matic/${
                            currentPage.contractAddress
                          }/${Number(currentPage.tokenId)}`
                        )
                      }
                      className="bg-[#3BBEAE] w-full px-5 py-3 my-3 rounded-full text-white font-semibold text-center">
                        View on Opensea
                      </button>
                    )}
                    <p
                      onClick={() => (window.location.href = "/")}
                      className="cursor-pointer font-semibold"
                    >
                      Home
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ClaimBlock>
      ) : isLoading ? (
        <div className="absolute z-20 inset-0 h-[100vh] bg-[#000a] flex items-center justify-center ">
          <div className="text-center">
            <ClipLoader size={60} color="#3BBEAE" />
            <p className="text-white font-semibold py-3">Please Wait...</p>
          </div>
        </div>
      ) : (
        <BuildBlock>
          <div className="border-2 rounded-2xl  p-4 flex h-full justify-center items-center w-full">
            <div className="text-center">
              <p className="font-black text-3xl md:text-5xl pb-3">WHOOPS!</p>
              <p className="font-medium text-[20px] md-text-[24px]">
                You've requested for a page that does not exist. Please try
                again.
              </p>
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 mt-4 px-16"
                onClick={() => navigate("/")}
              >
                Home
              </button>
            </div>
          </div>
        </BuildBlock>
      )}
      {/* { : null} */}
      {isLoading ? (
        <div className="absolute z-20 inset-0 h-[100vh] bg-[#000a] flex items-center justify-center ">
          <div className="text-center">
            <ClipLoader size={60} color="#3BBEAE" />
            <p className="text-white font-semibold py-3">Please Wait...</p>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default Claimpage;
