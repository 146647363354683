import { useGCtx } from "../../Contexts/GlobalContext";
import menubars from "../../assets/images/menu.svg";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

const WalletAddress = () => {
  const { setShowNav, disconnect } = useGCtx();
  const { isConnected } = useAccount({
    onDisconnect() {
      disconnect();
    },
  });
  // const [showMenu, setShowMenu] = useState(false);
  // const handleClick = (e) => {
  //   if (e.target.tagName !== "BUTTON" && e.target.id !== "nav") {
  //     setShowMenu(!showMenu);
  //   }
  // };
  return (
    <div className="flex items-center gap-6 top-[20px] md:top-[5%] right-[20px] cursor-pointer z-10 absolute md:my-auto max-w-[95%] ">
      <div class="buttonInNav ">
        {isConnected ? (
          <ConnectButton
            showBalance={{
              smallScreen: false,
              largeScreen: false,
            }}
          />
        ) : null}
      </div>

      <div className="bg-white p-2 rounded-lg">
        <img
          src={menubars}
          alt=""
          className="h-8"
          onClick={() => setShowNav(true)}
        />
      </div>
    </div>
  );
};

export default WalletAddress;
