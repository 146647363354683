import { useGCtx } from "../../Contexts/GlobalContext";

const BuildNftImage = () => {
  const { assetPreview } = useGCtx();

  return (
    <div className="image__container mx-auto text-center w-full">
      <img
        src={assetPreview?.file}
        alt=""
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "/notloaded.png";
        }}
        className="h-[300px] w-[300px] md:h-[200px] md:w-[200px] lg:h-[360px] lg:w-[360px]  object-cover object-center mx-auto rounded-xl border-4 border-solid border-white "
      />
    </div>
  );
};

export default BuildNftImage;
