import linkIcon from "../../assets/images/linkIcon.svg";
import { useGCtx } from "../../Contexts/GlobalContext";
const BuildNftDetails = () => {
  const { nftData } = useGCtx();
  const [name, shortDescription, link] = [
    nftData.name,
    nftData.description,
    nftData.external_link,
  ];
  return (
    <div className="  w-[300px] md:w-[100%] lg:w-[360px] max-w-full bg-white rounded-lg p-3 py-5 mx-auto my-3 box-shadow-block">
      {name ? (
        <p className="font-black text-2xl md:text-3xl">{name}</p>
      ) : (
        <p className="font-black text-2xl md:text-3xl opacity-70">Name</p>
      )}
      {shortDescription ? (
        <p className="font-medium md:text-[21px] py-2">{shortDescription}</p>
      ) : (
        <p className="font-medium md:text-[21px] py-2 opacity-70">
          A short description
        </p>
      )}
      {link ? (
        <p className="font-medium flex items-center">
          <img src={linkIcon} alt="" className="h-6 mr-2" />{" "}
          <a href={link} target="_blank" rel="noreferrer">
            {link}
          </a>
        </p>
      ) : (
        <p className="font-medium flex items-center opacity-70">
          <img src={linkIcon} alt="" className="h-6 mr-2" /> Link Here
        </p>
      )}
    </div>
  );
};

export default BuildNftDetails;
