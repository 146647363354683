import { useEffect } from "react";

import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import { useGCtx } from "../../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";

const SuccessMint = () => {
  const { wallet, nftData, assetPreview, nftType, txhash, collection } = useGCtx();

  const navigate = useNavigate();
  useEffect(() => {
    if (nftData.name && nftData.description && wallet && assetPreview.file) {
    } else {
      window.location.href = "/";
    }
  }, []);

  return (
    <section className="connect flex h-screen w-full items-end">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-6xl text-4xl font-black uppercase">
            Minting <br className="hidden md:block" />
            Done
          </h1>
          <p className="font-semibold py-4 text-xl md:text-2xl">
            Your NFT was minted successfully!
          </p>
          <div className="h-[200px] grid items-center">
            {assetPreview ? (
              <div>
                <img
                  src={assetPreview.file}
                  alt=""
                  className="mx-auto h-[200px] border-4 rounded-xl max-w-full"
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="buttons">
          <button
            className="flex items-center justify-center gap-3 bg-[#3BBEAE] w-full px-5 py-4 my-2 rounded-full text-white font-semibold"
            onClick={() => window.open(`https://polygonscan.com/tx/${txhash}`)}
          >
            View on PolygonScan
          </button>
          {nftType === "erc721" && !collection ? (
            <button
              className="flex items-center justify-center gap-3 bg-[#3BBEAE] w-full px-5 py-4 my-2 rounded-full text-white font-semibold"
              onClick={() => navigate("/dehidden/add-utilities")}
            >
              <span>Add Utilities</span>
            </button>
          ) : (
            <button
              className="flex items-center justify-center gap-3 bg-[#3BBEAE] w-full px-5 py-4 my-2 rounded-full text-white font-semibold"
              onClick={() => navigate("/share")}
            >
              <span>Share your NFT</span>
            </button>
          )}
          <p
            className="py-2 cursor-pointer text-center"
            onClick={() => (window.location.href = "/")}
          >
            Exit
          </p>
        </div>
      </ContentBlock>
    </section>
  );
};

export default SuccessMint;
