import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAccount, useSignMessage } from "wagmi";

const GlobalContext = createContext({});

export function useGCtx() {
  return useContext(GlobalContext);
}

export default function GlobalContextProvider({ children }) {
  const [wallet, setWallet] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { address, isConnected } = useAccount();

  const [serverDown, setServerDown] = useState(false);
  let batchId = 1;
  //Mint NFT pages states
  const [mintType, setMintType] = useState("");
  const [nftType, setNftType] = useState("");
  const [erc721Type, setERC721Type] = useState("");
  const [networkType, setNetworkType] = useState("");
  //eslint-disable-next-line
  const [isUploading, setIsUploading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Please Wait...");
  const [isMinting, setIsMinting] = useState(false);
  //eslint-disable-next-line
  const [isMinted, setIsMinted] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [userNFTs, setUserNFTs] = useState([]);
  const [batchSessions, setBatchSessions] = useState();
  const [useCase, setUseCase] = useState("");
  const [showNav, setShowNav] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [collection, setCollection] = useState(false);
  var pings = 0;

  useEffect(() => {
    console.log("USE !");
    if (!address) {
      return;
    }
    if (address !== wallet) {
      setHasSigned(false);
      if (!getApiKey()) {
        getNonce();
      } else {
        setLoading(true);
        setWallet(address);
        setUserApiKey(getApiKey());
        setHasSigned(true);
        setLoading(false);
      }
    }
  }, [wallet, address]);

  const getNonce = async (selectedAccount) => {
    if (!address) return;
    const data = {
      wallet: address,
    };
    const nonce = await fetch(`${process.env.REACT_APP_BASE_URL}/api/nonce`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      method: "POST",
      body: JSON.stringify(data),
    });

    var nonceToSign = await nonce.json();
    if (nonceToSign.nonce) {
      // signAndVerify(nonceToSign.nonce);
      setLoading(true);
      setLoadingMessage("Please check your wallet prompt");
      signMessage({ message: nonceToSign.nonce });
    }
  };

  const getApiKey = () => {
    if (!localStorage.getItem("API_KEY")) return false;
    const { wallet, apiKey } = JSON.parse(localStorage.getItem("API_KEY"));

    if (wallet === address && apiKey) {
      return apiKey;
    } else {
      localStorage.removeItem("API_KEY");
      return false;
    }
  };
  const { data, error, isLoading, signMessage } = useSignMessage({
    onSuccess(data, variables) {
      // Verify signature when sign message succeeds

      signAndVerify(data);
    },
  });
  const signAndVerify = async (tempSignature) => {
    try {
      setLoading(true);
      var data = {
        wallet: address,
        signature: tempSignature,
      };
      const apires = await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        method: "POST",
        body: JSON.stringify(data),
      });

      var apiKey = await apires.json();
      if (apiKey.apiKey) {
        localStorage.setItem(
          "API_KEY",
          JSON.stringify({ wallet: address, apiKey: apiKey.apiKey })
        );
        setUserApiKey(apiKey.apiKey);
        setWallet(address);
        setHasSigned(true);
        setLoading(false);
      }
    } catch (e) {
      // hcaptcha.reset();
      // setIsConnected(false);
      setLoading(false);
      console.log(e);
    }
  };

  // useEffect(() => {
  //   console.log("USE !!!");

  //   if (isConnected) {
  //     setAssistantText("");

  //     if (!getApiKey()) {
  //       getNonce();
  //     } else {
  //       setWallet(address);
  //       setUserApiKey(getApiKey());
  //     }
  //   }
  // }, [isConnected]);

  useEffect(() => {
    if (pings < 1) {
      fetch(`${process.env.REACT_APP_BASE_URL}`, { method: "GET" })
        .then((res) => {
          setServerDown(false);
        })
        .catch((e) => {
          setServerDown(true);
        });
    }
  }, []);

  const [attributes, setAttributes] = useState([
    {
      trait_type: "",
      value: "",
      display_type: "number",
      id: 0,
    },
    {
      trait_type: "",
      value: "",
      id: 1,
    },
    {
      trait_type: "",
      value: "",
      of: "",
      display_type: "percent",
      id: 2,
    },
  ]);

  const [assetPreview, setAssetPreview] = useState({ file: {}, fileInfo: {} });
  const [uploadedFile, setUploadedFile] = useState({ file: {}, fileInfo: {} });
  const [batchFiles, setBatchFiles] = useState({
    assets: [],
    preview: [],
    metadata: {},
  });
  const [autographedImage, setAutographedImage] = useState(null);
  const [autographedText, setAutographedText] = useState("");
  const [dehiddenModules, setDehiddenModules] = useState({
    autograph: false,
    phygital: false,
    unlockableContent: false,
    airdrop: false,
  });
  const [ipfsHash, setIpfsHash] = useState("");
  const [txhash, setTxhash] = useState("");
  const [mintedTokenId, setMintedTokenId] = useState("");
  const [isUserNftLoading, setIsUserNftLoading] = useState(false);
  const [batchSessionType, setBatchSessionType] = useState("");
  const [userApiKey, setUserApiKey] = useState("");
  const [nftData, setNftData] = useState({
    name: "",
    description: "",
    external_link: "",
    amount: "1",
    isAdded: false,
  });

  const [assistantText, setAssistantText] = useState("");
  const [checkTime, setCheckTime] = useState(null);

  var tries = 0;
  useEffect(() => {
    if (wallet) {
      if (tries < 1) {
        getNfts();
        getBatchSessions();
        tries++;
      }
    }
  }, [wallet]);

  const getNfts = async () => {
    setIsUserNftLoading(true);
    var currentNetwork = networkType === "80001" ? "mumbai" : "mainnet";

    const getLocalDataCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/nft/${currentNetwork}/${wallet}/0`,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );

    const localData = await getLocalDataCall.json();

    if (localData && localData.actualNFTs && localData.actualNFTs.length > 0) {
      setUserNFTs(localData.actualNFTs);
      setIsUserNftLoading(false);
    } else {
      setUserNFTs([]);
      setIsUserNftLoading(false);
    }
  };
  const getBatchSessions = async () => {
    setIsUserNftLoading(true);

    const getLocalDataCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/batch/all/${wallet}`,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );

    const localData = await getLocalDataCall.json();

    if (localData && localData.length) {
      setBatchSessions(localData);
      setIsUserNftLoading(false);
    } else {
      setBatchSessions([]);
      setIsUserNftLoading(false);
    }
  };
  const resetState = () => {
    setTxhash("");
    setIpfsHash("");
    setAssetPreview({ file: {}, fileInfo: {} });
    setDehiddenModules({
      autograph: false,
      phygital: false,
      unlockableContent: false,
      airdrop: false,
    });
    setUploadedFile({ file: {}, fileInfo: {} });
    setNftData({
      name: "",
      description: "",
      external_link: "",
      amount: "1",
      isAdded: false,
    });
    setERC721Type("");
    setIsMinted(false);
    setIsMinting(false);
    setIsUploading(false);
    setAutographedImage(null);
    setAutographedText("");
    setMintedTokenId("");
  };

  // useEffect(() => {
  //   var pro = localStorage.getItem("provider");
  //   if (!location.pathname.includes("/view/matic")) {
  //     if (!isConnected) {
  //       if (pro && pro === "injected") {
  //         activate(connectors.injected);
  //         window.localStorage.setItem("provider", pro);
  //       } else if (pro === "walletConnect") {
  //         activate(connectors.walletConnect);
  //         window.localStorage.setItem("provider", pro);
  //       }
  //     }
  //   }
  // }, []);

  const callAssistant = (text) => {
    if (checkTime) {
      setAssistantText("");
      clearTime();
    }
    setAssistantText(text);
    var timeOut = setTimeout(() => {
      setAssistantText("");
    }, 8000);
    setCheckTime(timeOut);
  };

  const clearTime = () => {
    clearTimeout(checkTime);
    setCheckTime(null);
  };
  useEffect(() => {
    let nftTypeData = localStorage.getItem("type");
    if (nftType === "erc1155") {
      setERC721Type("");
    }
    if(nftType === "" && nftTypeData && nftTypeData?.length > 0){
      setNftType(nftTypeData);
    }
  }, [nftType]);



  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
    resetState();
  };

  // useEffect(() => {
  //   if (!isConnected) {
  //     refreshState();
  //     setWallet("");
  //     localStorage.removeItem("provider");
  //   }
  // }, [isConnected]);

  const disconnect = () => {
    if (!isConnected) {
      refreshState();
      setWallet("");
      localStorage.removeItem("provider");
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        wallet,
        setWallet,
        loading,
        loadingMessage,
        setLoading,
        setLoadingMessage,
        nftType,
        setNftType,
        erc721Type,
        setERC721Type,
        networkType,
        setNetworkType,
        assetPreview,
        setAssetPreview,
        uploadedFile,
        setUploadedFile,
        nftData,
        setNftData,
        mintType,
        setMintType,
        setAssistantText,
        assistantText,
        callAssistant,
        isUploading,
        setIsUploading,
        isMinting,
        setIsMinting,
        isMinted,
        setIsMinted,
        attributes,
        setAttributes,
        clearTime,
        setIpfsHash,
        ipfsHash,
        dehiddenModules,
        setDehiddenModules,
        txhash,
        setTxhash,
        autographedImage,
        setAutographedImage,
        autographedText,
        setAutographedText,
        setIsPending,
        isPending,
        mintedTokenId,
        setMintedTokenId,
        resetState,
        batchId,
        isUserNftLoading,
        userNFTs,
        getNfts,
        batchSessionType,
        setBatchSessions,
        setBatchSessionType,
        batchFiles,
        setBatchFiles,
        useCase,
        setUseCase,
        serverDown,
        userApiKey,
        batchSessions,
        getBatchSessions,
        showNav,
        setShowNav,
        disconnect,
        hasSigned,
        setCollection,
        collection,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
