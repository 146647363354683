import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Assistant from "../../Components/Assitant/Assistant";

import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";

import { useGCtx } from "../../Contexts/GlobalContext";

const SelectCollection = () => {
  const [extended, setExtended] = useState("create-collection");
  const { setCollection, callAssistant, setAssistantText,nftType,wallet } = useGCtx();

  const [contracts, setContracts] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomContracts()
    return () => {
      fetchCustomContracts()
    }
  }, [wallet])
  
  const fetchCustomContracts = async() => {
    try{
      const data = await fetch(`${process.env.REACT_APP_BASE_URL}/v2/collection/user/${wallet}`,{
        method:"GET",
        headers:{
          "x-api-key":process.env.REACT_APP_API_KEY,
          "x-auth-token": process.env.REACT_APP_API_KEY,
        }
      })
      const response = await data.json()
      let contracts = response.data?.filter((item) => item.nftType?.toLowerCase() === nftType)
      setContracts(contracts?.length > 0 ? contracts : false)
    }
    catch(error){
      console.log(error)
    }
  }
  const handleClickExtention = (e, type) => {
    setExtended(type);
    if (e.target.tagName === "BUTTON") {
      return;
    }
    if (type === "create-collection") {
      setTimeout(() => {
        callAssistant(
          "You will be able to mint your NFTs to a new collection"
        );
      }, 100);
    } else if (type === "default") {
      setTimeout(() => {
        callAssistant(
          "You will be able to mint your NFTs to an default collection"
        );
      }, 100);
    }
  };
  const handleContinue = (type) => {
    setAssistantText("");
    if(type === "create-collection") {
    localStorage.setItem("collection", true);
    localStorage.setItem("type", nftType);
    setCollection(true);
    navigate("/create-contract");
    } 
    else {
    navigate("/upload");
  }
  };

  return (
    <section className="select  flex h-screen w-full items-end">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-6xl text-5xl font-black uppercase">
            Where <br className="hidden md:block" />
            To Mint ?<br className="hidden md:block" />
            
          </h1>
          {/* <p
            className="mt-2 cursor-pointer rounded-lg p-2 border bg-[#fefefe] font-semibold text-sm"
            
          >
            <span className="mr-2">ⓘ</span>
           By default your NFT is minted to the 
           <span
           onClick={() => 
            nftType === "erc721" ? window.open("https://opensea.io/collection/mintnft-721") : window.open("https://opensea.io/collection/mintnft-1155")
          }
           className="font-bold text-blue-500">
             {" "}Master Collection
           </span>, you can also choose to mint the nft to your collection{" "}
            {" "}
          </p> */}
          <div className="options__container pt-2">
           
            {/* <OptionItem
              heading="Badges"
              description="lorem ipsum dolor sit amet,consectertur adiplcing"
              buttonText="Continue"
              isExtended={extended === "badges"}
              type={"badges"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            /> */}
            {/* <OptionItem
              heading="🚀 Let's Mint it"
              description="Mint your NFTs to an default collection"
              buttonText="Continue"
              isExtended={extended === "default"}
              type={"default"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            /> */}
             <OptionItem
              heading="🎨 Let's Mint to My Collection"
              description="Mint to the NFTs to my new or existing collection"
              buttonText="create-collection"
              isExtended={extended === "create-collection"}
              type={"create-collection"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
              data={contracts}
              setCollection={setCollection}
            />
          </div>
        </div>
        <div className="progress">
          <ProgressBar phase={1} percentage={70} />
        </div>
      </ContentBlock>
      <Assistant />
    </section>
  );
};

const OptionItem = ({
  heading,
  buttonText,
  description,
  isExtended,
  type,
  handleClickExtention,
  handleContinue,
  pill,
  data,
  setCollection,
}) => {
  return (
    <div
      className={
        isExtended
          ? " bg-[#fefefea9] optionitem my-3 p-4 rounded-lg cursor-pointer"
          : " bg-[#fefefe6f] optionitem my-3 px-4 py-1 rounded-lg cursor-pointer"
      }
      onClick={(event) => handleClickExtention(event, type)}
    >
      <p
        className={
          !isExtended
            ? "optionitem__heading text-xl font-black md:text-2xl text-[#1f1f1f]"
            : "optionitem__heading text-xl font-black md:text-2xl text-[#008376]"
        }
      >
        {heading}
        {pill && (
          <span
            className={
              isExtended
                ? "ml-3 text-[10px] font-semibold text-white bg-[#1f1f1f] px-3 py-1 rounded-xl"
                : "ml-3 text-[10px] font-semibold text-white bg-[#1f1f1fa7] px-3 py-1 rounded-xl"
            }
          >
            {pill}
          </span>
        )}
      </p>

      <div className={isExtended ? "extended" : "not-extended"}>
        <p className="optionitem__heading font-medium pt-2 md:text-[18px]">
          {description}
        </p>
        {buttonText === "create-collection" ?
          <>
         <div className={`grid gap-2 ${!data ? "grid-cols-1" : "grid-cols-2"}` }>
         <button
          className="bg-[#3BBEAE] w-full p-2  mt-3 rounded-lg text-white font-semibold text-center"
          onClick={() => handleContinue(type)}
        >
           +
        </button>
        {data && data.length > 0 &&
        <button
          className="text-left bg-white w-full p-2 mt-3 text-black flex gap-2"
          onClick={() => {
            localStorage.setItem("collectionAddress", data[0]?.collectionAddress)
            setCollection(data[0]?.collectionAddress)
            handleContinue("default")
          }}
        >
          <div className="w-1/3 h-full">
          <img src={require("../../assets/images/vectorGrad.png")} className="h-full" />
          </div>
          <div>
         <span className="text-[#3BBEAE] font-bold text-lg">{data[0].name?.slice(0,5)}</span>
         <br/>
          <span className="text-black text-sm ">{data[0].symbol?.slice(0,5)}</span>
          </div>
        </button>
        }

   {data && data?.length > 1 &&
        data?.slice(1,)?.map((item, index) => 
        <button
          className="text-left bg-white w-full p-2  mt-3 text-black flex gap-2"
          onClick={() => {
            localStorage.setItem("collectionAddress", item?.collectionAddress)
            setCollection(item?.collectionAddress)
            handleContinue("default")}
          }
        >
          <div className="w-1/3 h-full">
          <img src={require("../../assets/images/vectorGrad.png")} className="h-full" />
          </div>
          <div>
         <span className="text-[#3BBEAE] font-bold text-lg">{item?.name?.slice(0,8)}</span>
         <br className="hidden md:block" />
          <span className="text-black text-sm ">{item?.symbol?.slice(0,5)}</span>
          </div>
        </button>
        )
        }
         

        </div>

       
         </>
        :
        <button
          className="bg-[#3BBEAE] w-full px-5 py-4 mt-3 rounded-full text-white font-semibold text-center"
          onClick={() => handleContinue(type)}
        >
          {buttonText}
        </button>
}
      </div>
    </div>
  );
};




export default SelectCollection;
