import alertCircle from "../../assets/images/alertCircle.svg";
import { useNavigate } from "react-router-dom";
import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import { useGCtx } from "../../Contexts/GlobalContext";
import polygonBranding from "../../assets/images/logo.svg";
import DehiddenBranding from "../../assets/images/DehiddenLogo.svg";

import homePoster from "../../assets/images/homePoster.png";
import "./Home.css";

const Home = () => {
  const { callAssistant, resetState, serverDown, setUseCase } = useGCtx();
  const navigate = useNavigate();
  const handleNextPage = (usecase) => {
    resetState();
    setUseCase(usecase);
    navigate("/connect");
    setTimeout(() => {
      callAssistant([
        "Dont worry.",
        <br />,
        "- We don't use your keys",
        <br />,
        "- We don't drain your crypto",
        <br />,
        "- We can't target you with ads",
      ]);
    }, 2000);
  };

  return (
    <section className="home flex h-screen w-full items-end">
      <div className="video__wrapper">
        <video
          src={
            "https://s3.ap-south-1.amazonaws.com/assets.withcope.xyz/hero.mp4"
          }
          autoPlay
          loop
          muted
          playsInline
          poster={homePoster}
        ></video>
      </div>
      {serverDown ? (
        <div className="absolute top-0 left-0 right-0 p-2 py-3 font-semibold rounded-b-lg bg-red-300 flex items-center z-10">
          {" "}
          <img src={alertCircle} alt="" className="h-6 mr-3" /> Server Down.
          Please contact the administrator.
        </div>
      ) : null}



      <ContentBlock>
        <div className="top__content">
          <h1 className="text-4xl font-black uppercase md:text-8xl ">
            Mint <br className="hidden md:block" />
            It <br className="hidden md:block" />
            Big
          </h1>
          <p className="font-semibold py-4 text-xl md:text-2xl">
          The world’s simplest NFT creation tool. <br /> Go from idea to NFT in 80 seconds!
          </p>
        </div>
        <div>
          <p
            onClick={() => handleNextPage("mint")}
            className="bg-[#3BBEAE] my-2 w-full px-5 py-4 rounded-full text-white font-semibold text-center cursor-pointer"
          >
            Mint Your NFT
          </p>
        </div>
      </ContentBlock>
      <div className="h-12 absolute hidden md:flex gap-8  top-10 right-10 md:bottom-10 md:top-auto ">
        <div className="log_box">
          <p className="text-white font-semibold">Powered by</p>
          <a href="https://dehidden.com/" target="_blank" rel="noopener noreferrer">

        <img src={DehiddenBranding} alt="" className="h-full w-[150px]" />
        </a>


        </div>
        <div className="log_box">
          <p className="text-white font-semibold">Built with</p>
          <img src={polygonBranding} alt="" className="h-full w-[120px]" />



        </div>

      </div>
    </section>
  );
};

export default Home;
