import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Assistant from "../../Components/Assitant/Assistant";

import ContentBlock from "../../Components/ContentBlock/ContentBlock";

import { useGCtx } from "../../Contexts/GlobalContext";
import { CreateCollectionABI } from "../../assets/ABI/CreateCollectionABI";
import Web3 from "web3";
import { ClipLoader } from "react-spinners";
import alertCircle from "../../assets/images/alertCircle.svg";
import { useAccount } from "wagmi";
const CreateCollection = () => {
  const [collectionName, setcollectionName] = useState("");
  const [symbolName, setsymbolName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { sessionID } = useParams();
  const { connector } = useAccount();
  const navigate = useNavigate();
  const { wallet } = useGCtx();

  useEffect(() => {
    if (error.length) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);

  const handleContinue = async (type) => {
    try {
      setIsLoading(true);
      var checkCollection = await createCollection();
      if (checkCollection?.isCreated === true) {
        navigate(`/batch/session/${sessionID}`);
        return;
      }
      const provider = await connector?.getProvider();
      const web3 = new Web3(provider);

      const contract = new web3.eth.Contract(
        CreateCollectionABI,
        "0x1aF7768737e41D227Fd0f6330Ed7B0ad846A8B73"
      );

      const totalGas = await contract.methods
        .createCollection(wallet, sessionID, collectionName, symbolName)
        .estimateGas({ from: wallet });
      const gasPrice = await web3.eth.getGasPrice();

      const tx = await contract.methods
        .createCollection(wallet, sessionID, collectionName, symbolName)
        .send({ from: wallet, gas: totalGas.toString(), gasPrice: gasPrice });

      if (tx && tx.events) {
        var uploadRes = await createCollection();
        if (uploadRes.error) {
          console.log(uploadRes.error);
          setError("Unable to create collection. Please retry!");
          setcollectionName("");
          setsymbolName("");
          setIsLoading(false);
        } else {
          navigate(`/batch/session/${sessionID}`);
          setIsLoading(false);
        }
      }
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
      // console.log(e.message);
    }
  };

  const createCollection = async () => {
    const data = {
      wallet,
      sessionID,
    };
    const uploadCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/batch/collection/create`,
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const uploadRes = await uploadCall.json();
    return uploadRes;
  };

  useEffect(() => {
    if (!sessionID) {
      window.location.reload();
    }
  }, [sessionID]);

  return (
    <section className="select  flex h-screen w-full items-end">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-5xl text-4xl font-black uppercase">
            Create
            <br className="hidden md:block" />
            Collection
          </h1>

          {!isLoading ? (
            <div className="options__container pt-2">
              {error ? (
                <div className="flex font-semibold text-[#333333] items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                  <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                  <p>{error}</p>
                </div>
              ) : null}
              <div>
                <p className=" pt-2 font-semibold text-[#333333] ">Name*</p>
                <input
                  type="text"
                  name="collectionName"
                  id="collectionName"
                  value={collectionName}
                  onChange={(e) => setcollectionName(e.target.value)}
                  placeholder="Enter a personal collection name"
                  className="w-full border-2 border-solid border-[#cccccc] p-2 px-2 rounded-md my-2 text-sm"
                />
              </div>
              <div>
                <p className=" pt-2 font-semibold text-[#333333] ">Symbol*</p>
                <input
                  type="text"
                  name="symbolName"
                  id="symbolName"
                  value={symbolName}
                  onChange={(e) => setsymbolName(e.target.value)}
                  placeholder="Enter your sybmbol name"
                  className="w-full border-2 border-solid border-[#cccccc] p-2 px-2 rounded-md my-2 text-sm"
                />
              </div>
            </div>
          ) : (
            <div>
              {/* <img src={} alt="" /> */}
              <div className="text-center my-4">
                <ClipLoader size={60} color="#3BBEAE" />
              </div>
              <p className="text-center font-semibold">
                Creating Collection... <br /> Please don't close this window.{" "}
              </p>
            </div>
          )}
        </div>
        {!isLoading && (
          <div className="button__container text-center">
              <button
              onClick={() => navigate("/profile")}
              className="bg-[#3BBEAE] w-full px-5 py-4 my-3 rounded-full text-white font-semibold text-center"
              disabled={!sessionID}
            >
              Save as Draft
            </button>
            <button
              onClick={handleContinue}
              className="bg-[#3BBEAE] w-full px-5 py-4 my-3 rounded-full text-white font-semibold text-center"
              disabled={!collectionName || !symbolName || !sessionID}
            >
              Create Collection
            </button>
            <p onClick={() => navigate(-1)} className="cursor-pointer">
              Back
            </p>
          </div>
        )}
      </ContentBlock>
      <Assistant />
    </section>
  );
};

export default CreateCollection;
