import BuildBlock from "../../../Components/BuildBlock/BuildBlock";
import twitterBig from "../../../assets/images/twitterBig.svg";
import discordBig from "../../../assets/images/discordBig.svg";
import BuildNftImage from "../../../Components/BuildNftImage/BuildNftImage";
import BuildNftDetails from "../../../Components/BuildNftDetails/BuildNftDetails";
import { useEffect, useState } from "react";
import { useGCtx } from "../../../Contexts/GlobalContext";
import AutographedImage from "../../../Components/AutographedImage/AutographedImage";
import domtoimage from "dom-to-image";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import alertCircle from "../../../assets/images/alertCircle.svg";

const AutographModule = () => {
  const [selectedOption, setselectedOption] = useState(null);
  const {
    wallet,
    setAutographedText,
    txhash,
    autographedText,
    assetPreview,
    setAutographedImage,
    autographedImage,
    networkType,
    nftData,
    dehiddenModules,
    setDehiddenModules,
  } = useGCtx();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleOptionSelection = (name) => {
    setselectedOption(name);
    if (name.toLowerCase() === "twitter") {
      authTwitter();
    } else {
      authDiscord();
    }
  };

  useEffect(() => {
    if (error?.length) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);

  const verifyAuth = async () => {
    const verifyCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/dehidden/autograph/info?txhash=${txhash}`,
      { method: "GET" }
    );

    const verifyRes = await verifyCall.json();
    if (verifyRes.twitterID || verifyRes.discordID) {
      setError("");
      if (selectedOption === "twitter") {
        setAutographedText(verifyRes.twitterID);
      } else if (selectedOption === "discord") {
        setAutographedText(verifyRes.discordID);
      }
    } else {
      setAutographedText("");
      setselectedOption("");
      setError("Authentication Failed");
    }
  };

  const authTwitter = async () => {
    const openWindow = window.open(
      `${process.env.REACT_APP_BASE_URL}/dehidden/autograph/auth/twitter?txhash=${txhash}&redirect_uri=${process.env.REACT_APP_SOCIAL_REDIRECT}`
    );

    setTimeout(() => {
      openWindow.close();
    }, 15000);
  };

  const authDiscord = async () => {
    const openWindow = window.open(
      `${process.env.REACT_APP_BASE_URL}/dehidden/autograph/auth/discord?txhash=${txhash}&redirect_uri=${process.env.REACT_APP_SOCIAL_REDIRECT}`
    );
    setTimeout(() => {
      openWindow.close();
    }, 15000);
  };

  const handleConfirmAutograph = async () => {
    setIsLoading(true);
    var item = document.querySelector(".image__container__signed");
    try {
      const blob = await domtoimage.toBlob(item);

      var file = new File([blob], "signed" + assetPreview.fileInfo.name, {
        type: "image/*",
      });
      setAutographedImage(file);
      if (file) {
        const newData = new FormData();
        const metadata = {
          name: nftData.name,
          description: nftData.description,
          network: networkType === "137" ? "mainnet" : "mumbai",
        };
        newData.append("image", file);
        newData.append("metadata", JSON.stringify(metadata));
        try {
          const uploadCall = await fetch(
            `${process.env.REACT_APP_BASE_URL}/dehidden/autograph/upload`,
            {
              method: "POST",
              body: newData,
            }
          );

          const uploadRes = await uploadCall.json();
          if (uploadRes.ipfs) {
            const bodyData = {
              wallet,
              metadata: uploadRes.ipfs,
              txhash,
            };

            try {
              const mintCall = await fetch(
                `${process.env.REACT_APP_BASE_URL}/dehidden/autograph/generate`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(bodyData),
                }
              );

              const mintRes = await mintCall.json();

              if (mintRes.tx) {
                setDehiddenModules({ ...dehiddenModules, autograph: true });
                setIsLoading(false);
              } else {
                setIsLoading(false);
                setError(
                  "Oops, we were unable to mint your autographed image! Please try again."
                );
                console.log("Failed");
              }
            } catch (e) {
              console.log(e);
            }
          }
        } catch (e) {
          console.log(e);
          setError(
            "Oops, we were unable to mint your autographed image! Please try again."
          );
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);

        setError(
          "Oops, we were unable to mint your autographed image! Please try again."
        );
      }
    } catch (error) {
      setIsLoading(false);

      console.log("oops, something went wrong!", error);
      setError(
        "Oops, we were unable to mint your autographed image! Please try again."
      );
    }
  };

  return (
    <section className=" flex h-[100%] md:h-screen w-full">
      <BuildBlock>
        <div className="w-full mx-auto h-auto  md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
          {/* <div className="mockup__container"> */}
          {!autographedText ? (
            <>
              <BuildNftImage />
              <BuildNftDetails />
            </>
          ) : (
            <AutographedImage />
          )}

          {/* </div> */}
        </div>
        <div className="w-full md:h-[100%] md:w-[60%] p-4 flex flex-col justify-between md:overflow-y-auto">
          <div>
            <h2 className="text-4xl font-black md:text-6xl uppercase pb-5">
              ✍️ Autograph mY nft
            </h2>
            <p className="pb-4 font-semibold text-3xl">
              {dehiddenModules.autograph
                ? ""
                : "Select the medium you want to autograph your NFT with."}
            </p>
            {isLoading ? (
              <div className="options grid items-center font-semibold">
                <div>
                  {/* <img src={} alt="" /> */}
                  <div className="text-center my-4">
                    <ClipLoader size={60} color="#3BBEAE" />
                  </div>

                  <p className="text-center font-semibold">
                    Minting... <br /> Please don't close this window.
                  </p>
                </div>
              </div>
            ) : !autographedText && !selectedOption ? (
              <div className="options font-semibold text-[#333333]">
                <p>Choose an option</p>
                <div className="flex my-3 gap-[30px]">
                  <AutographOption
                    name={"Twitter"}
                    handleOptionSelection={handleOptionSelection}
                    icon={twitterBig}
                    selectedOption={selectedOption}
                    isVerification={false}
                  />
                  <AutographOption
                    name={"Discord"}
                    handleOptionSelection={handleOptionSelection}
                    icon={discordBig}
                    selectedOption={selectedOption}
                    isVerification={false}
                  />
                </div>
                {error ? (
                  <div className="options font-semibold text-[#333333] flex items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                    <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                    <p>{error}</p>
                  </div>
                ) : null}
              </div>
            ) : !dehiddenModules.autograph ? (
              <div className="options font-semibold text-[#333333]">
                {selectedOption === "discord" ? (
                  <AutographOption
                    name={"Discord"}
                    handleOptionSelection={verifyAuth}
                    icon={discordBig}
                    selectedOption={selectedOption}
                    isVerification={true}
                    autographedText={autographedText}
                  />
                ) : (
                  <AutographOption
                    name={"Twitter"}
                    handleOptionSelection={verifyAuth}
                    icon={twitterBig}
                    selectedOption={selectedOption}
                    isVerification={true}
                    autographedText={autographedText}
                  />
                )}
                {error ? (
                  <div className="options font-semibold text-[#333333] flex items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                    <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                    <p>{error}</p>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="options font-semibold text-[#333333] w-full h-[300px] text-center border rounded-xl grid items-center">
                <div className="text-center">
                  <img
                    src={selectedOption === "discord" ? discordBig : twitterBig}
                    className="h-24 mb-3 mx-auto"
                    alt=""
                  />
                  <p className="text-xl md:text-2xl">
                    You have successfully <br /> autographed your NFT!
                  </p>
                </div>
              </div>
            )}
          </div>

          {!dehiddenModules.autograph ? (
            <div className="steps__wrapper mt-3">
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
                onClick={handleConfirmAutograph}
                disabled={!autographedText || isLoading}
              >
                Confirm Autograph
              </button>
              <p className="text-center pt-3" onClick={() => navigate(-1)}>
                Back
              </p>
            </div>
          ) : (
            <div className="steps__wrapper mt-3">
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          )}
        </div>
      </BuildBlock>
    </section>
  );
};

export default AutographModule;

const AutographOption = ({
  icon,
  handleOptionSelection,
  name,
  selectedOption,
  isVerification,
  autographedText,
}) => {
  return (
    <div
      className={
        selectedOption === name.toLowerCase() && autographedText
          ? "border h-[150px] w-[150px] rounded-md text-[#ffffff] border-[#3BBEAE] cursor-pointer"
          : "border h-[150px] w-[150px] rounded-md cursor-pointer"
      }
      onClick={() => handleOptionSelection(name.toLowerCase())}
    >
      <div className="item__image h-[110px] grid items-center">
        <img src={icon} alt="" className="mx-auto h-14 " />
      </div>
      <div
        className={
          selectedOption === name.toLowerCase() && autographedText
            ? "item__foot h-[40px] grid items-center text-center bg-[#3BBEAE] font-semibold rounded-b-md "
            : "item__foot h-[40px] grid items-center text-center bg-[#EDEDED] font-semibold rounded-b-md"
        }
      >
        {isVerification ? <p>Verify {name}</p> : <p>{name} Sign In</p>}
      </div>
    </div>
  );
};
