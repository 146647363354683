import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import BuildBlock from "../../../Components/BuildBlock/BuildBlock";
import BuildNftDetails from "../../../Components/BuildNftDetails/BuildNftDetails";
import BuildNftImage from "../../../Components/BuildNftImage/BuildNftImage";
import { useGCtx } from "../../../Contexts/GlobalContext";
import { validateUrl } from "../../../utils";
import checkCirclePurple from "../../../assets/images/checkCirclePurple.svg";
import alertCircle from "../../../assets/images/alertCircle.svg";

const UnlockableModule = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { wallet, setDehiddenModules, dehiddenModules, txhash } = useGCtx();
  useEffect(() => {
    if (error?.length) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);
  const updateUnlockableContent = async () => {
    if (!validateUrl(content)) {
      setError(
        "Invalid URL. Please provide a valid URL (example https://mintnft.today)"
      );
      return;
    }
    setError("");
    setIsLoading(true);
    const data = {
      wallet,
      content,
      txhash,
    };

    try {
      const unlockableContentCall = await fetch(
        `${process.env.REACT_APP_BASE_URL}/dehidden/unlockable/token`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const unlockableContentResponse = await unlockableContentCall.json();

      if (unlockableContentResponse) {
        setError("");
        setIsLoading(false);
        setDehiddenModules({ ...dehiddenModules, unlockableContent: true });
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <section className=" flex h-[100%] md:h-screen w-full">
      <BuildBlock>
        <div className="w-full mx-auto h-auto  md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
          {/* <div className="mockup__container"> */}
          <BuildNftImage />
          <BuildNftDetails />
          {/* </div> */}
        </div>
        <div className="w-full md:h-[100%] md:w-[60%] p-4 flex flex-col justify-between md:overflow-y-auto">
          <div>
            <h2 className="text-4xl font-black md:text-6xl uppercase pb-5">
              Unlockable content
            </h2>
            <p className="pb-4 font-semibold text-3xl">
              Enter the URL the NFT holder can unlock
            </p>
            {isLoading ? (
              <div className="options grid items-center font-semibold">
                <div>
                  {/* <img src={} alt="" /> */}
                  <div className="text-center my-4">
                    <ClipLoader size={60} color="#3BBEAE" />
                  </div>

                  <p className="text-center font-semibold">
                    Updating... <br /> Please don't close this window.
                  </p>
                </div>
              </div>
            ) : !dehiddenModules.unlockableContent ? (
              <div className="options font-semibold text-[#333333]">
                {error ? (
                  <div className="options font-semibold text-[#333333] flex items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                    <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                    <p>{error}</p>
                  </div>
                ) : null}
                <div className={"w-full form-group mt-4"}>
                  <label htmlFor="name" className="font-semibold pb-1">
                    URL*
                  </label>
                  <input
                    type="text"
                    name="content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    id="content"
                    placeholder="Enter unlockable content URL"
                    className="w-full border-2 border-solid border-[#cccccc] p-2 rounded-md my-2"
                    required
                  />
                </div>
                {/* {error ? (
                  <div className="options font-semibold text-[#333333] grid items-center w-full py-3 text-center">
                    <p>{errorr}</p>
                  </div>
                ) : null} */}
              </div>
            ) : (
              <div className="options font-semibold text-[#333333] w-full h-[300px] text-center border rounded-xl grid items-center">
                <div className="text-center">
                  <img
                    src={checkCirclePurple}
                    className="h-24 mb-3 mx-auto"
                    alt=""
                  />
                  <p>Unlockable content added!</p>
                </div>
              </div>
            )}
          </div>

          {!dehiddenModules.unlockableContent ? (
            <div className="steps__wrapper mt-3">
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
                onClick={updateUnlockableContent}
                disabled={!content || isLoading}
              >
                Update unlockable content
              </button>
              <p className="text-center pt-3" onClick={() => navigate(-1)}>
                Back
              </p>
            </div>
          ) : (
            <div className="steps__wrapper mt-3">
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
                onClick={() => navigate("/dehidden/add-utilities")}
              >
                Go Back
              </button>
            </div>
          )}
        </div>
      </BuildBlock>
    </section>
  );
};

export default UnlockableModule;
