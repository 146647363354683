import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Assistant from "../../Components/Assitant/Assistant";

import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import OptionItem from "../../Components/OptionItem/OptionItem";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";

import { useGCtx } from "../../Contexts/GlobalContext";
import closeIcon from "../../assets/images/closeIcon.png";

const CreateRestore = () => {
  const [extended, setExtended] = useState("create");
  const { callAssistant, setAssistantText, setBatchSessionType } = useGCtx();
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  // useEffect(() => {
  //   document.addEventListener("click", (e) => {
  //     const alertDiv = document.querySelector("#alert-pop");

  //     if (
  //       (showAlert && e.target.id === "alert-pop") ||
  //       alertDiv.contains(e.target)
  //     ) {
  //       return;
  //     } else {
  //       setShowAlert(false);
  //     }
  //   });
  // });

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(true);
    }, 500);
  }, []);

  const handleClickExtention = (e, type) => {
    setExtended(type);
    if (e.target.tagName === "BUTTON") {
      return;
    }
    if (type === "create") {
      setTimeout(() => {
        callAssistant(
          "Mint up to 10,000 NFTs in one go. Gas fees are applicable."
        );
      }, 100);
    } else if (type === "restore") {
      setTimeout(() => {
        callAssistant("Continue where you left");
      }, 100);
    }
  };
  const handleContinue = (type) => {
    setBatchSessionType(type);
    setAssistantText("");
    if (type === "create") {
      navigate("/batch-upload");
    } else {
      navigate("/profile");
    }
  };

  return (
    <section className="select  flex h-screen w-full items-end relative">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-6xl text-4xl font-black uppercase">
            Create/ <br className="hidden md:block" />
            Restore <br className="hidden md:block" />A session{" "}
          </h1>
          <p
            className="mt-2 cursor-pointer rounded-lg p-2 border bg-[#fefefe] font-semibold text-sm"
            onClick={() => setShowAlert(true)}
          >
            <span className="mr-2">ⓘ</span>
            How to Batch Mint your NFT's?{" "}
          </p>
          <div className="options__container pt-2">
            <OptionItem
              heading="Create New Session"
              description="Create a new batch mint session"
              buttonText="Continue"
              isExtended={extended === "create"}
              type={"create"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            />
            {/* <OptionItem
              heading="Badges"
              description="lorem ipsum dolor sit amet,consectertur adiplcing"
              buttonText="Continue"
              isExtended={extended === "badges"}
              type={"badges"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            /> */}
            <OptionItem
              heading="Restore a Session"
              description="Choose from existing sessions"
              buttonText="Continue"
              isExtended={extended === "restore"}
              type={"restore"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            />
          </div>
        </div>
        <div className="progress">
          <ProgressBar phase={1} percentage={50} />
        </div>
      </ContentBlock>
      <Assistant />
      <div
        className={
          showAlert
            ? " fade-in duration-200 box-shadow-block absolute rounded-xl p-6 top-[50%] left-[50%] w-[95%] lg:w-[900px] bg-white z-10 translate-x-[-50%] translate-y-[-50%] "
            : " transition-transform duration-200 scale-0  box-shadow-block absolute rounded-xl p-6 top-[50%] left-[50%] w-[95%] lg:w-[900px] bg-white z-10 translate-x-[-50%] translate-y-[-50%] "
        }
        id="alert-pop"
      >
        <img
          src={closeIcon}
          alt=""
          className="absolute top-5 right-5 h-6 cursor-pointer"
          onClick={() => setShowAlert(false)}
        />
        <p className="font-black text-xl pt-2 gt-w">
          How to Batch Mint your NFTs: (
          <a
            href="https://app.usebubbles.com/6XSPfg1un3x96cAX7hvWoK/mint-nft-batch-mint-demo"
            target={"blank"}
            rel="noreferrer"
            className="text-[#7451df]"
          >
            Demo
          </a>
          )
        </p>
        <ol className="list-decimal pl-4 mt-2">
          <li>Create a new session</li>
          <li>
            Select all the assets you want to mint. Make sure all files follow
            the naming convention 0.png, 1.png, … 1000.png
          </li>
          <li>
            Select your NFT thumbnails (preview images). Ensure the same naming
            convention is followed.
          </li>
          <li>
            Attach your metadata file as single .csv (
            <a
              href="/samples/sample.csv"
              target={"blank"}
              rel="noreferrer"
              className="text-[#7451df]"
            >
              Sample
            </a>
            ) or .json (
            <a
              href="/samples/sample.json"
              target={"blank"}
              rel="noreferrer"
              className="text-[#7451df]"
            >
              Sample
            </a>
            ) file. Please follow the OpenSea Metadata Standards (
            <a
              href="https://docs.opensea.io/docs/metadata-standards"
              className="text-[#7451df]"
            >
              https://docs.opensea.io/docs/metadata-standards
            </a>
            )
          </li>
          <li>
            Click on the <i>Continue</i> button to create your session
          </li>
          <li>
            Once the session is created now you need to deploy your own
            collection contract so that all the NFTs minted comes under this
            deployed collection in OpenSea. Enter a Name and Symbol for your
            Collection Contract and deploy it
          </li>
          <li>
            Your session is ready & you will be taken to the session page to
            continue with the upload & minting process.
          </li>
        </ol>
        <p className="font-black text-xl pt-2">Note:</p>
        <ul className="list-disc pl-4 mt-2">
          <li>Please strictly follow the naming convention for your files</li>
          <li>Ensure each file is below 100MB</li>
          <li>Please make sure you follow the OpenSea Metadata Standard</li>
          <li>
            This is not a Gasless Minting experience. Gas fees will be
            applicable.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default CreateRestore;
