import { useNavigate } from "react-router-dom";
import BuildBlock from "../../Components/BuildBlock/BuildBlock";
import BuildNftDetails from "../../Components/BuildNftDetails/BuildNftDetails";
import BuildNftImage from "../../Components/BuildNftImage/BuildNftImage";
import { useGCtx } from "../../Contexts/GlobalContext";
// import mintnft from "../../assets/images/mintNFTC.svg";
// import cope from "../../assets/images/copeC.png";
// import dehidden from "../../assets/images/dehiddenC.png";
import { globalNFT } from "../../connectors";
import homeIcon from "../../assets/images/homeIcon.svg";
import profileIcon from "../../assets/images/user.svg";
import shareIcon from "../../assets/images/shareIcon.svg";
import { useEffect } from "react";

const Share = () => {
  const navigate = useNavigate();
  const {
    resetState,
    mintedTokenId,
    dehiddenModules,
    erc721Type,
    nftType,
    nftData,
  } = useGCtx();
  useEffect(() => {
    if (!nftData.name) {
      navigate("/profile");
    }
  }, [nftData]);

  var contractAddresses = [
    globalNFT.ERC721.address,
    globalNFT.SoulBound.address,
    globalNFT.ERC1155.address,
    globalNFT.Autograph.address,
  ];

  const getContractToUse = () => {
    if (dehiddenModules?.isAutographedItem) {
      return contractAddresses[3];
    } else if (nftType.toLowerCase() === "erc721") {
      if (erc721Type.toLowerCase() === "721only") {
        return contractAddresses[0];
      } else {
        return contractAddresses[1];
      }
    } else if (nftType.toLowerCase() === "erc1155") {
      return contractAddresses[2];
    }
  };

  return (
    <section className=" flex h-[100%] md:h-screen w-full pt-12 md:pt-0">
      <BuildBlock>
        <div className="w-full mx-auto h-auto  md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
          {/* <div className="mockup__container"> */}
          <BuildNftImage />
          <BuildNftDetails />
          {/* </div> */}
        </div>
        <div className="w-full md:h-[100%] md:w-[60%] p-4 flex flex-col justify-center md:overflow-y-auto">
          <div>
            <h2 className="text-4xl font-black md:text-6xl uppercase pb-5 text-center">
              You’VE SUCCESSFULLY mINTED YOUR nft
            </h2>
            <div className="buttons__wrapper">
              <button
                className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
                onClick={() => navigate("/create-claim-page")}
              >
                Create Claim Page
              </button>
              {nftType === "ERC721" && (
                <button
                  className="border border-1 border-black font-semibold text-center rounded-full  p-3 w-full mt-4 "
                  onClick={() => navigate("/dehidden/add-utilities")}
                >
                  🎛 Add Utilities
                </button>
              )}
            </div>
            <div className="flex gap-6 items-center justify-center mt-6">
              <div
                className="rounded-full cursor-pointer h-14 w-14 flex justify-center items-center bg-[#EEEFF2]"
                onClick={() =>
                  window.open(
                    `https://twitter.com/share?text=I just minted an NFT on MintNFT platform by Polygon. Check it out here on https://mintnft.today/view/matic/${getContractToUse()}/${mintedTokenId}. Now you can mint it too `
                  )
                }
              >
                <img src={shareIcon} alt="" className="h-6" />
              </div>
              <div
                className="rounded-full cursor-pointer h-14 w-14 flex justify-center items-center bg-[#EEEFF2]"
                onClick={() => (window.location.href = "/")}
              >
                <img src={homeIcon} alt="" className="h-6" />
              </div>
              <div
                className="rounded-full cursor-pointer h-14 w-14 flex justify-center items-center bg-[#EEEFF2]"
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <img src={profileIcon} alt="" className="h-6" />
              </div>
            </div>
          </div>
          {/* <div className="self-center md:self-end mt-4 md:mt-auto">
            <div
              className="collab bg-[#a973ef85] rounded-2xl flex items-center p-4 py-6 w-full md:w-[380px] justify-between"
              style={{ backdropFilter: "blur(10px)" }}
            >
              <a
                href="https://polygon.technology"
                target="_blank"
                rel="noreferrer"
              >
                <img src={mintnft} alt="" className="h-16" />
              </a>
              <div className="imggroup ml-4 text-center">
                <p className=" text-sm py-1 font-semibold">
                  In collaboration with
                </p>
                <div className="flex items-center justify-center gap-2">
                  <a
                    href="https://cope.studio"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={cope} alt="" className="h-14" />
                  </a>
                  <a
                    href="https://dehidden.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={dehidden} alt="" className="h-14" />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </BuildBlock>
    </section>
  );
};

export default Share;

// <button
//                 className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
//                 onClick={() =>
//                   window.open(
//                     `https://twitter.com/share?text=I just minted an NFT on MintNFT platform by Polygon. Check it out here on https://mintnft.today/view/matic/${getContractToUse()}/${mintedTokenId}. Now you can mint it too `
//                   )
//                 }
//               >
//                 Share on Social Media
// </button>
