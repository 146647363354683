import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Assistant from "../../Components/Assitant/Assistant";
import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import OptionItem from "../../Components/OptionItem/OptionItem";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";

import { useGCtx } from "../../Contexts/GlobalContext";

const NftType = () => {
  const [extended, setExtended] = useState("erc721");
  const navigate = useNavigate();
  const { setNftType, callAssistant, setAssistantText, setERC721Type } = useGCtx();
  const handleClickExtention = (e, type) => {
    setExtended(type);
    if (e.target.tagName === "BUTTON") {
      return;
    }
    if (type === "erc1155") {
      setTimeout(() => {
        callAssistant(
          "Use ERC 1155 if your NFT can be held by multiple wallets"
        );
      }, 100);
    } else if (type === "erc721") {
      setTimeout(() => {
        callAssistant(
          "Use ERC 721 if your NFT needs to be in just one wallet at a time. This will allow you to add utilities to your NFT"
        );
      }, 100);
    }
  };
  const handleContinue = (type) => {
    setNftType(type);
    setAssistantText("");
    if (type === "erc721") {
      setERC721Type("721only");
      navigate("/collection-type");
    } else {
      navigate("/collection-type");
    }
  };

  return (
    <section className="select  flex h-screen w-full items-end">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-6xl text-4xl font-black uppercase">
            Choose <br className="hidden md:block" />
            Your NFT <br className="hidden md:block" />
            Type
          </h1>
          <div className="options__container pt-2">
            <OptionItem
              heading="🖼 ERC 721"
              description="NFTs minted using this standard are unique and can only be owned by one wallet at a time"
              buttonText="Continue"
              isExtended={extended === "erc721"}
              type={"erc721"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            />
            <OptionItem
              heading="🪆 ERC 1155"
              description="NFTs minted using this standard can have multiple copies and can be owned by multiple wallets"
              buttonText="Continue"
              isExtended={extended === "erc1155"}
              type={"erc1155"}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            />
          </div>
        </div>
        <div className="progress">
          <ProgressBar phase={1} percentage={45} />
        </div>
      </ContentBlock>
      <Assistant />
    </section>
  );
};

export default NftType;
