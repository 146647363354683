import BuildBlock from "../../../Components/BuildBlock/BuildBlock";
import BuildNftDetails from "../../../Components/BuildNftDetails/BuildNftDetails";
import BuildNftImage from "../../../Components/BuildNftImage/BuildNftImage";
import checkCircle from "../../../assets/images/checkCircle.svg";
import { useEffect, useState } from "react";
import { useGCtx } from "../../../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";

const AddUtilities = () => {
  const [activeItem, setActiveItem] = useState("autograph");
  const navigate = useNavigate();
  const { erc721Type, dehiddenModules, uploadedFile } = useGCtx();
  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  useEffect(() => {
    if (dehiddenModules?.isAutographedItem) {
      setActiveItem("phygital");
    }
  }, []);

  const getFileType = (file) => {
    return file["type"].split("/")[0];
  };

  const shouldAutograph = () => {
    if (uploadedFile?.fileInfo && uploadedFile?.fileInfo.name) {
      return (
        !dehiddenModules.airdrop &&
        !dehiddenModules?.isAutographedItem &&
        erc721Type !== "soulbounds" &&
        getFileType(uploadedFile.fileInfo) === "image"
      );
    } else {
      return (
        !dehiddenModules.airdrop &&
        !dehiddenModules?.isAutographedItem &&
        erc721Type !== "soulbounds"
      );
    }
  };

  return (
    <section className=" flex h-[100%] md:h-screen w-full pt-12 md:pt-0">
      <BuildBlock>
        <div className="w-full mx-auto h-auto  md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
          {/* <div className="mockup__container"> */}
          <BuildNftImage />
          <BuildNftDetails />
          {/* </div> */}
        </div>
        <div className="w-full md:h-[100%] md:w-[60%] p-4 flex flex-col justify-between md:overflow-y-auto">
          <div>
            <h2 className="text-4xl font-black md:text-6xl uppercase pb-5">
              Add Utilities
            </h2>

            {shouldAutograph() ? (
              <UtilityItem
                itemTitle={"✍️ Autograph Module"}
                itemDescription="Mint a copy with your autograph to personalize your ownership"
                btnText={dehiddenModules.autograph ? "Added" : "Continue"}
                handleItemClick={handleItemClick}
                isExpanded={activeItem === "autograph"}
                item="autograph"
                isCompleted={dehiddenModules.autograph}
                handleButtonClick={() => navigate("/dehidden/autograph")}
              />
            ) : null}
            {/* {!dehiddenModules.airdrop ? (
              <UtilityItem
                itemTitle={"🍀 Order a Phygital"}
                itemDescription="Get a frame with your NFT"
                btnText={dehiddenModules.phygital ? "Added" : "Continue"}
                handleItemClick={handleItemClick}
                isExpanded={activeItem === "phygital"}
                item="phygital"
                isCompleted={dehiddenModules.phygital}
                handleButtonClick={() => navigate("/dehidden/phygital")}
              />
            ) : null} */}
            {erc721Type !== "soulbounds" ? (
              <UtilityItem
                itemTitle={"🎁 Send as a gift"}
                itemDescription="Airdrop this NFT to someone"
                btnText={dehiddenModules.airdrop ? "Added" : "Continue"}
                handleItemClick={handleItemClick}
                isExpanded={activeItem === "gift"}
                item="gift"
                isCompleted={dehiddenModules.airdrop}
                handleButtonClick={() => navigate("/dehidden/airdrop")}
              />
            ) : (
              ""
            )}
            {!dehiddenModules.airdrop ? (
              <UtilityItem
                itemTitle={"🔑 Unlockable content"}
                itemDescription="Add unlockable content to your NFT"
                btnText={
                  dehiddenModules.unlockableContent ? "Added" : "Continue"
                }
                handleItemClick={handleItemClick}
                isExpanded={activeItem === "unlockable"}
                item="unlockable"
                isCompleted={dehiddenModules.unlockableContent}
                handleButtonClick={() =>
                  navigate("/dehidden/unlockable-content")
                }
              />
            ) : null}
          </div>

          <div className="steps__wrapper mt-3">
            {/* <button
              className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
              disabled={
                !dehiddenModules.autograph &
                !dehiddenModules.phygital &
                !dehiddenModules.unlockableContent &
                !dehiddenModules.airdrop
              }
              onClick={() => navigate("/share")}
            >
              Confirm Utilities
            </button> */}
            <button
              className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
              onClick={() => navigate("/share")}
            >
              Share
            </button>
            <p
              className="text-center pt-3 cursor-pointer"
              onClick={() => navigate("/profile")}
            >
              Back to Profile
            </p>
          </div>
        </div>
      </BuildBlock>
    </section>
  );
};

export default AddUtilities;

export const UtilityItem = ({
  itemTitle,
  itemDescription,
  btnText,
  handleItemClick,
  item,
  isCompleted,
  isExpanded,
  isSkipable,
  handleButtonClick,
}) => {
  return (
    <div
      className={
        isExpanded ? "rounded-xl p-5 py-8 bg-[#fefefe] my-4" : "p-5 py-0"
      }
      onClick={() => handleItemClick(item)}
    >
      <p
        className={
          isExpanded
            ? "font-black text-2xl md:text-3xl pb-1 flex items-center"
            : "text-[#00000079] font-black text-2xl md:text-3xl pb-1 flex items-center"
        }
      >
        {isCompleted ? (
          <img src={checkCircle} alt="" className="mr-2 h-6" />
        ) : null}{" "}
        {itemTitle}
      </p>
      {isExpanded && (
        <div className={isExpanded ? "extended2" : "not-extended"}>
          <p className="text-[20px] md:text-[24px]">{itemDescription}</p>
          <button
            className="bg-black font-semibold text-center rounded-full text-white p-3 w-full mt-4"
            onClick={handleButtonClick}
            disabled={isCompleted}
          >
            {btnText}
          </button>
          {isSkipable ? <p className="text-center pt-2">Back</p> : null}
        </div>
      )}
    </div>
  );
};
