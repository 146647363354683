import { useEffect, useState } from "react";
import BuildBlock from "../../Components/BuildBlock/BuildBlock";
import checkCircle from "../../assets/images/checkCircle.svg";

import BuildNftImage from "../../Components/BuildNftImage/BuildNftImage";
import { useGCtx } from "../../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { refactorAttributes } from "../../utils";
import BuildNftDetails from "../../Components/BuildNftDetails/BuildNftDetails";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { useAccount } from "wagmi";
import Web3 from "web3";


import alertCircle from "../../assets/images/alertCircle.svg";
import GasTankContract from "../../assets/ABI/GasTankABIMainnet.json";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "http://54.236.202.110:5000";
// const BASE_URL = "https://mintnft-staging.herokuapp.com";

const BuildNFT = () => {
  const [activeItem, setActiveItem] = useState("details");
 const [gasAvailable, setGasAvailable] = useState(false);
 const [errorMsg, setErrorMsg] = useState(false);
 const [processing, setProcessing] = useState(false);
  
 const [gasFill, setGasFill] = useState(0.5);
 const [popUp, setPopUp] = useState(false);
  const { connector } = useAccount();

  const {
    nftData,
    assetPreview,
    uploadedFile,
    wallet,
    nftType,
    networkType,
    setNftData,
    erc721Type,
    setIsUploading,
    setIsMinting,
    setIsMinted,
    attributes,
    setIpfsHash,
    setTxhash,
    setIsPending,
    setMintedTokenId,
    collection,
    setCollection,
    resetState,
  } = useGCtx();

 
  const navigate = useNavigate();
  const handleItemClick = (item) => {
    setActiveItem(item);
  };


  const handleContinue = (item) => {
    item === "details"
      ? navigate("/add-nft-details")
      : navigate("/additional-properties");
  };

  useEffect(() => {
    if(collection){
      FetchGasTank()
      if(collection && collection === true){
        fetchCustomContracts()
      }
    }
  }, [collection])
  
  const fetchCustomContracts = async() => {
    try{
      const data = await fetch(`${process.env.REACT_APP_BASE_URL}/v2/collection/user/${wallet}`,{
        method:"GET",
        headers:{
          "x-api-key":process.env.REACT_APP_API_KEY,
          "x-auth-token": process.env.REACT_APP_API_KEY,
        }
      })
      const response = await data.json()
      let contracts = response.data?.filter((item) => item.nftType?.toLowerCase() === nftType)
      console.log(contracts,contracts[contracts.length - 1]?.collectionAddress)
      setCollection(contracts[contracts.length - 1]?.collectionAddress)
    }
    catch(error){
      console.log(error)
    }
  }

  const FetchGasTank = async () => {
    try{
      const data = await fetch(`${BASE_URL}/api/gasTankBalance/${wallet}`,{
        method:"GET",
        headers:{
          "x-api-key": process.env.REACT_APP_API_KEY,
        }
      })
      const response = await data.json();
      if(response.status === true){
        const convertToEth = Web3.utils.fromWei(response.balance?.toString(), "ether");
        console.log(convertToEth && convertToEth?.slice(0,5))
        setGasAvailable(convertToEth && convertToEth?.slice(0,5))
        setErrorMsg(false)
      }
      setErrorMsg(false)
    }
    catch(error){
      console.log(error)
      setErrorMsg(false)
    }
  }
  
  const FillGas = async(amount) => {
    try{
      setProcessing(true)
      const provider = await connector?.getProvider();
      const web3 = new Web3(provider);
     
      const contract = new web3.eth.Contract(
       GasTankContract?.GasTank?.abi,
        GasTankContract?.GasTank?.address
      );
      const totalGas = await contract.methods
        .depositGas()
        .estimateGas({ from: wallet, value:  web3.utils.toWei(amount, "ether") });
      const gasPrice = await web3.eth.getGasPrice();
      const tx = await contract.methods
      .depositGas()
      .send({ from: wallet, gas: totalGas.toString(), gasPrice: gasPrice, value:  web3.utils.toWei(amount, "ether") });

     if (tx && tx.events) {
        setProcessing(false)
        console.log(tx.events)
        setPopUp(false)
        FetchGasTank()
        setErrorMsg(false)
     }
     setProcessing(false)
    }
    catch(error){
      console.log(error)
      setErrorMsg(false)
      setProcessing(false)
    }
  }



  const uploadAssetsCustom = async () => {
    window.gtag("event", "mint-click", {
      event_category: "mint",
      event_label: nftType.toUpperCase(),
    });
    navigate("/mint");
    const metadata = {
      name: nftData.name,
      description: nftData.description,
      external_link: nftData.external_link,
      attributes: refactorAttributes(attributes),
    };

    const image = assetPreview.fileInfo;
    const asset = uploadedFile.fileInfo;

    // const previewFileObject = new File([image], assetPreview.fileInfo.name, {
    //   type: assetPreview.fileInfo.type,
    // });
    // const assetFileObject = new File([asset], uploadedFile.fileInfo.name, {
    //   type: uploadedFile.fileInfo.type,
    // });


   
    
    const formData = new FormData();
    formData.append("image", image);
    formData.append("asset", asset);
    formData.append("metadata", JSON.stringify(metadata));
    try {
      const uploadingCall = await fetch(`${BASE_URL}/v1/upload/single`, {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: formData,
      });

      const uploadResponse = await uploadingCall.json();

      // setNftData({name:"",description:"",external_link:"",isAdded:false})
      if (uploadResponse.data && uploadResponse.data.url) {
        const ipfsLink = uploadResponse.data.url;
        setIpfsHash(ipfsLink);
        const type = nftType.toUpperCase();
        const network = networkType === "137" ? "mainnet" : "mumbai";
        const amount = nftData?.amount;
        const tokenCategory = erc721Type === "soulbounds" ? "soulbound" : null;
        const bodyData = {
          wallet,
          contractAddress: collection,
          to:wallet,
          tokenUri: ipfsLink,
          supply: 1,
        };

        setIsUploading(false);
        setIsMinting(true);
        try {
          const mintCall = await fetch(`${BASE_URL}/v2/collection/mint`, {
            method: "POST",
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "x-auth-token": process.env.REACT_APP_API_KEY,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          });

          const mintResponse = await mintCall.json();
          console.log(mintResponse);
          if (mintResponse && mintResponse.status) {
            console.log(mintResponse);
            if (mintResponse.status === true) {
              setIsMinting(false);
              setIsMinted(true);
              navigate("/success");
              setTxhash(mintResponse.data.transactionHash);
              setMintedTokenId("");
            } else {
              setIsMinting(false);
              setIsMinted(false);
              setIsPending(true);
              setTxhash("");
            }
          } else {
            setIsMinting(false);
            setIsMinted(false);
            setIsPending(true);
            setTxhash("");
          }
        } catch (e) {
          setIsUploading(false);
          setIsMinting(false);
          setIsMinted(false);
          console.log(e);
          setNftData({
            name: "",
            description: "",
            external_link: "",
            isAdded: false,
          });
        }
      } else {
        setIsMinted(false);
        setIsUploading(false);
        setIsMinting(false);
        setNftData({
          name: "",
          description: "",
          external_link: "",
          isAdded: false,
        });
      }
    } catch (e) {
      setIsMinted(false);
      setIsUploading(false);
      setIsMinting(false);
      console.log(e);
      setNftData({
        name: "",
        description: "",
        external_link: "",
        isAdded: false,
      });
    }
  };
 
  const uploadAssets = async () => {
    window.gtag("event", "mint-click", {
      event_category: "mint",
      event_label: nftType.toUpperCase(),
    });
    navigate("/mint");
    const metadata = {
      name: nftData.name,
      description: nftData.description,
      external_link: nftData.external_link,
      attributes: refactorAttributes(attributes),
    };

    const image = assetPreview.fileInfo;
    const asset = uploadedFile.fileInfo;

    // const previewFileObject = new File([image], assetPreview.fileInfo.name, {
    //   type: assetPreview.fileInfo.type,
    // });
    // const assetFileObject = new File([asset], uploadedFile.fileInfo.name, {
    //   type: uploadedFile.fileInfo.type,
    // });

    const formData = new FormData();
    formData.append("image", image);
    formData.append("asset", asset);
    formData.append("metadata", JSON.stringify(metadata));
    try {
      const uploadingCall = await fetch(`${BASE_URL}/v1/upload/single`, {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-auth-token": process.env.REACT_APP_API_KEY,
        },
        body: formData,
      });

      const uploadResponse = await uploadingCall.json();

      // setNftData({name:"",description:"",external_link:"",isAdded:false})
      if (uploadResponse.data && uploadResponse.data.url) {
        const ipfsLink = uploadResponse.data.url;
        setIpfsHash(ipfsLink);
        const type = nftType.toUpperCase();
        const network = networkType === "137" ? "mainnet" : "mumbai";
        const amount = nftData?.amount;
        const tokenCategory = erc721Type === "soulbounds" ? "soulbound" : null;
        const bodyData = {
          wallet,
          type,
          amount,
          network,
          tokenUri: ipfsLink,
          tokenCategory,
        };

        setIsUploading(false);
        setIsMinting(true);
        try {
          const mintCall = await fetch(`${BASE_URL}/v1/mint/single`, {
            method: "POST",
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "x-auth-token": process.env.REACT_APP_API_KEY,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          });

          const mintResponse = await mintCall.json();
          if (mintResponse && mintResponse.data) {
            if (mintResponse.data.status === "CONFIRMED") {
              setIsMinting(false);
              setIsMinted(true);
              navigate("/success");
              setTxhash(mintResponse.data.transactionHash);
              setMintedTokenId(mintResponse.data.tokenId);
            } else {
              setIsMinting(false);
              setIsMinted(false);
              setIsPending(true);
              setTxhash(mintResponse.data.transactionHash);
            }
          } else {
            if (mintResponse.message === "Please retry txn") {
              try {
                const mintCall = await fetch(`${BASE_URL}/v1/mint/single`, {
                  method: "POST",
                  headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-auth-token": process.env.REACT_APP_API_KEY,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(bodyData),
                });

                const mintResponse = await mintCall.json();
                if (mintResponse && mintResponse.data) {
                  if (mintResponse.data.status === "CONFIRMED") {
                    setIsMinting(false);
                    setIsMinted(true);
                    navigate("/success");
                    setTxhash(mintResponse.data.transactionHash);
                    setMintedTokenId(mintResponse.data.tokenId);
                  } else {
                    setIsMinting(false);
                    setIsMinted(false);
                    setIsPending(true);
                    setTxhash(mintResponse.data.transactionHash);
                  }
                } else {
                  setIsUploading(false);
                  setIsMinting(false);
                  setIsMinted(false);
                  setNftData({
                    name: "",
                    description: "",
                    external_link: "",
                    isAdded: false,
                  });
                }
              } catch (e) {
                setIsUploading(false);
                setIsMinting(false);
                setIsMinted(false);
                console.log(e);
                setNftData({
                  name: "",
                  description: "",
                  external_link: "",
                  isAdded: false,
                });
              }
            }
          }
        } catch (e) {
          setIsUploading(false);
          setIsMinting(false);
          setIsMinted(false);
          console.log(e);
          setNftData({
            name: "",
            description: "",
            external_link: "",
            isAdded: false,
          });
        }
      } else {
        setIsMinted(false);
        setIsUploading(false);
        setIsMinting(false);
        setNftData({
          name: "",
          description: "",
          external_link: "",
          isAdded: false,
        });
      }
    } catch (e) {
      setIsMinted(false);
      setIsUploading(false);
      setIsMinting(false);
      console.log(e);
      setNftData({
        name: "",
        description: "",
        external_link: "",
        isAdded: false,
      });
    }
  };

  const handleMint = () => {
    setIsUploading(true);
    // collection ? uploadAssetsCustom() : uploadAssets();
    console.log("collection", collection);
    if(collection){
      if(gasAvailable < 0.5){
        setErrorMsg("Insufficient gas tank balance to send transaction, Minimum 0.5 MATIC is required to mint NFT Please fill your gas tank");
      } else {
    uploadAssetsCustom();
      }
  }  else {
    console.log("gasAvailable", gasAvailable);
  }
  };

  return (
    <>
    <section className=" flex h-[100%] md:h-screen w-full">
    {popUp &&<>
      <div className="claimpopup absolute z-10 bg-[#000000ad] inset-0 grid items-center justify-center max-w-full ">
      <div className="claimpopup__inner w-[95%] md:w-[700px] bg-white p-8 rounded-xl mx-auto">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Gas Tank</h2>
          <span onClick={() => setPopUp(false)} className="text-2xl font-bold">X</span>
          </div>
          <div className="flex gap-4 items-center mt-4">
            <label className="text-lg font-semibold">Enter the amount of gas you want to deposit (in Matic)
            </label>
          </div>
          <div className="flex gap-4 items-center mt-4">
          <input
          defaultValue={gasFill}
          value={gasFill}
          onChange={(e) => setGasFill(e.target.value)}
          type="number" className="w-full border-2 border-gray-300 rounded-md p-2" placeholder="Enter Amount" />
          </div>

          <button
          disabled={processing}
          onClick={() => {
            if(gasFill < 0.5){
              setErrorMsg("Minimum gas fill amount is 0.5 Matic")
              return;
            } else {
            console.log(gasFill)
             FillGas(gasFill?.toString())
            }
          }}
          className="bg-[#000000] font-semibold text-center rounded-full text-white p-3 w-full mt-4">
            {processing ? "Processing...": "Deposit"}
            </button>
      </div>
  
      </div>
    </>}
      <BuildBlock>
       
       
        <div className="w-full mx-auto h-auto  md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
          <BuildNftImage />
          <BuildNftDetails />
        </div>
       
        <div className="w-full md:h-[100%] md:w-[60%] p-4 flex flex-col justify-between md:overflow-y-auto">
        {errorMsg && <>
        <div className="flex font-semibold text-[#333333] items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                  <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                  <p>{errorMsg}</p>
                </div>
       </>}
          <div>
            
            <h2 className="text-4xl font-black lg:text-6xl uppercase pb-5">
              Let’s build your nft
            </h2>
            {collection && 
             <>
            
            <div 
            onClick={() => setPopUp(true)}
          className="bg-[#000000] font-semibold text-center rounded-lg text-white text-sm p-2 w-1/2 md:w-1/4 ml-auto"
          >
            Gas Tank: {gasAvailable} Matic
          </div></>
            }

            <BuildNftItem
              itemTitle={"Add NFT details*"}
              itemDescription="Add a name and description for your NFT"
              btnText="Edit details"
              handleItemClick={handleItemClick}
              isExpanded={activeItem === "details"}
              item="details"
              isCompleted={nftData.isAdded}
              handleContinue={handleContinue}
            />
            {/* <BuildNftItem
            itemTitle={"Additional Properties"}
            itemDescription="Add a name and description to your NFT"
            btnText="Continue"
            handleItemClick={handleItemClick}
            isExpanded={activeItem === "properties"}
            item="properties"
            isCompleted={false}
            isSkipable={true}
            handleContinue={handleContinue}
          /> */}
         
         
          </div>
          <div className="steps__wrapper mt-3">
         
            <button
              className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 w-full mt-4 "
              disabled={!nftData.isAdded}
              onClick={handleMint}
            >
              Mint my NFT
            </button>
            <p
              className="text-center pt-3 cursor-pointer"
              onClick={() => {
                setNftData({
                  name: "",
                  description: "",
                  external_link: "",
                  amount: "1",
                  isAdded: false,
                });
                navigate(-1);
              }}
            >
              Cancel Mint
            </p>

            <div className="progress">
              <ProgressBar phase={2} percentage={nftData.isAdded ? 50 : 10} />
            </div>
          </div>
        </div>
      </BuildBlock>
    </section>
    </>
  );
};

export default BuildNFT;

export const BuildNftItem = ({
  itemTitle,
  itemDescription,
  btnText,
  handleItemClick,
  item,
  isCompleted,
  isExpanded,
  isSkipable,
  handleContinue,
}) => {
  return (
    <div
      className={
        isExpanded ? "rounded-xl p-4 md:p-5 py-8 bg-[#fefefe] my-4" : "p-5 py-0"
      }
      onClick={() => handleItemClick(item)}
    >
      <p
        className={
          isExpanded
            ? "font-black text-2xl lg:text-3xl pb-1 flex items-center"
            : "text-[#00000079] font-black text-2xl lg:text-3xl pb-1 flex items-center"
        }
      >
        {isCompleted ? (
          <img src={checkCircle} alt="" className="mr-2 h-6" />
        ) : null}{" "}
        {itemTitle}
      </p>
      {isExpanded && (
        <div className={isExpanded ? "extended2" : "not-extended"}>
          <p className="text-[20px] lg:text-[24px]">{itemDescription}</p>
          <button
            className="bg-black font-semibold text-center rounded-full text-white p-3 w-full mt-4"
            onClick={() => handleContinue(item)}
          >
            {btnText}
          </button>
          {isSkipable ? <p className="text-center pt-2">Skip</p> : null}
        </div>
      )}
    </div>
  );
};

