import "./Assistant.css";
import assistantImage from "../../assets/images/assistant.png";
import { useGCtx } from "../../Contexts/GlobalContext";
import { useEffect } from "react";
const Assistant = () => {
  const { assistantText } = useGCtx();
  useEffect(() => {
    var assis = document.querySelector(".assistant");
    if (assistantText === "" || !assistantText) {
      assis.classList.remove("expanded");
    } else {
      assis.classList.remove("expanded");
      setTimeout(() => {
        assis.classList.add("expanded");
      }, 500);
    }
  }, [assistantText]);

  return (
    <div className={"assistant fixed right-[20px] bottom-[5%]  bg-[#000000c6]"}>
      <div className="assistant__inner flex flex-col">
        <p className="assistent__text mr-12 text-white pl-2 pt-2">
          {assistantText}
        </p>

        <img
          src={assistantImage}
          alt=""
          className="h-20 w-20 bg-green-400 rounded-full ml-auto"
        />
      </div>
    </div>
  );
};

export default Assistant;
