import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ABI } from "../../assets/ABI/DepositMaticMumbai";
import { ClipLoader } from "react-spinners";
import BuildBlock from "../../Components/BuildBlock/BuildBlock";
import {
  mintBatch,
  retryS3Upload,
  updateDeposit,
  uploadToIPFS,
} from "../../Pages/Profile/helpers";
import Web3 from "web3";
import checkCircle from "../../assets/images/checkCirclePurple.svg";
import pendingCircle from "../../assets/images/pendingCircle.svg";
import emptyCircle from "../../assets/images/emptyCircle.svg";
import { useGCtx } from "../../Contexts/GlobalContext";
import { useAccount, useSigner } from "wagmi";

const SessionDetails = () => {
  const { sessionID } = useParams();
  const { wallet } = useGCtx();
  const [currentSession, setCurrentSession] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { connector } = useAccount();
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const web3u = new Web3();
  const navigate = useNavigate();
  useEffect(() => {
    if (!sessionID) return;
    getCurrentSession();
    if (!currentSession.sessionID) {
      console.log("No Data");
    }
  }, [sessionID]);

  useEffect(() => {
    console.log("Update Status...");
  }, [lastUpdated, currentSession]);

  const getCurrentSession = async () => {
    setIsLoading(true);

    const getCurrentCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/batch/${sessionID}`,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );

    const getCurrentRes = await getCurrentCall.json();
    if (getCurrentRes) {
      setCurrentSession(getCurrentRes);
      setIsLoading(false);
      setIsUpdating(false);
    }
  };

  // const getCollectionName = () => {};

  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key).then(
      function () {
        console.log("Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const depositMatic = async () => {
    try {
      setIsUpdating(true);
      var checkDeposit = await updateDeposit(
        currentSession.sessionID,
        currentSession.wallet
      );
      if (checkDeposit?.isDeposited === true) {
        var tempSesss = currentSession;
        tempSesss.status.gas = "DEPOSITED";
        tempSesss.lastUpdated = Date.now();
        setCurrentSession(tempSesss);
        setLastUpdated(Date.now());
        setIsUpdating(false);
        return;
      }
      const provider = await connector?.getProvider();
      const web3 = new Web3(provider);
      const depositContract = new web3.eth.Contract(
        ABI,
        "0xb3E5FA3F9e1DF2b8274c9c8568716d7A23066C0f"
      );
      const session = currentSession.sessionID;
      const balance = currentSession.estimatedGas;
      const totalGas = await depositContract.methods
        .depositGas(session.toString())
        .estimateGas({
          value: balance.toString(),
          from: currentSession.wallet,
        });
      const gasPrice = await web3.eth.getGasPrice();

      const tx = await depositContract.methods
        .depositGas(session.toString())
        .send({
          value: balance.toString(),
          from: currentSession.wallet,
          gas: totalGas.toString(),
          gasPrice: gasPrice,
        });
      if (tx) {
        var res = await updateDeposit(session, currentSession.wallet);
        if (res.error) {
          setIsUpdating(false);
        } else {
          var tempSess = currentSession;
          tempSess.status.gas = "DEPOSITED";
          tempSess.lastUpdated = Date.now();
          setCurrentSession(tempSess);
          setLastUpdated(Date.now());
          setIsUpdating(false);
        }
      }
    } catch (e) {
      console.log(e.message);
      setIsUpdating(false);
    }
  };

  const handleUploadIpfs = async () => {
    setIsUpdating(true);
    const res = await uploadToIPFS(
      currentSession.sessionID,
      currentSession.wallet
    );
    if (res.error) {
      setIsUpdating(false);
    } else {
      var tempSession = currentSession;
      tempSession.status.ipfs = "PENDING";
      tempSession.lastUpdated = Date.now();
      setCurrentSession(tempSession);
      setLastUpdated(Date.now());
      setIsUpdating(false);
    }
  };

  const getAction = (status = currentSession.status) => {
    if (
      !currentSession.contractAddress ||
      currentSession.contractAddress === "0x0" ||
      currentSession.contractAddress.length < 4
    ) {
      return (
        <div
          className="bg-[#3BBEAE] text-white transition-colors text-center transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer "
          onClick={() =>
            navigate(`/batch/create-collection/${currentSession.sessionID}`)
          }
        >
          Create Collection
        </div>
      );
    } else if (status.s3 !== "CONFIRMED") {
      if (status.s3 === "PENDING") {
        return;
        // <p className="bg-yellow-400 p-2 py-1 font-semibold rounded-md text-xs">
        //   Uploading to Server
        // </p>
      } else {
        return (
          <div
            className="bg-[#3BBEAE] text-white transition-colors text-center transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer "
            onClick={() =>
              retryS3Upload(currentSession.sessionID, currentSession.wallet)
            }
          >
            Retry Upload
          </div>
        );
      }
    } else if (status.ipfs !== "CONFIRMED") {
      if (status.ipfs === "PENDING") {
        return;
        // (
        //   <p className="bg-yellow-400 p-2 py-1 font-semibold rounded-md text-xs">
        //     Uploading to IPFS
        //   </p>
        // );
      } else {
        return (
          <div
            className="bg-[#3BBEAE] text-white transition-colors text-center transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer "
            onClick={handleUploadIpfs}
          >
            Upload to IPFS
          </div>
        );
      }
    } else if (status.gas !== "DEPOSITED") {
      return (
        <div
          className="bg-[#3BBEAE] text-white  transition-colors text-center transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer "
          onClick={depositMatic}
        >
          Deposit Matic
        </div>
      );
    } else if (status.mint !== "CONFIRMED") {
      if (status.mint === "PENDING" || status.mint === 'FAILED') {
        return;
      } else {
        return (
          <div
            className="bg-[#3BBEAE] text-white transition-colors text-center transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer "
            onClick={() => {
              mintBatch(
                currentSession.sessionID,
                currentSession.wallet,
                currentSession,
                setCurrentSession,
                setLastUpdated,
                setIsUpdating
              );
            }}
          >
            Mint
          </div>
        );
      }
    } else if (status.refund !== "CONFIRMED") {
      if (status.refund === "PENDING" || status.refund === "FAILED") {
        return;
        // (
        //   <p className="bg-yellow-400 p-2 py-1 font-semibold rounded-md text-xs">
        //     Refund in progress
        //   </p>
        // );
      } else {
        return;
        // (
        //   <p className="bg-green-400 p-2 py-1 font-semibold rounded-md text-xs">
        //     Refund Success
        //   </p>
        // );
      }
    } else {
      return (
        <p
          className="bg-green-400 transition-colors text-center transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer "
          onClick={() =>
            window.open(
              `https://opensea.io/assets?search[query]=${currentSession.contractAddress}`
            )
          }
        >
          View Collection
        </p>
      );
    }
  };

  const handleRefresh = () => {
    setIsUpdating(true);
    setTimeout(() => {
      getCurrentSession();
    }, 1000);
  };

  return (
    <section className=" flex h-[100%] md:h-screen w-full">
      {currentSession.sessionID ? (
        <BuildBlock>
          <div className="w-full mx-auto h-auto  md:h-[100%] md:w-[40%] max-w-[360px] md:max-w-[400px] p-4 gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden">
            {currentSession.name && (
              <img
                src={`https://avatars.dicebear.com/api/bottts/${currentSession.name}.svg`}
                alt={currentSession.name}
                className="h-[300px] w-[300px] md:h-[200px] md:w-[200px] lg:h-[360px] lg:w-[360px]  object-contain object-center mx-auto rounded-xl border-4 border-solid border-white"
              />
            )}
          </div>
          <div className="w-full md:h-[100%] md:w-[60%] p-4 flex flex-col justify-between md:overflow-y-auto ">
            <div>
              {/* <p className="font-semibold text-sm">{getCollectionName()}</p> */}
              <h2 className="text-4xl font-black lg:text-6xl uppercase pb-2 ">
                {currentSession.name}
              </h2>
              {currentSession.wallet === wallet ? (
                <div className="flex gap-3">
                  {getAction()}
                  <div
                    className="bg-[#fefefe] transition-colors text-center transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer "
                    onClick={() => {
                      handleRefresh();
                    }}
                  >
                    Refresh
                  </div>
                </div>
              ) : null}{" "}
              {/* <p>{currentSession.description}</p> */}
              <div className="bg-[#fefefe]  p-3 rounded-lg my-3 lg:flex lg:justify-between">
                <Timeline currentSession={currentSession} />
                <div className="w-full lg:w-[58%]">
                  <div className="flex justify-between items-center my-1">
                    <p className="font-semibold">Estimated Gas</p>{" "}
                    <p>
                      {web3u.utils
                        .fromWei(
                          currentSession.estimatedGas?.toString(),
                          "ether"
                        )
                        .substring(0, 8)}
                    </p>
                  </div>

                  <div className="flex justify-between items-center my-1">
                    <p className="font-semibold">Utilised Gas</p>{" "}
                    <p>
                      {web3u.utils
                        .fromWei(
                          currentSession.totalGasUsed?.toString(),
                          "ether"
                        )
                        .substring(0, 8)}
                    </p>
                  </div>
                  <div className="flex justify-between items-center my-1">
                    <p className="font-semibold">Deposited Gas</p>{" "}
                    <p>
                      {web3u.utils
                        .fromWei(
                          currentSession.depositedAmount?.toString(),
                          "ether"
                        )
                        .substring(0, 8)}
                    </p>
                  </div>
                  <div className="flex justify-between items-center my-1">
                    <p className="font-semibold">No. of Files</p>{" "}
                    <p>{currentSession.filesCount}</p>
                  </div>
                  <div className="flex justify-between items-center my-1">
                    <p className="font-semibold">Contract Address</p>{" "}
                    <button
                      onClick={() =>
                        handleCopyKey(currentSession.contractAddress)
                      }
                      className="copytext text-[16px]"
                    >
                      {currentSession.contractAddress.substring(0, 8) +
                        "..." +
                        currentSession.contractAddress.substring(
                          currentSession.contractAddress?.length - 5,
                          currentSession.contractAddress?.length
                        )}
                    </button>
                  </div>
                  <div className="flex justify-between items-center my-1">
                    <p className="font-semibold">Refund Amount</p>{" "}
                    <p>
                      {web3u.utils
                        .fromWei(
                          currentSession.refundAmount?.toString(),
                          "ether"
                        )
                        .substring(0, 8)}
                    </p>
                  </div>
                  {currentSession?.refundHash?.length && (
                    <div className="flex justify-between items-center my-1">
                      <p className="font-semibold">Refund hash</p>{" "}
                      <button
                        onClick={() => handleCopyKey(currentSession.refundHash)}
                        className="copytext text-[16px]"
                      >
                        {currentSession.refundHash.substring(0, 8) +
                          "..." +
                          currentSession.refundHash.substring(
                            currentSession.refundHash.length - 5,
                            currentSession.refundHash.length
                          )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-2">
                <p className="font-semibold">Transactions</p>{" "}
                {currentSession?.transactionHashes?.length &&
                  currentSession.transactionHashes.map((t, i) => {
                    return (
                      <div className="flex justify-between items-center my-1">
                        <button
                          onClick={() => handleCopyKey(t.hash)}
                          className="copytext text-[16px]"
                        >
                          {t.hash.substring(0, 8) +
                            "..." +
                            t.hash.substring(t.hash.length - 5, t.hash.length)}
                        </button>
                        <p>{t.status}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="mt-3">
              <p
                className="cursor-pointer font-semibold"
                onClick={() => navigate("/profile")}
              >
                Back to Dashboard
              </p>
            </div>
          </div>
        </BuildBlock>
      ) : isLoading ? (
        <BuildBlock>
          <div className="w-full h-full grid items-center">
            <div>
              <div className="text-center my-4">
                <ClipLoader size={60} color="#3BBEAE" />
              </div>
              <p className="text-center font-semibold">Loading...</p>
            </div>
          </div>
        </BuildBlock>
      ) : (
        <BuildBlock>
          <div className="border-2 rounded-2xl  p-4 flex h-full justify-center items-center w-full">
            <div className="text-center">
              <p className="font-black text-3xl md:text-5xl pb-3">WHOOPS!</p>
              <p className="font-medium text-[20px] md-text-[24px]">
                Session not found :(
              </p>
              <button
                className="bg-[#3BBEAE] text-white font-semibold text-center rounded-full text-white p-3 mt-4 px-16"
                onClick={() => navigate("/")}
              >
                Home
              </button>
            </div>
          </div>
        </BuildBlock>
      )}
      {isUpdating ? <Loading /> : null}
    </section>
  );
};

export default SessionDetails;

export const Timeline = ({ currentSession }) => {
  return (
    <div className="w-full lg:w-[38%] my-4 lg:my-0 lg:border-r-1 lg:border-r lg:border-r-[#000000a0]">
      <div className="flex py-2 relative">
        <div
          className={
            currentSession.status.s3 === "CONFIRMED" ||
            currentSession.status.s3 === "PENDING"
              ? "line bg-[#A673EF]"
              : "line bg-[#AEAEAE]"
          }
        ></div>
        <img
          className="h-6 mr-3"
          src={
            currentSession.status.s3 === "PENDING"
              ? pendingCircle
              : currentSession.status.s3 === "CONFIRMED"
              ? checkCircle
              : emptyCircle
          }
          alt=""
        />{" "}
        <p>Upload to Server</p>
      </div>
      <div className="flex py-2 relative">
        <div
          className={
            currentSession.status.ipfs === "CONFIRMED" ||
            currentSession.status.ipfs === "PENDING"
              ? "line bg-[#A673EF]"
              : "line bg-[#AEAEAE]"
          }
        ></div>
        <img
          className="h-6 mr-3"
          src={
            currentSession.status.ipfs === "PENDING"
              ? pendingCircle
              : currentSession.status.ipfs === "CONFIRMED"
              ? checkCircle
              : emptyCircle
          }
          alt=""
        />{" "}
        <p>Upload to IPFS</p>
      </div>
      <div className="flex py-2 relative">
        <div
          className={
            currentSession.status.gas === "DEPOSITED" ||
            currentSession.status.gas === "PENDING"
              ? "line bg-[#A673EF]"
              : "line bg-[#AEAEAE]"
          }
        ></div>
        <img
          className="h-6 mr-3"
          src={
            currentSession.status.gas === "PENDING"
              ? pendingCircle
              : currentSession.status.gas === "DEPOSITED"
              ? checkCircle
              : emptyCircle
          }
          alt=""
        />{" "}
        <p>Deposit Matic</p>
      </div>
      <div className="flex py-2 relative">
        <div
          className={
            currentSession.status.mint === "CONFIRMED" ||
            currentSession.status.mint === "PENDING"
              ? "line bg-[#A673EF]"
              : "line bg-[#AEAEAE]"
          }
        ></div>
        <img
          className="h-6 mr-3"
          src={
            currentSession.status.mint === "PENDING"
              ? pendingCircle
              : currentSession.status.mint === "CONFIRMED"
              ? checkCircle
              : emptyCircle
          }
          alt=""
        />{" "}
        <p>Mint</p>
      </div>
      <div className="flex py-2 relative">
        <img
          className="h-6 mr-3"
          src={
            currentSession.status.refund === "PENDING"
              ? pendingCircle
              : currentSession.status.refund === "CONFIRMED"
              ? checkCircle
              : emptyCircle
          }
          alt=""
        />{" "}
        <p>Refund</p>
      </div>
    </div>
  );
};

export const Loading = () => {
  return (
    <div className="absolute z-100 bg-[#000000b0] inset-0 grid items-center h-[100vh] w-full text-white">
      <div>
        <div className="text-center my-4">
          <ClipLoader size={60} color="#3BBEAE" />
        </div>
        <p className="text-center font-semibold">
          Please wait... <br /> Please don't close this window.{" "}
        </p>
      </div>
    </div>
  );
};
