import { useGCtx } from "../../Contexts/GlobalContext";

const PhygitalImage = ({ selectedOption }) => {
  const { assetPreview } = useGCtx();
  return (
    <div className="image__container__signed mx-auto text-center w-full bg-white  h-[350px] rounded-xl border-4 border-solid border-black p-3">
      <div
        className="frameouter h-full w-[200px] relative"
        style={
          selectedOption === "frame-fx"
            ? { transform: "scale(0.6)" }
            : { transform: "scale(1)" }
        }
      >
        <div className="frameinner h-full w-full"></div>
        <img
          src={assetPreview?.file}
          alt=""
          className="h-[auto] w-full object-contain object-center mx-auto rounded-xl"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/notloaded.png";
          }}
        />
      </div>
    </div>
  );
};

export default PhygitalImage;
