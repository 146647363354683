export const uploadToIPFS = async (sessionID, wallet) => {
  try {
    const data = {
      wallet,
      sessionID,
    };
    const uploadCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/batch/ipfs`,
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const uploadRes = await uploadCall.json();
    return uploadRes;
  } catch (e) {
    console.log(e);
  }
};

export const retryS3Upload = async (sessionID, wallet) => {
  try {
    const data = {
      wallet,
      sessionID,
    };
    const uploadCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/batch/upload/retry`,
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const uploadRes = await uploadCall.json();
    if (uploadRes.error) {
      console.log(uploadRes.error);
    } else {
      console.log(uploadRes);
    }
  } catch (e) {
    console.log(e);
  }
};

export const mintBatch = async (
  sessionID,
  wallet,
  currentSession,
  setCurrentSession,
  setLastUpdated,
  setIsUpdating
) => {
  try {
    setIsUpdating(true);
    const data = {
      wallet,
      sessionID,
      network: "mainnet",
    };
    const uploadCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/mint/batch`,
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const uploadRes = await uploadCall.json();
    if (uploadRes.error) {
      console.log(uploadRes.error);
      setIsUpdating(false);
    } else {
      var tempSession = currentSession;
      tempSession.status.mint = "PENDING";
      tempSession.lastUpdated = Date.now();
      setCurrentSession(tempSession);
      setLastUpdated(Date.now());
      setIsUpdating(false);
    }
  } catch (e) {
    console.log(e);
    setIsUpdating(false);
  }
};

export const updateDeposit = async (sessionID, wallet) => {
  try {
    const data = {
      wallet,
      sessionID,
    };
    const uploadCall = await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/deposit`,
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const uploadRes = await uploadCall.json();
    return uploadRes;
  } catch (e) {
    console.log(e);
  }
};
