
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { networkId } from "../../connectors";
import Assistant from "../../Components/Assitant/Assistant";
import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import OptionItem from "../../Components/OptionItem/OptionItem";
import { useGCtx } from "../../Contexts/GlobalContext";
import { useAccount } from "wagmi";
const Netwrok = () => {
  const [extended, setExtended] = useState("137");
  const {chain} = useAccount()
  const { setNetworkType, callAssistant, switchNetwork } = useGCtx();
  const navigate = useNavigate();
  const handleClickExtention = (e, type) => {
    setExtended(type);
    if (e.target.tagName === "BUTTON") {
      return;
    }
    if (type === "137") {
      setTimeout(() => {
        callAssistant("Mint your token directly to the Polygon Mainnet");
      }, 100);
    } else if (type === "80001") {
      setTimeout(() => {
        callAssistant("Use this option if you are just testing the platform");
      }, 100);
    }
  };
  useEffect(() => {
    if (chain && chain.toString() === "137") {
      navigate("/start");

      setNetworkType("137");
    }
  }, []);

  const handleContinue = (type) => {
    if (chain && type === chain?.toString()) {
      setNetworkType(type);

      navigate("/start");
    } else {
      switchNetwork(type);
    }
  };

  return (
    <section className="select flex h-screen w-full items-end">
      <ContentBlock>
        <div className="top__content">
          <h1 className="md:text-6xl text-4xl font-black uppercase">
            Switch <br className="hidden md:block" />
            Network <br className="hidden md:block" />
            To Continue
          </h1>
          <div className="options__container pt-2">
            <OptionItem
              heading="Polygon Mainnet"
              description="Mint NFT mints your tokens directly to the Polygon Mainnet. Please click Continue to switch to the Polygon Mainnet."
              buttonText="Continue"
              isExtended={extended === networkId}
              type={networkId}
              handleClickExtention={handleClickExtention}
              handleContinue={handleContinue}
            />
          </div>
        </div>
        <p
          className="text-center cursor-pointer font-medium"
          onClick={() => navigate("/")}
        >
          Back
        </p>
        {/* <div className="progress">
          <ProgressBar phase={1} percentage={90} />
        </div> */}
      </ContentBlock>
      <Assistant />
    </section>
  );
};

export default Netwrok;
