let globalNFT;
let globalClaim;
let networkId;
let nftFactory;

if (process.env.REACT_APP_TESTNET) {
  console.log("In testnet!");
  globalNFT = require("./assets/ABI/nftGlobalTestnet.json");
  globalClaim = require("./assets/ABI/claimGlobalTestnet.json");
  nftFactory = require("./assets/ABI/nftFactoryTestnet.json");
  networkId = "80001";
} else {
  console.log("In mainnet!");
  globalNFT = require("./assets/ABI/nftGlobalMainnet.json");
  globalClaim = require("./assets/ABI/claimGlobalMainnet.json");
  nftFactory = require("./assets/ABI/nftFactoryMainnet.json");
  networkId = "137";
}

export { globalNFT, globalClaim, networkId, nftFactory };
