import { useLocation } from "react-router-dom";
import { useGCtx } from "../../Contexts/GlobalContext";
import Connect from "../../Pages/Connect/Connect";

function PrivateRoute({ children }) {
  const location = useLocation();
  const { wallet } = useGCtx();
  return wallet ? children : <Connect prev={location} />;
}

export default PrivateRoute;
