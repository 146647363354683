import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import fingerprint from "../../assets/images/fingerprint.svg";
import supply from "../../assets/images/supply.svg";
import Web3 from "web3";
import alertCircle from "../../assets/images/alertCircle.svg";
import ClaimBlock from "../../Components/ClaimBlock/ClaimBlock";
import { Biconomy } from "@biconomy/mexa";
import { useAccount, useSigner } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { zkEVMDev } from "../../assets/ABI/claimzkEVMDev";

const CONTRACT_ADDRESS = "0xcD8369e959E44ca44082cf59806c8C5E917343f3";

const Claimpage = ({ tokenId = 1 }) => {
  const [isClaimed, setIsClaimed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [biconomyContract, setBiconomyContract] = useState();
  const [error, setError] = useState("");
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();

  let biconomy, web3Pro;

  const loadContract = async () => {
    biconomy = new Biconomy(signer.provider, {
      apiKey: process.env.REACT_APP_BICONOMY_KEY_MAINNET,
      debug: true,
      strictMode: true,
    });
    web3Pro = new Web3(biconomy);
    biconomy.onEvent(biconomy.READY, async () => {
      const contract = new web3Pro.eth.Contract(zkEVMDev, CONTRACT_ADDRESS);
      setBiconomyContract(contract);
    });
  };

  const checkAccess = async () => {
    try {
      setError("");
      const isWhitelisted = await biconomyContract.methods
        .isWhitelisted(address)
        .call();
      if (!isWhitelisted) {
        setError("You're not whitelisted for the claim");
        return;
      }
      setIsWhitelisted(isWhitelisted);
    } catch (e) {
      console.log(e);
      setError(
        "Oops! Something went wrong. We could not check your eligibility."
      );
    }
  };

  const checkBalance = async () => {
    try {
      setError("");
      const balance = await biconomyContract.methods
        .balanceOf(address, tokenId)
        .call();
      if (Number(balance) > 0) setIsClaimed(true);
    } catch (e) {
      setError("Oops, Something went wrong! Please try again after a while.");
    }
  };

  const claimNFT = async () => {
    try {
      setIsLoading(true);
      setError("");
      biconomyContract.methods
        .mint(tokenId)
        .send({
          from: address,
        })
        .then((data) => {
          setIsClaimed(data.status);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setError(
            "Oops, the transaction has failed! Please try again after a while."
          );
        });
    } catch (e) {
      setIsLoading(false);
      setError("Oops, Something went wrong! Please try again after a while.");
    }
  };

  useEffect(() => {
    if (isConnected && signer) {
      setCheckingStatus(true);
      loadContract();
    }
  }, [isConnected, signer]);

  useEffect(() => {
    if (biconomyContract) {
      checkAccess();
      checkBalance();
      setCheckingStatus(false);
    }
  }, [biconomyContract]);

  return (
    <section className=" flex h-[100%] md:h-screen w-full relative">
      <div className="video__wrapper bg-white md:hidden">
        <video
          src={"/videos/Screen1.mp4"}
          className="bg-white object-cover w-full h-full"
          autoPlay
          loop
          muted
          playsInline
        ></video>
      </div>
      <ClaimBlock>
        <div className="w-full mx-auto h-full  lg:h-[100%] md:w-[40%] lg:w-[50%] max-w-[360px] md:max-w-[400px] lg:max-w-[50%]  gradp2 rounded-2xl md:overflow-y-auto md:overflow-x-hidden relative">
          <div className="image__container mx-auto text-center w-full h-full md:p-12">
            <img
              src={`/claim-zkEVM/dev-${tokenId}.png`}
              alt="claim"
              className="h-full w-full object-contain mx-auto rounded-xl "
            />
          </div>
          {/* {false ? (
              <div className="absolute bottom-6 left-6 right-6  claimprop rounded-xl overflow-hidden  mx-auto my-3 mt-4 font-semibold flex  items-center gt-w bg-[#3A4662] max-w-[400px]">
                {" "}
                <div className="prop flex items-center gap-1 text-white justify-center bg-[#3BBEAE] p-2 w-[100%]">
                  Claim not started yet!
                </div>
              </div>
            ) : (
              true && (
                <div className="absolute bottom-6 left-6 right-6  claimprop rounded-xl overflow-hidden  mx-auto my-3 mt-4 font-semibold flex  items-center gt-w bg-[#3A4662] max-w-[400px]">
                  <div className="prop flex items-center gap-1 text-white justify-center bg-[#3BBEAE] p-2 w-[50%]">
                    <img src={timer} className="h-4" alt="" /> Time left to
                    Claim
                  </div>
                  <div className="prop-value p-2 px-4 text-white font-medium text-center bg-[#3A4662] w-[50%] ">
                    {Math.ceil(
                      (new Date().getTime() -
                        new Date(Date.now() / 1000).getTime()) /
                        (24 * 60 * 60)
                    )}{" "}
                    Days
                  </div>
                </div>
              )
            )} */}
        </div>
        <div className="w-full md:h-[100%] lg:w-[60%] md:w-[50%] p-4 flex items-center md:overflow-y-auto">
          <div className="w-full">
            {isConnected && error ? (
              <div className="flex font-semibold text-[#333333] items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
                <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
                <p>{error}</p>
              </div>
            ) : null}
            <h2 className="text-3xl font-black md:text-5xl  py-5 ">
              zk Dev {tokenId}
            </h2>
            <p className="text-xl mb-5">zkThon Challenge completion Badge. This NFT is awarded to all zkThon 2023 participants for successfully submitting challenge 1.</p>
            <div className="claimprops flex items-center gap-2 flex-wrap font-regular gt-w ">
              {/* <div className="claimprop rounded-lg overflow-hidden w-auto  ">
                <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                  <img src={claimed} className="h-4 mr-1" alt="" />
                  {balanceOf || 0} Claimed
                </div>
              </div> */}
              <div className="claimprop w-auto  rounded-lg overflow-hidden">
                <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                  <img src={fingerprint} className="h-4 mr-1" alt="" />
                  {"Private"} Access
                </div>
              </div>
              <div className="claimprop rounded-lg overflow-hidden w-auto  ">
                <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                  <img src={supply} className="h-4 mr-1" alt="" />
                  {"200"} Supply
                </div>
              </div>
            </div>
            <p className="font-medium text-[#1e1e1e] text-[18px] md:text-[21px] py-3 gt-w">
              {""}
            </p>
            {/* <p className="py-1 pb-3 flex items-center gt-w">
                <img src={linkIcon} alt="" className="mr-2 h-4" />{" "}
                <span className="text-[#3BBEAE] font-semibold text-[14px] md:text-[16px]">
                  {window.location.href}
                </span>
              </p> */}
            <div className="button__container text-center">
              {!isConnected ? (
                <ConnectButton />
              ) : isWhitelisted && !isClaimed ? (
                <button
                disabled={checkingStatus}
                onClick={() =>
                  window.open(
                    `https://rarible.com/token/polygon/${CONTRACT_ADDRESS}:${tokenId}`
                  )
                }
                className="bg-[#3BBEAE] w-full px-5 py-3 my-3 rounded-full text-white font-semibold text-center"
              >
                {"View on Rarible"}
              </button>
              ) : (
                <button
                  disabled={checkingStatus}
                  onClick={() =>
                    window.open(
                      `https://rarible.com/token/polygon/${CONTRACT_ADDRESS}:${tokenId}`
                    )
                  }
                  className="bg-[#3BBEAE] w-full px-5 py-3 my-3 rounded-full text-white font-semibold text-center"
                >
                  {checkingStatus ? "Checking Status" : "View on Rarible"}
                </button>
              )}
            </div>
          </div>
        </div>
      </ClaimBlock>
      {/* { : null} */}
      {isLoading ? (
        <div className="absolute z-20 inset-0 h-[100vh] bg-[#000a] flex items-center justify-center ">
          <div className="text-center">
            <ClipLoader size={60} color="#3BBEAE" />
            <p className="text-white font-semibold py-3">Please Wait...</p>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default Claimpage;
