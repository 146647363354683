const AdditionalDetailModal = ({ children, closeModal }) => {
  return (
    <div
      className="fixed w-full h-full flex items-center justify-center z-3 bg-[#00000076] inset-0"
      onClick={() => closeModal(false)}
    >
      <div
        className="w-full max-w-[800px] min-h-[300px] p-4 py-8 md:p-8 rounded-3xl bg-white box-shadow-block"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default AdditionalDetailModal;
