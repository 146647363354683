import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import backRound from "../../assets/images/roundBack.svg";

import { useGCtx } from "../../Contexts/GlobalContext";
import BatchItem from "../../Components/BatchItem/BatchItem";
import "./Profile.css";

const Profile = () => {
  const {
    setAssetPreview,
    wallet,
    setDehiddenModules,
    setNftData,
    setTxhash,
    setIpfsHash,
    setMintedTokenId,
    userNFTs,
    isUserNftLoading,
    setNftType,
    setERC721Type,
    getNfts,
    setUploadedFile,
    batchSessions,
    getBatchSessions,
  } = useGCtx();
  const [activeTab, setActiveTab] = useState("single");
  const [claimableNFTs, setClaimableNFTs] = useState([]);
  const [collectionNFTs, setCollectionNFTs] = useState([]);
  var tries = 0;

  

  const IPFStoMetadata = async (hash) => {
    try{
      const data = await fetch(`https://cloudflare-ipfs.com/ipfs/${hash}`,{
        method:"GET",
        headers:{
          "content-type":"application/json"
        }
      })
      const response = await data.json()
      return response;
    }
    catch(error){
      console.log(error)
      return false;
    }
  }

  const FetchCollections = async(wallet) => {
    try{
      const data = await fetch(`${process.env.REACT_APP_BASE_URL}/v2/collection/all/${wallet}`,{
        method:"GET",
        headers:{
          "x-api-key":process.env.REACT_APP_API_KEY,
          "x-auth-token": process.env.REACT_APP_API_KEY,
        }
      })
      const response = await data.json()
      if(response?.data?.length > 0){
        await Promise.all(response.data.map(async(item) => {
          let metadata = await IPFStoMetadata(item?.tokenURI?.split("ipfs://")[1])
          if(metadata){
            item.media = metadata?.image;
            item.title = metadata?.name;
            item.description = metadata?.description;
            item.network = "mainnet";
            item.supplyCount = item?.supply;
          }
          
          setCollectionNFTs(prev => [...prev,item])
        }))
      }
    }
    catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    if (wallet) {
      if (tries < 1) {
        getNfts();
        getBatchSessions();
        FetchCollections(wallet)
        tries++;
      }
    }
  }, [wallet]);

  useEffect(() => {
    prepareClaimSessions();
  }, [userNFTs]);

  const navigate = useNavigate();

  const prepareClaimSessions = async () => {
    const claimableNFTs = userNFTs.filter(
      (item) => item.claimable && item.claimable !== ""
    );
    setClaimableNFTs(claimableNFTs);
  };

  const handlePreviewEdit = async (hash, where) => {
  
    const currentNft = userNFTs.filter(
      (item) => item.transactionHash === hash
    )[0] || collectionNFTs.filter((item) => item.transactionHash === hash)[0];
    if (currentNft) {
      if (currentNft.type === "ERC721") {
        setAssetPreview({
          file: currentNft.media?.includes("ipfs://") ? "https://ipfs.io/ipfs/" + currentNft.media?.split("ipfs://")[1] : currentNft.media,
          fileInfo: { name: currentNft?.title },
        });
        setNftData({
          name: currentNft?.title,
          description: currentNft?.description,
          amount: currentNft?.supplyCount || currentNft?.supply,
        });
        setDehiddenModules({
          autograph: currentNft.autograph || false,
          phygital: currentNft.phygital || false,
          unlockableContent: currentNft.unlockables ? true : false,
          airdrop: currentNft.airdrop || false,
          isAutographedItem: currentNft.originalHash ? true : false,
        });

        var tempUploaded = {
          file: {},
          fileInfo: {
            name: currentNft?.name,
            type: currentNft?.imageType,
          },
        };

        setUploadedFile(tempUploaded);
        setIpfsHash(currentNft?.tokenURI);

        setTxhash(hash);
        setMintedTokenId(Number(currentNft?.tokenId));
        if (currentNft?.type === "ERC721") {
          currentNft?.tokenCategory === "soulbound"
            ? setERC721Type("soulbounds")
            : setERC721Type("721only");
        }
        setNftType(currentNft?.type);
        
        if (where === "share") {
          navigate("/share");
        }
        else if(where === "claim"){
          navigate("/create-claim-page");
        }
        else {
          navigate("/dehidden/add-utilities");
        }

      } else if (currentNft.type === "ERC1155") {
        setAssetPreview({
          file: currentNft.media?.includes("ipfs://") ? "https://ipfs.io/ipfs/" + currentNft.media?.split("ipfs://")[1] : currentNft.media,
          fileInfo: { name: currentNft?.title },
        });
        setNftData({
          name: currentNft?.title,
          description: currentNft?.description,
          amount: currentNft?.supplyCount || currentNft?.supply,
        });
        setNftType(currentNft?.type);
        setIpfsHash(currentNft?.tokenURI);
        setTxhash(hash);
        setMintedTokenId(Number(currentNft?.tokenId));
        if (where === "claim") {
          navigate("/create-claim-page");
        } else {
        navigate("/share");
      }
      }
    }
  };

  const getOpenseaUrl = (contract, network, tokenId) => {
    return network === "mumbai" || network === "80001"
      ? "https://testnets.opensea.io/assets/mumbai/" +
          contract +
          "/" +
          Number(tokenId)
      : "https://opensea.io/assets/matic/" + contract + "/" + Number(tokenId);
  };

  return (
    <section className=" flex h-[100%] md:h-screen w-full flex-col ">
      <div className="pt-4 flex justify-between w-[95%] md:w-[90%] mx-auto items-center pb-10">
        <div
          className="back flex items-center py-3 pt-5 pb-0 cursor-pointer"
          onClick={() => navigate(-1)}
        >
           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" className="mr-2" viewBox="0 0 24 25" fill="none">
            <path d="M20.1984 14.2712C20.1984 9.83601 16.5888 6.2264 12.1536 6.2264H8.94717V3.5L3.80157 7.66641L8.94717 11.8328L8.94731 8.70317H12.1537C15.2258 8.70317 17.7217 11.1992 17.7217 14.2712C17.7217 17.3433 15.2257 19.8392 12.1537 19.8392L5.60651 19.8393C4.91531 19.8393 4.37771 20.3961 4.37771 21.0681C4.37771 21.7593 4.93444 22.2969 5.60651 22.2969H12.1537C16.5889 22.3161 20.1985 18.7065 20.1985 14.2713" fill="#fff" />
          </svg>
          <p className="font-semibold text-[#fff] hidden md:block">Go Back</p>
        </div>
      </div>

      <div className="flex-col md:flex-row w-[95%] md:w-[90%] mx-auto rounded-2xl p-3 md:p-6  mb-10 mt-10 bg-white h-auto m-auto md:mb-auto md:mt-auto md:max-w-[1440px]  md:h-[80vh] md:max-h-[800px] box-shadow-block">
        <div className="flex gap-3 flex-col sm:items-center sm:justify-between sm:flex-row">
          <h1 className="md:text-6xl text-4xl font-black uppercase py-3">
            My Dashboard
          </h1>
          <button
            onClick={
              activeTab === "single"
                ? () => {
                    getNfts().then(() => prepareClaimSessions());
                  }
                : getBatchSessions
            }
            className="border rounded-lg  p-2 px-8 font-semibold hover:border-[#111111] text-sm w-[140px] text-center sm:w-auto"
          >
            Refresh
          </button>

        </div>
        <div className="flex gap-3 flex-col sm:items-center sm:justify-between sm:flex-row">
        <div className="flex gap-2 border max-w-fit p-2 rounded-lg md:my-1 border-[#00000081] my-2">
          <button
            className={
              activeTab === "single"
                ? "bg-[#3BBEAE] text-white border rounded-lg p-1 text-sm px-4 text-center"
                : "hover:bg-[#8238e393] border rounded-lg p-1 text-sm px-4 text-center bg-[#fefefe] text-[#000000]"
            }
            onClick={() => setActiveTab("single")}
          >
            Single
          </button>
          <button
            className={
              activeTab === "batch"
                ? "bg-[#3BBEAE] text-white  border rounded-lg p-1 text-sm px-4 text-center"
                : "hover:bg-[#8238e393] border rounded-lg p-1 text-sm px-4 text-center bg-[#fefefe] text-[#000000]"
            }
            onClick={() => setActiveTab("batch")}
          >
            Batch
          </button>
          <button
            className={
              activeTab === "collections"
                ? "bg-[#3BBEAE] text-white  border rounded-lg p-1 text-sm px-4 text-center"
                : "hover:bg-[#8238e393] border rounded-lg p-1 text-sm px-4 text-center bg-[#fefefe] text-[#000000]"
            }
            onClick={() => setActiveTab("collections")}
          >
            Collections
          </button>
          <button
            className={
              activeTab === "claims"
                ? "bg-[#3BBEAE] text-white  border rounded-lg p-1 text-sm px-4 text-center"
                : "hover:bg-[#8238e393] border rounded-lg p-1 text-sm px-4 text-center bg-[#fefefe] text-[#000000]"
            }
            onClick={() => setActiveTab("claims")}
          >
            Live Claims
          </button>
        
        </div>

        <button
           onClick={() => navigate("/start")}
            className={"bg-[#3BBEAE] text-white  border rounded-lg p-2 px-8 text-sm px-4 text-center ml-auto w-1/2 md:w-auto"}
          >
            New Mint
          </button> 
        </div>
            
        {isUserNftLoading ? (
          <div className="border-2 rounded-2xl mt-6 p-4 flex h-[68%] justify-center items-center">
            <ClipLoader />
          </div>
        ) : activeTab === "single" ? (
          !userNFTs?.length ? (
            <EmptyProfile navigate={navigate} what="Mint NFT" where="/quick" />
          ) : (
            <div className="usernft__outer rounded-lg">
              
              <div className="usernft__container">
                {userNFTs.map((item, index) => {
                  return (
                    <NFTItem
                      item={item}
                      key={index}
                      handlePreviewEdit={handlePreviewEdit}
                      openseaUrl={getOpenseaUrl(
                        item.contractAddress,
                        item.network,
                        item.tokenId
                      )}
                      navigate={navigate}
                      activeTab={activeTab}
                    />
                  );
                })}
              </div>
            </div>
          )
        ) : activeTab === "batch" ? (
          !batchSessions?.length ? (
            <EmptyProfile
              navigate={navigate}
              what="Batch Mint NFT's"
              where="/create"
            />
          ) : (
            <div className="usernft__outer rounded-lg">
              <div className="usernft__container">
                {batchSessions.map((item, index) => {
                  return <BatchItem item={item} key={index} />;
                })}
              </div>
            </div>
          )
        ) : activeTab === "collections" ? 
         !collectionNFTs?.length ?
         <EmptyProfile navigate={navigate} what="Mint NFT" where="/type" />
         :
        <>
          
            <div className="usernft__outer rounded-lg">
              
              <div className="usernft__container">
                {collectionNFTs?.map((item, index) => {
                  return (
                    <NFTItem
                      item={item}
                      key={index}
                      handlePreviewEdit={handlePreviewEdit}
                      openseaUrl={getOpenseaUrl(
                        item.contractAddress,
                        item.network,
                        item.tokenId
                      )}
                      navigate={navigate}
                      activeTab={activeTab}
                    />
                  );
                })}
              </div>
            </div>
          </>
          
        :
        (
          (!userNFTs?.length && !batchSessions?.length) ? 
          <>
           <EmptyProfile
              navigate={navigate}
              what="claim_nft"
              where="/create"
            />
          </>
          :
          !claimableNFTs?.length  ?
          <>
           <EmptyProfile
              navigate={navigate}
              what="claim"
              where="/create"
            />
          </> :
          <>
          <div className="usernft__outer rounded-lg">
            <div className="usernft__container">
              {claimableNFTs.map((item, index) => {
                return <ClaimItem item={item} key={index} what="claim" />;
              })}
            </div>
          </div>
          </>
        )}
        
      </div>
    </section>
  );
};

export default Profile;

const NFTItem = ({ item, handlePreviewEdit, openseaUrl, navigate,activeTab}) => {
  return (
    <div className="usernft__item border border-[#00000082] p-3 rounded-xl ">
      <div
        className="h-full w-auto aspect-square relative border bg-[#e0e0e0] rounded-lg mr-4 usernft__image__item"
        onClick={() =>
          activeTab === "collections" ?
          window.open(
            `https://opensea.io/assets/matic/${item.contractAddress}/${Number(item.tokenId)}`,
            "_blank"
          ) :
          window.open(
            `/view/matic/${item.contractAddress}/${Number(item.tokenId)}`
          )
        }
      >
        
        <img
          src={item?.media?.includes("ipfs://") ? `https://cloudflare-ipfs.com/ipfs/${item?.media?.split("ipfs://")[1]}` : item?.media}
          alt={item.title}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/notloaded.png";
          }}
          className="rounded-xl h-full w-full object-cover"
        />
        <span className="absolute text-[16px] font-black text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center w-3/4  p-2 backdrop-blur-sm bg-black/10">
          View NFT
        </span>
        <div className="type absolute right-2 bottom-2 rounded-lg p-1 text-[14px] font-black text-[#008376] px-3 backdrop-blur-sm bg-black/10">
          {item.type === "ERC721" ? "721" : "1155"}
        </div>
       
      </div>
      <div className="w-[40%]">
        <p className="font-black text-2xl gt-w">
          {item.title.length > 7
            ? item.title.substring(0, 7) + "..."
            : item.title}
        </p>

        {
        activeTab !== "collections" ? 
        item.type === "ERC721"  && (
          <div
            className="hover:bg-[#fefefe] transition-colors transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer w-full border"
            onClick={() => handlePreviewEdit(item.transactionHash, "utilities")}
          >
            ✏️ Add Utilities
          </div>
        ) : null }
         <div
            className="hover:bg-[#fefefe] transition-colors transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer w-full border flex gap-2 mx-auto"
            onClick={() => handlePreviewEdit(item.transactionHash, "claim")}
          >
            <span>📄</span>
            <span>Create Claim Page</span>
          </div>
        <div
          className="hover:bg-[#fefefe] transition-colors transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer w-full border"
          onClick={() => handlePreviewEdit(item.transactionHash, "share")}
        >
          🌐 Share
        </div>
        {item.unlockables && item.unlockables.length ? (
          <a href={item.unlockables} target="_blank" rel="noreferrer">
            <div className="hover:bg-[#fefefe] border transition-colors  transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer w-full unlockable__item relative">
              🎁 View Unlockable
              <div className="tooltip absolute  bg-[#ffffff] border border-[#A673EF] text-[14px] p-2 px-3  right-0 top-10 rounded-lg">
                {item.unlockables.length > 30
                  ? item.unlockables.substring(0, 30) + "..."
                  : item.unlockables}
              </div>
            </div>
          </a>
        ) : null}
      </div>
    </div>
  );
};

const EmptyProfile = ({ navigate, what, where }) => {
  return (
    <div className="border-2 rounded-2xl mt-6 p-4 flex h-[68%] justify-center items-center">
      {what !== "claim" && what !== "claim_nft" ? (
        <div className="text-center">
          <p className="font-black text-3xl md:text-5xl pb-3">UH OH!</p>
          <p className="font-medium text-[20px] md-text-[24px]">
            Looks like you haven’t minted any NFT on our platform
          </p>
          <button
            className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 mt-4 px-16"
            onClick={() => navigate(where || "/")}
          >
            {what}
          </button>
        </div>
      ) :
        what === "claim_nft" ? 
        <>
           <div className="text-center">
          <p className="font-black text-3xl md:text-5xl pb-3">UH OH!</p>
          <p className="font-medium text-[20px] md-text-[24px]">
            Looks like you haven’t minted any NFT on our platform
          </p>
          <p>
            Create a claim page by minting an NFT.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center mt-4 gap-2">
          <button
            className="bg-[#3BBEAE] text-lg font-semibold text-center rounded-full text-white p-2 mt-4 px-2"
            onClick={() => navigate("/quick")}
          >
            Single Mint
          </button>
          <button
            className="bg-[#3BBEAE] text-lg font-semibold text-center rounded-full text-white p-2 mt-4 px-2"
            onClick={() => navigate("/create")}
          >
            Batch Mint NFT's
          </button>
          </div>
        </div>
        </>
        :
      (
        <div className="text-center">
          <p className="font-black text-3xl md:text-5xl pb-3">HMMM…</p>
          <p className="font-medium text-[20px] md-text-[24px]">
            Looks like you haven’t created a claim page for your NFTs yet :/
          </p>
          <p>Head to one of your NFTs to create a claim page!</p>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center mt-4 gap-2">
          <button
            className="bg-[#3BBEAE] text-lg font-semibold text-center rounded-full text-white p-2 mt-4 px-2"
            onClick={() => navigate("/quick")}
          >
            Single Mint
          </button>
          <button
            className="bg-[#3BBEAE] text-lg font-semibold text-center rounded-full text-white p-2 mt-4 px-2"
            onClick={() => navigate("/create")}
          >
            Batch Mint NFT's
          </button>
          </div> */}
        </div>
      )}
    </div>
  );
};

const ClaimItem = ({ item, handlePreviewEdit }) => {
  return (
    <div className="usernft__item border border-[#00000082] p-3 rounded-xl ">
      <div
        className="h-full w-auto aspect-square  relative border bg-[#e0e0e0] rounded-lg mr-4 usernft__image__item"
        onClick={() =>
          window.open(
            `/view/matic/${item.contractAddress}/${Number(item.tokenId)}`
          )
        }
      >
        <img
          src={item.media}
          alt={item.title}
          className="rounded-xl h-full w-full object-cover"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/notloaded.png";
          }}
        />
        <div className="type absolute right-2 bottom-2 rounded-lg p-1 text-[14px] font-black text-[#008376] px-3 backdrop-blur-sm bg-black/10">
          {item.type === "ERC721" ? "721" : "1155"}
        </div>
      </div>
      <div className="w-[40%]">
        <p className="font-black text-2xl">
          {item.title.length > 7
            ? item.title.substring(0, 7) + "..."
            : item.title}
        </p>

        <div
          className="hover:bg-[#fefefe] transition-colors transition-ease p-2 rounded-lg my-1 text-sm cursor-pointer w-full border"
          onClick={() => window.open(`/claim-page/${item.claimable}`)}
        >
          🌁 Preview
        </div>
      </div>
    </div>
  );
};