import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import fileIcon from "../../assets/images/fileIcon.png";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import closeIcon from "../../assets/images/closeIcon.png";

import { useNavigate } from "react-router-dom";
import { useGCtx } from "../../Contexts/GlobalContext";
import Assistant from "../../Components/Assitant/Assistant";
import alertCircle from "../../assets/images/alertCircle.svg";
import { refactorAttributes } from "../../utils";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const acceptedTypes = [
  "JPG",
  "PNG",
  "JPEG",
  "GIF",
  "SVG",
  "MP4",
  "WEBM",
  "WEBP",
  "MP3",
  "WAV",
  "OGG",
  "GLB",
  "GLTF",
  "GLB",
];
const QuickMint = () => {
  const {
    uploadedFile,
    setUploadedFile,
    assetPreview,
    setAssetPreview,
    callAssistant,
    setNftData,
    nftData,
    setNftType,
    setERC721Type,
    setIsMinted,
    setIsMinting,
    setIsUploading,
    setTxhash,
    attributes,
    setIpfsHash,
    resetState,
    wallet,
    setMintedTokenId,
    setIsPending,
  } = useGCtx();
  //eslint-disable-next-line

  const [fileType, setFileType] = useState("");
  const [error, setError] = useState("");
  //eslint-disable-next-line
  const [uploadedFileExtension, setUploadedFileExtension] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const navigate = useNavigate();

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const getFileType = (file) => {
    return file["type"].split("/")[0];
  };
  const getFileExtention = (filename) => {
    return filename.split(".").pop();
  };

  const getFilePreview = (file) => {
    if (fileType === "image") {
      return <img src={file.file} alt="" className="h-40 mx-auto" />;
    } else if (fileType === "video") {
      return <video src={file.file} controls className="h-40 mx-auto" />;
    } else if (fileType === "audio") {
      return (
        <audio src={file.file} controls className="mx-auto w-[90%] h-auto" />
      );
    }
  };

  useEffect(() => {
    if (error?.length) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);

  const handleResetFile = () => {
    setUploadedFile({ file: {}, fileInfo: {} });
    setAssetPreview({ file: {}, fileInfo: {} });
    setIsUploaded(false);
    setFileType("");
    setUploadedFileExtension("");
  };

  const uploadAssets = async () => {
    window.gtag("event", "mint-click", {
      event_category: "mint",
      event_label: "ERC721",
    });
    navigate("/mint");
    const metadata = {
      name: nftData.name,
      description: nftData.description,
      external_link: nftData.external_link,
      attributes: refactorAttributes(attributes),
    };

    const image = assetPreview.fileInfo;
    const asset = uploadedFile.fileInfo;

    const formData = new FormData();
    formData.append("image", image);
    formData.append("asset", asset);
    formData.append("metadata", JSON.stringify(metadata));
    try {
      window.gtag("event", "mint-click", {
        "event-category": "mint",
        "event-label": "ERC721",
      });
      const uploadingCall = await fetch(`${BASE_URL}/v1/upload/single`, {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: formData,
      });

      const uploadResponse = await uploadingCall.json();

      // setNftData({name:"",description:"",external_link:"",isAdded:false})
      if (uploadResponse.data && uploadResponse.data.url) {
        const ipfsLink = uploadResponse.data.url;
        setIpfsHash(ipfsLink);
        const type = "ERC721";
        const network = "mainnet";
        const amount = nftData?.amount;
        const tokenCategory = null;
        const bodyData = {
          wallet,
          type,
          amount,
          network,
          tokenUri: ipfsLink,
          tokenCategory,
        };

        setIsUploading(false);
        setIsMinting(true);
        try {
          const mintCall = await fetch(`${BASE_URL}/v1/mint/single`, {
            method: "POST",
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          });

          const mintResponse = await mintCall.json();
          if (mintResponse && mintResponse.data) {
            if (mintResponse.data.status === "CONFIRMED") {
              setIsMinting(false);
              setIsMinted(true);
              navigate("/success");
              setTxhash(mintResponse.data.transactionHash);
              setMintedTokenId(mintResponse.data.tokenId);
            } else {
              setIsMinting(false);
              setIsMinted(false);
              setIsPending(true);
              setTxhash(mintResponse.data.transactionHash);
            }
          } else {
            if (mintResponse.message === "Please retry txn") {
              try {
                const mintCall = await fetch(`${BASE_URL}/v1/mint/single`, {
                  method: "POST",
                  headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(bodyData),
                });

                const mintResponse = await mintCall.json();
                if (mintResponse && mintResponse.data) {
                  if (mintResponse.data.status === "CONFIRMED") {
                    setIsMinting(false);
                    setIsMinted(true);
                    navigate("/success");
                    setTxhash(mintResponse.data.transactionHash);
                    setMintedTokenId(mintResponse.data.tokenId);
                  } else {
                    setIsMinting(false);
                    setIsMinted(false);
                    setIsPending(true);
                    setTxhash(mintResponse.data.transactionHash);
                  }
                } else {
                  setIsUploading(false);
                  setIsMinting(false);
                  setIsMinted(false);
                  setNftData({
                    name: "",
                    description: "",
                    external_link: "",
                    isAdded: false,
                  });
                }
              } catch (e) {
                setIsUploading(false);
                setIsMinting(false);
                setIsMinted(false);
                console.log(e);
                setNftData({
                  name: "",
                  description: "",
                  external_link: "",
                  isAdded: false,
                });
              }
            }
          }
        } catch (e) {
          setIsUploading(false);
          setIsMinting(false);
          setIsMinted(false);
          console.log(e);
          setNftData({
            name: "",
            description: "",
            external_link: "",
            isAdded: false,
          });
        }
      } else {
        setIsMinted(false);
        setIsUploading(false);
        setIsMinting(false);
        setNftData({
          name: "",
          description: "",
          external_link: "",
          isAdded: false,
        });
      }
    } catch (e) {
      setIsMinted(false);
      setIsUploading(false);
      setIsMinting(false);
      console.log(e);
      setNftData({
        name: "",
        description: "",
        external_link: "",
        isAdded: false,
      });
    }
  };

  const handleMint = () => {
    setIsUploading(true);
    setERC721Type("721only");
    setNftType("ERC721");
    uploadAssets();
  };

  const uploadPreview = () => {
    return (
      <Dropzone
        onDrop={(acceptedFiles) => {
          // Do something with the files
          if (!acceptedFiles[0]) {
            console.log("Error Uploading File / No files present");
            return;
          }
          const reader = new FileReader();
          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = () => {
            // Do whatever you want with the file contents
            if (getFileType(acceptedFiles[0]) === "image") {
              const binaryStr = reader.result;
              const blob = new Blob([binaryStr]);
              const url = URL.createObjectURL(blob);
              let singleFile = acceptedFiles[0];
              setAssetPreview({ file: url, fileInfo: singleFile });
            } else {
              callAssistant(
                "Only images are allowed as thumbnails. Please check your thumbnail file type."
              );
              setError(
                "Only images are allowed as thumbnails. Please check your thumbnail file type."
              );
            }
          };
          reader.readAsArrayBuffer(acceptedFiles[0]);
        }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className="dndarea border-solid my-2 border-2 rounded-md h-44 p-4 grid items-center text-center"
            {...getRootProps()}
          >
            <input {...getInputProps()} multiple={false} accept="image/*" />
            {isDragActive ? (
              <p>Drop the files here...</p>
            ) : (
              <div>
                <img
                  src={uploadIcon}
                  alt="File Icon"
                  className="h-14 mx-auto mb-3"
                />
                <p>Upload a thumbnail</p>
              </div>
            )}
          </div>
        )}
      </Dropzone>
    );
  };
  return (
    <section className=" flex h-screen w-full items-end">
     

      <ContentBlock>
        <div className="top__content">
        <p
            className="mt-2 cursor-pointer rounded-lg p-2 border bg-[#fefefe] font-semibold text-sm"
          >
            <span className="mr-2">ⓘ</span>
            We're adding some exciting new features, so minting is paused for now. We'll be back soon!
            {" "}
          </p>
         
        
          {error ? (
            <div className="font-semibold text-[#333333] flex items-center  w-full py-3 bg-[#FFB5B5] rounded-lg p-2 my-2">
              <img src={alertCircle} className="h-6 mr-2" alt="" />{" "}
              <p>{error}</p>
            </div>
          ) : null}
          {!isUploaded ? (
            <Dropzone
              onDrop={(acceptedFiles) => {
                // Do something with the files
                if (!acceptedFiles[0]) {
                  console.log("Error Uploading File / No files present");
                  return;
                }
                const reader = new FileReader();
                reader.onabort = () => console.log("file reading was aborted");
                reader.onerror = () => console.log("file reading has failed");
                reader.onload = () => {
                  // Do whatever you want with the file contents

                  if (acceptedFiles[0].size > 104857600) {
                    setError(
                      "Whoops! Whoops! Max file size exceeded. Please upload a file less than 100MB.. Please upload a file less than 100MB."
                    );
                    callAssistant(
                      "Whoops! Whoops! Max file size exceeded. Please upload a file less than 100MB.. Please upload a file less than 100MB."
                    );
                  } else if (
                    !acceptedTypes.includes(
                      getFileExtention(acceptedFiles[0].name.toUpperCase())
                    )
                  ) {
                    setError("Please upload a valid file");
                    callAssistant("Please upload a valid file");
                  } else {
                    setError("");
                    const binaryStr = reader.result;
                    const blob = new Blob([binaryStr]);
                    const url = URL.createObjectURL(blob);
                    let singleFile = acceptedFiles[0];
                    var tempFileItem = { file: url, fileInfo: singleFile };
                    setUploadedFile(tempFileItem);
                    let tempFileType = getFileExtention(singleFile.name);
                    setUploadedFileExtension(tempFileType);
                    setFileType(getFileType(acceptedFiles[0]));
                    if (getFileType(acceptedFiles[0]) === "image") {
                      setIsUploaded(true);
                      setAssetPreview(tempFileItem);
                      //todo write logic to go mint
                    } else {
                      setIsUploaded(true);
                      callAssistant(
                        "Please Upload a thumbnail for your file as a PNG or JPG"
                      );
                      console.log("NOT IMAGE");
                      //write logic to add preview file
                    }
                  }
                };
                reader.readAsArrayBuffer(acceptedFiles[0]);
              }}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  className="dndarea border-dashed border-2 rounded-md h-44 p-4 grid items-center text-center"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} multiple={false} />
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : (
                    <div>
                      <img
                        src={uploadIcon}
                        alt="File Icon"
                        className="h-16 mx-auto"
                      />
                      <p>
                        <span className="text-sm">Max file size 100MB</span>{" "}
                        <br />
                        (JPG, PNG, JPEG, GIF, SVG, MP4, WEBM, WEBP, MP3, WAV,
                        OGG, GLTF, GLB)
                      </p>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          ) : (
            <div className="dndarea mt-3">
              <div className="flex border-[#fefefe] border-solid border-2 items-center justify-between p-2 rounded-lg">
                {uploadedFile.fileInfo.name.length > 30 ? (
                  <p className="font-semibold">
                    {uploadedFile.fileInfo.name.slice(0, 8)}...
                    {uploadedFile.fileInfo.name.slice(
                      uploadedFile.fileInfo.name.length - 10
                    )}
                  </p>
                ) : (
                  <p className="font-semibold">{uploadedFile.fileInfo.name}</p>
                )}

                <div className="closeicon">
                  <img
                    src={closeIcon}
                    alt=""
                    className="h-5 cursor-pointer"
                    onClick={handleResetFile}
                  />
                </div>
              </div>

              {fileType && fileType === "image" ? (
                <div className="my-2 border-[#fefefe] border-solid border-2 h-44 text-center p-2 rounded-lg">
                  {getFilePreview(uploadedFile)}
                </div>
              ) : !assetPreview.fileInfo.name ? (
                uploadPreview()
              ) : (
                <div className="my-2 border-[#fefefe] border-solid border-2 h-44 text-center p-2 rounded-lg">
                  <img
                    src={assetPreview.file}
                    alt=""
                    className="h-40 mx-auto"
                  />
                </div>
              )}
            </div>
          )}
          <div>
            <p className=" pt-2 font-semibold text-[#333333] ">
              Enter the name of your NFT*
            </p>
            <input
              type="text"
              name="name"
              id="name"
              value={nftData.name}
              onChange={(e) => setNftData({ ...nftData, name: e.target.value })}
              placeholder="My first NFT"
              className="w-full border-2 border-solid border-[#cccccc] p-2 px-2 rounded-md my-2 text-sm"
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="description" className="font-semibold pb-1">
              Provide a description for your NFT.*
            </label>
            <textarea
              name="description"
              id="description"
              value={nftData.description}
              onChange={(e) =>
                setNftData({ ...nftData, description: e.target.value })
              }
              placeholder="Fill in the detailed description of your NFT"
              className="w-full border-2 border-solid border-[#cccccc] p-2 rounded-md my-2 min-h-[100px]"
              required
            />
          </div>
        </div>

        <div className="button__container text-center">
          <button
            onClick={() => console.log("Mint NFT")}
            className="bg-[#3BBEAE] w-full px-5 py-4 my-3 rounded-full text-white font-semibold text-center"
            disabled={true}
          >
            Mint NFT
          </button>
          <p
            onClick={() => {
              resetState();
              navigate("/start");
            }}
            className="cursor-pointer"
          >
            Cancel
          </p>
        </div>
      </ContentBlock>
      <Assistant />
    </section>
  );
};

export default QuickMint;
