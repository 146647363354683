import React from "react";
import { useNavigate } from "react-router-dom";
import BuildBlock from "../../Components/BuildBlock/BuildBlock";

const Page404 = () => {
  const navigate = useNavigate();
  return (
    <section className=" flex h-[100%] md:h-screen w-full">
      <BuildBlock>
        <div className="border-2 rounded-2xl  p-4 flex h-full justify-center items-center w-full">
          <div className="text-center">
            <p className="font-black text-3xl md:text-5xl pb-3">WHOOPS!</p>
            <p className="font-medium text-[20px] md-text-[24px]">
              Looks like you are lost in metaverse
            </p>
            <button
              className="bg-[#3BBEAE] font-semibold text-center rounded-full text-white p-3 mt-4 px-16"
              onClick={() => navigate("/")}
            >
              Home
            </button>
          </div>
        </div>
      </BuildBlock>
    </section>
  );
};

export default Page404;
