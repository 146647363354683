import Home from "./Pages/Home/Home";
import Connect from "./Pages/Connect/Connect";
import NFTType from "./Pages/NFTType/NFTType";
import NFTOptions from "./Pages/NFTOptions/NFTOptions";
import Network from "./Pages/Network/Network";
import UploadSingle from "./Pages/Upload/UploadSingle";
import { Route, Routes, useLocation } from "react-router-dom";
import BuildNFT from "./Pages/BuildNFT/BuildNFT";
import AddDetails from "./Pages/AddDetails/AddDetails";
import AdditionalProperties from "./Pages/AdditonalProperties/AdditionalProperties";
import MintProgress from "./Pages/MintProgress/MintProgress";
import AddUtilities from "./Pages/Dehidden/AddUtilities/AddUtilities";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import SuccessMint from "./Pages/SuccessMint/SuccessMint";
import AutographModule from "./Pages/Dehidden/AutographModule/AutographModule";
import PhygitalModule from "./Pages/Dehidden/PhygitalModule/PhygitalModule";
import AirdropModule from "./Pages/Dehidden/AirdropModule/AirdropModule";
import RedirectPage from "./Pages/RedirectPage/RedirectPage";
import UnlockableModule from "./Pages/Dehidden/UnlockableModule/UnlockableModule";
import Share from "./Pages/Share/Share";
import Profile from "./Pages/Profile/Profile";
import NFTDetails from "./Pages/NFTDetails/NFTDetails";
import Page404 from "./Pages/404/404";
import CreateRestore from "./Pages/CreateRestore/CreateRestore";
import BatchUpload from "./Pages/BatchUpload/BatchUpload";
import CreateCollection from "./Pages/CreateCollection/CreateCollection";
import SessionDetails from "./Pages/SessionDetails/SessionDetails";
import QuickMint from "./Pages/QuickMint/QuickMint";
import MintYourNFT from "./Pages/MintYourNFT/MintYourNFT";
import Claimpage from "./Pages/Claimpage/Claimpage";
import AnalyticsPage from "./Pages/Analytics/analytics";
import CreateClaim from "./Pages/CreateClaim/CreateClaim";
import { useGCtx } from "./Contexts/GlobalContext";
import { ClipLoader } from "react-spinners";
import Navigation from "./Components/Navigation/Navigation";
import WalletAddress from "./Components/WalletAddress/WalletAddress";
import FAQs from "./Pages/FAQs/FAQs";
import CreateContract from "./Pages/CreateContract/CreateContract";
import SelectCollection from "./Pages/collectionSelect/CollectionType";
import { ClaimZkdev1 } from "./Pages/ClaimBadge";

const RoutesContainer = () => {
  const location = useLocation();
  const { loading, loadingMessage, showNav } = useGCtx();
  return (
    <>
      <WalletAddress />
      <Routes location={location} key={location.pathname}>
        <Route path="/" exact element={<Home />} />
        <Route path="/start" exact element={<MintYourNFT />} />
        <Route path="/connect" exact element={<Connect />} />
        <Route
          path="/quick"
          exact
          element={
            <PrivateRoute>
              <QuickMint />
            </PrivateRoute>
          }
        />

        <Route
          path="/type"
          exact
          element={
            <PrivateRoute>
              <NFTType />
            </PrivateRoute>
          }
        />
        <Route
          path="/create"
          exact
          element={
            <PrivateRoute>
              <CreateRestore />
            </PrivateRoute>
          }
        />
        <Route
          path="/batch-upload"
          exact
          element={
            <PrivateRoute>
              <BatchUpload />
            </PrivateRoute>
          }
        />
        <Route
          path="/batch/create-collection/:sessionID"
          exact
          element={
            <PrivateRoute>
              <CreateCollection />
            </PrivateRoute>
          }
        />
        <Route
          path="/batch/session/:sessionID"
          exact
          element={
            <PrivateRoute>
              <SessionDetails />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/options"
          exact
          element={
            <PrivateRoute>
              <NFTOptions />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/collection-type"
          exact
          element={
            <PrivateRoute>
              <SelectCollection />
            </PrivateRoute>
          }
        />
        <Route
          path="/network"
          exact
          element={
            <PrivateRoute>
              <Network />
            </PrivateRoute>
          }
        />
        <Route
          path="/upload"
          exact
          element={
            <PrivateRoute>
              <UploadSingle />
            </PrivateRoute>
          }
        />
        <Route
          path="/build-nft"
          exact
          element={
            <PrivateRoute>
              <BuildNFT />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-nft-details"
          exact
          element={
            <PrivateRoute>
              <AddDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/additional-properties"
          exact
          element={
            <PrivateRoute>
              <AdditionalProperties />
            </PrivateRoute>
          }
        />
         <Route
          path="/create-contract"
          exact
          element={
            <PrivateRoute>
              <CreateContract />
            </PrivateRoute>
          }
        />
        <Route
          path="/mint"
          exact
          element={
            <PrivateRoute>
              <MintProgress />
            </PrivateRoute>
          }
        />
        <Route
          path="/success"
          exact
          element={
            <PrivateRoute>
              <SuccessMint />
            </PrivateRoute>
          }
        />
        <Route
          path="/dehidden/add-utilities"
          exact
          element={
            <PrivateRoute>
              <AddUtilities />
            </PrivateRoute>
          }
        />
        <Route
          path="/dehidden/autograph"
          exact
          element={
            <PrivateRoute>
              <AutographModule />
            </PrivateRoute>
          }
        />
        <Route
          path="/dehidden/phygital"
          exact
          element={
            // <PrivateRoute>
            <PhygitalModule />
            // </PrivateRoute>
          }
        />
        <Route
          path="/dehidden/airdrop"
          exact
          element={
            <PrivateRoute>
              <AirdropModule />
            </PrivateRoute>
          }
        />
        <Route
          path="/dehidden/unlockable-content"
          exact
          element={
            <PrivateRoute>
              <UnlockableModule />
            </PrivateRoute>
          }
        />
        <Route
          path="/share"
          exact
          element={
            <PrivateRoute>
              <Share />
            </PrivateRoute>
          }
        />
        {/* <Route
        path="/create-claim-page"
        exact
        element={
          <PrivateRoute>
            <CreateClaimPage />
          </PrivateRoute>
        }
      /> */}
        <Route
          path="/create-claim-page"
          exact
          element={
            <PrivateRoute>
              <CreateClaim />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/claim-page/:claimId"
          element={
            // <PrivateRoute>
            <Claimpage />
            // </PrivateRoute>
          }
        />
        <Route
        exact
        path="/claim/zkdev-1"
        element={
          // <PrivateRoute>
          <ClaimZkdev1 tokenId={1} />
          // </PrivateRoute>
        }
        />
        <Route
        exact
        path="/claim/zkdev-2"
        element={
          // <PrivateRoute>
          <ClaimZkdev1 tokenId={2}/>
          // </PrivateRoute>
        }
        />
        <Route
        exact
        path="/claim/zkdev-3"
        element={
          // <PrivateRoute>
          <ClaimZkdev1 tokenId={3}/>
          // </PrivateRoute>
        }
        />
        <Route
        exact
        path="/claim/zkdev-4"
        element={
          // <PrivateRoute>
          <ClaimZkdev1 tokenId={4}/>
          // </PrivateRoute>
        }
        />
        <Route
        exact
        path="/claim/zkdev-5"
        element={
          // <PrivateRoute>
          <ClaimZkdev1 tokenId={5}/>
          // </PrivateRoute>
        }
        />
        <Route
          path="/profile"
          exact
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />

        {/* <Route path="/app/admin/analytics" exact element={<AnalyticsPage />} /> */}

        <Route
          path="/view/matic/:contractAddress/:tokenId"
          exact
          element={<NFTDetails />}
        /> 
        <Route path="/auth/check" exact element={<RedirectPage />} />
      
        <Route path="/faqs" exact element={<FAQs />} />

        {/* All routes above this */}
        <Route path="*" element={<Page404 />} />
      </Routes>
      {showNav && <Navigation />}

      {loading ? (
        <div className="absolute z-20 inset-0 bg-[#000a] flex items-center justify-center ">
          <div className="text-center">
            <ClipLoader size={60} color="#3BBEAE" />
            <p className="text-white font-semibold py-3">{loadingMessage}</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RoutesContainer;
