import { useGCtx } from "../../Contexts/GlobalContext";
import claimedIcon from "../../assets/images/claimed.svg";
import fingerprint from "../../assets/images/fingerprint.svg";
import supplyIcon from "../../assets/images/supply.svg";

const ClaimPopup = ({
  currentStep,
  approve,
  register,
  confirmListing,
  supply,
  accessType,
}) => {
  const { assetPreview, nftData } = useGCtx();

  return (
    <div className="claimpopup absolute z-10 bg-[#000000ad] inset-0 grid items-center justify-center max-w-full ">
      <div className="claimpopup__inner w-[95%] md:w-[700px] bg-white p-8 rounded-xl mx-auto">
        <div className="nft__details flex w-full items-center flex-col md:flex-row text-center md:text-left">
          <div className="nftpreview md:mr-4">
            <img
              src={assetPreview.file}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/notloaded.png";
              }}
              alt=""
              className="h-36  w-36"
            />
          </div>
          <div className="nftdetails flex justify-between w-full md:w-[70%]">
            <div>
              <p className="nft__name text-3xl font-bold uppercase py-2 gt-w">
                {nftData.name}
              </p>

              <div className="claimprops flex justify-center md:justify-start items-center gap-2 flex-wrap font-regular gt-w ">
                <div className="claimprop rounded-lg overflow-hidden w-auto  ">
                  <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                    <img src={claimedIcon} className="h-4 mr-1" alt="" />
                    {0} Claimed
                  </div>
                </div>
                <div className="claimprop w-auto  rounded-lg overflow-hidden">
                  <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                    <img src={fingerprint} className="h-4 mr-1" alt="" />
                    {accessType} Access
                  </div>
                </div>
                <div className="claimprop rounded-lg overflow-hidden w-auto  ">
                  <div className="prop flex items-center gap-1 text-white justify-center text-[14px] md:text-[16px] bg-[#008376] p-2 px-3">
                    <img src={supplyIcon} className="h-4 mr-1" alt="" />
                    {supply} Supply
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-right">
              <p className="text-[#3A4662] text-2xl font-semibold">Price:</p>
              <p className="text-[#8C93A3] text-sm my-2">0.00 ETH</p>
              <p className="text-[#8C93A3] text-sm my-2">0.003 USD</p>
            </div> */}
          </div>
        </div>
        <div className="steps pt-4">
          <div className="step__item my-3 flex">
            <div className="step__number mr-8 text-xl font-semibold border-4 rounded-[50%] flex items-center justify-center border-green-400 h-12 w-12">
              1
            </div>

            <div className="step__content w-[80%]">
              <p className="step__title font-semibold text-xl py-2">Approve</p>
              {currentStep === "1" && (
                <div>
                  <p>Approve to convert your NFT as a claimable NFT</p>
                  <button
                    className="bg-[#3BBEAE] px-5 py-2 text-center font-semibold text-white rounded-lg mt-3 text-lg"
                    onClick={approve}
                  >
                    Approve
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="step__item my-3 flex">
            <div
              className={
                currentStep > 1
                  ? "tep__number mr-8 text-xl font-semibold border-4 rounded-[50%] flex items-center justify-center   h-12 w-12 border-green-400"
                  : "step__number mr-8 text-xl font-semibold border-4 rounded-[50%] flex items-center justify-center  border-[#E2E4E8] h-12 w-12"
              }
            >
              2
            </div>
            <div className="step__content w-[80%]">
              <p className="step__title font-semibold text-xl py-2">Register</p>
              {currentStep === "2" && (
                <div>
                  <p>Register your claim page on chain</p>
                  <button
                    className="bg-[#3BBEAE] px-5 py-2 text-center font-semibold text-white rounded-lg mt-3 text-lg"
                    onClick={register}
                  >
                    Register
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="step__item my-3 flex">
            <div
              className={
                currentStep > 2
                  ? "tep__number mr-8 text-xl font-semibold border-4 rounded-[50%] flex items-center justify-center   h-12 w-12 border-green-400"
                  : "step__number mr-8 text-xl font-semibold border-4 rounded-[50%] flex items-center justify-center  border-[#E2E4E8] h-12 w-12"
              }
            >
              3
            </div>
            <div className="step__content w-[80%]">
              <p className="step__title font-semibold text-xl py-2">Confirm</p>
              {currentStep === "3" && (
                <div>
                  <p>Confirm your listing</p>
                  <button
                    className="bg-[#3BBEAE] px-5 py-2 text-center font-semibold text-white rounded-lg mt-3 text-lg"
                    onClick={confirmListing}
                  >
                    Confirm
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimPopup;
